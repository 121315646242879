import { message, Modal } from 'antd';
import queryString from 'query-string';
import LoginController from "../controllers/LoginController";
import ProfileController, { ProfileType } from "../controllers/ProfileController";
import SutrazController from "../controllers/SutrazController";
import AppRoutes from "./AppRoutes";

const { confirm } = Modal;

export default class ProfileHelper {
    static async loginProfile(user: { id, name, profilePic }, history, login, type?: string, redirectLink?: string): Promise<void> {
        Modal.info({ title: "Signing in", onOk: null });
        console.log("Type", type);
        // TODO language
        let profileResponse = await LoginController.getDefaultProfile({ id: user.id, profilePic: user.profilePic, name: user.name, language: "78463a384a5aa4fad5fa73e2f506ecfc" }, true, type);
        Modal.destroyAll();
        if (profileResponse.error) {
            message.error(profileResponse.error)
        }
        else {
            login(profileResponse.data);
            let profile = profileResponse.data;
            await LoginController.saveCurrentProfile(profile.id);
            if (redirectLink) {
                window.location.href = redirectLink;
            }
            else {
                if (ProfileController.isProfileComplete(profile))
                    history.push(AppRoutes.HOME)
                else {
                    history.push(AppRoutes.PROFILE_COMPLETE)
                }
            }
        }

    }

    static async updateProfile(user, newUser): Promise<any> {
        let response;
        let newProfile = { id: user.id, name: newUser.name || user.name, username: newUser.username || user.username, profilePic: newUser.profilePic || user.profilePic, location: newUser.location || user.location, bio: newUser.bio || user.bio };
        if (user.type == ProfileType.SUTRA) {
            response = await ProfileController.update(newProfile);
            if (response.data) {
                let sutra = await SutrazController.getDetailsByProfile(response.data);
                await SutrazController.updateSutraSpecific({ ...sutra.data, profession: newUser.profession || user.profession })
            }
        }
        else {
            response = await ProfileController.update(newProfile);
        }
        if (!response) {
            return;
        }
        if (response.error) {
            message.error(response.error);
        }
        else {
            message.success("Profile Updated");
        }
        if (response.data) {
            let newProfile = await ProfileController.getWholeDetails(response.data);
            return newProfile;
        }
    }

    static async showLogout(logout): Promise<void> {
        console.log("Logout ", logout);
        confirm({
            title: 'Do you want to logout?',
            content: 'You can always login',
            onOk() {
                return new Promise(async (resolve, reject) => {
                    try {
                        await LoginController.deleteCurrentProfile();
                        logout();
                        window.location.replace('/')
                        resolve()

                    } catch (error) {
                        reject(error);
                    }
                }).catch(() => message.info({ title: 'Oops errors!' }));
            },
        });
    }

    static async showLogin(): Promise<void> {
        confirm({
            title: 'You are not logged in.',
            content: 'Please login to continue',
            onOk() {
                return new Promise(async (resolve, reject) => {
                    try {
                        window.location.replace('/login');
                        resolve()

                    } catch (error) {
                        reject(error);
                    }
                }).catch(() => message.info({ title: 'Oops errors!' }));
            },
        });
    }

    static getType = (search) => {
        let query = queryString.parse(search);
        if (query["type"] && ProfileType.allTypes.includes(query["type"].toString())) {
            return query["type"].toString();
        }
        return ProfileType.CUSTOMER;
    }

    static goToProfile = (profile) => {
        window.location.href = AppRoutes.USER_PROFILE(profile.type, profile.username);
    }

}