import firebase, { firestore } from "firebase";
import i18n from "../i18n";
import AppResponse from "../models/AppResponse";


class LanguageController {

    static LANGUAGES = "languages";

    static get langaugeCollection() {
        return firebase.firestore().collection(this.LANGUAGES)
    }


    static getLocalLanguages(): {} {
        return {
            EN: "English",
            HI: "हिन्दी",

        };
    }

    static get currentLanguage() {
        let language = i18n.language;
        let codes = Object.keys(this.getLocalLanguages());
        let index = codes.indexOf(language);
        if (index == -1) {
            return 'EN';
        }
        return language;
    }

    static async parseData(data): Promise<any> {
        let newData = [...data]
        let languageData = {};
        await Promise.all(Object.keys(newData).map(async (key) => {
            let val = newData[key];
            if (val && val.language) {
                if (languageData[val.language]) {
                    val.languageName = languageData[val].name;
                }
                else {
                    let result = await this.getDetails(val.language);
                    if (result) {
                        val.languageName = result["name"];
                        languageData[val.language] = result
                    }
                }
            }
        }));
        return newData;
    }


    static async getDetails(id): Promise<{}> {
        try {
            var snapshot = await firestore().collection(this.LANGUAGES).doc(id).get();
            return snapshot.data();
        } catch (error) {
            return undefined;
        }
    }

    static async getLanguageByCode(code: string): Promise<AppResponse<any>> {
        try {
            let snapshpt = await this.langaugeCollection.where("code", "==", code).get();
            if (snapshpt.docs.length > 0) {
                let doc = snapshpt.docs[0].data();
                return new AppResponse(doc, null);
            }
            return new AppResponse(null, "Not found");
        } catch (error) {
            new AppResponse(null, error);
        }
    }


}


export default LanguageController;