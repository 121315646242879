import firebase from "firebase";

export default class CategoryController {
  static CATEGORIES = "categories";

  static async getCategory(id: string): Promise<{}> {
    try {
      var snapshot = await firebase.firestore().collection(CategoryController.CATEGORIES).doc(id).get();
      var data = snapshot.data();
      if (data)
          return data;
      return "Does not exists";
    } catch (error) {
      console.log("Error", error)
      return null;
    }
  }

  static async getCategories(): Promise<Array<any>> {
    try {
      var snapshot = await firebase.firestore().collection(CategoryController.CATEGORIES).get();
      var result = snapshot.docs.map((val) => val.data());
      return result;
    } catch (error) {
      console.log("Error", error);
      return null;
    }
  }
}
