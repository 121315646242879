import SourceString from './models/SourceString';


class ReactGAApp {

    static initialize() {
        // const trackingId = "UA-138666720-1";
        // ReactGA.initialize(trackingId);
        // console.log("Analytics added");
    }

    static async emitVideoClick(sourceString: SourceString) {
        // console.log(sourceString);
        // if (!sourceString.text) {
        //     return;
        // }
        // let data = "";
        // let user = await LoginController.getCurrentUser();
        // if (user != null) {
        //     data += `${user.username} viewed `;
        // }
        // else {
        //     data += `Anonymous viewed `;
        // }
        // data += `${sourceString.text}`;
        // ReactGA.event({
        //     category: AppConstants.VIDEO_VIEW,
        //     action: data,

        // })
    }

}

export default ReactGAApp;