import { Button, Form, Input, Modal, Result } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import React, { Component } from 'react';
import { BrowserView, MobileView } from 'react-device-detect';
import { withRouter } from 'react-router-dom';
import { Image, Select } from 'semantic-ui-react';
import CategoryController from '../../controllers/CategoryController';
import HashtagController from '../../controllers/HashtagController';
import IntentController from '../../controllers/IntentsController';
import { ProfileType } from '../../controllers/ProfileController';
import SourceStringController from '../../controllers/SourceStringController';
import StoryController from '../../controllers/StoryController';
import VideoController from '../../controllers/VideoController';
import { ContentType } from '../../models/upload';
import { authConnector } from '../../stores/auth/connector';
import UploadPanel from '../uploadPanel/UploadPanel';
import VideoGallery from '../videoGallery/VideoGallery';
import './style.css';

const profiles = [{
  text: 'Customer',
  value: 'customer',
}, {
  text: 'Store',
  value: 'store',
}, {
  text: 'Sutra',
  value: 'sutra',
}];

const profileMap = {
  sutra: 'Sutra',
  store: 'Supplier',
  customer: 'Consumer',
};

class UploadPage extends Component<any, any> {
  state = {
    videoUrl: null,
    videoThumbnail: null,
    categories: [],
    hashtags: [],
    intents: [],
    sourceStrings: [],
    category: '',
    storyHashtags: [],
    storyIntents: [],
    storySourceStrings: [],
    title: '',
    description: '',
    profile: 'customer',
    error: null,
    showGallery: false
  }

  componentDidMount() {
    const { user } = this.props;
    const profile = { ...user };
    if (!profile) {
      this.setState({ error: "Profile not exists" })
      return;
    }
    this.loadCategories();
    this.loadHashtags();
    if (profile.type == ProfileType.CUSTOMER) {
      this.loadIntents();
    }
    if (profile.type === 'sutra') {
      this.loadSorceStrings();
    }
  }

  loadCategories = async () => {
    const data = await CategoryController.getCategories();
    const categories = data.map(category => Object.assign({}, { text: category.name, value: category.id }));
    this.setState({
      categories,
    });
  }

  loadHashtags = async () => {
    const data = await HashtagController.getHomeHashtags();
    const hashtags = data.map(hashtag => Object.assign({}, { text: hashtag.name, value: hashtag.id }));
    this.setState({
      hashtags,
    });
  }

  loadIntents = async () => {
    const data = await IntentController.getIntents();
    const intents = data.map(intent => Object.assign({}, { text: intent.name, value: intent.id }));
    console.log("Intents", intents)

    this.setState({
      intents,
    })
  }

  loadSorceStrings = async () => {
    const data = await SourceStringController.getSourceStrings();
    const sourceStrings = data.map(source => Object.assign({}, { text: source.name, value: source.id }));
    this.setState({
      sourceStrings,
    })
  }

  onSubmit = async () => {
    const { user, history } = this.props;
    const profile = { ...user };
    const { videoUrl, videoThumbnail, category, storyHashtags, title, description, storyIntents, storySourceStrings } = this.state;
    if (!profile.id || !profile.type) {
      this.setState({
        error: 'Profile does not exist'
      });
      return;
    }
    const videoData = {
      videoUrl,
      videoThumbnail,
    }
    const video = await VideoController.addVideo(videoData);
    const videoId = !video.error ? video.data : ''
    // const userProfile = profileData[0];
    const data = {
      title,
      description,
      videoUrl,
      videoThumbnail,
      category,
      profileId: profile.id,
      videoId,
      type: profile.type,
      hashtags: storyHashtags,
      intents: [],
      sourceStrings: [],
      recordedAt: new Date(),
    }

    if (profile.type === 'customer') {
      data.intents = storyIntents;
    }

    if (profile.type === 'sutra') {
      data.sourceStrings = storySourceStrings;
    }

    const response = await StoryController.addStory(data);
    history.push('/');
  }

  render() {
    const { videoUrl, videoThumbnail, hashtags, categories, error, intents, sourceStrings, showGallery } = this.state;
    const { user } = this.props;
    const profile = { ...user };
    const { type = '' } = profile;
    return (
      <div className="upload-page">
        <div className="upload-title">
          <div style={{ fontSize: "3rem", color: "rgba(0,0,0,1)", fontWeight: "bold" }}>{type == ProfileType.SUTRA ? "Upload" : "Post your Requirement"} </div>
          <div style={{ fontSize: "1rem", color: "rgba(0,0,0,0.5)", textAlign: "center" }}>Describe your problem</div>
        </div>
        <Form className="upload-form">
          <BrowserView>
            {this.buildVideo()}
          </BrowserView>
          <div className="upload-body-form">
            <div className="form-title">Title</div>
            <Input className="login-input" size="default" required placeholder="Title" onChange={e => this.setState({ title: e.target.value })} />
            <div className="form-title">Description</div>
            <TextArea className="form-input" required rows={3} placeholder="Write something about your story" onChange={e => this.setState({ description: e.target.value })} />
            <div className="form-title">Profile </div>
            <div>{profileMap[profile.type]}</div>
            {/* <div className="form-title">Categories</div>
            <Select placeholder="Select Category" name="category" options={categories} onChange={(e, { name, value }) => { this.setState({ [name]: value }) }} /> */}
            <div className="form-title">Hashtags</div>
            <Select multiple placeholder="Select Hashtags" name="storyHashtags" options={hashtags} onChange={(e, { name, value }) => { this.setState({ [name]: value }) }} />
            {/* {type === 'customer' && <div className="form-title">Intents</div>} */}
            {/* {type === 'customer' && <Select multiple placeholder="Select Intents" name="storyIntents" options={intents} onChange={(e, {name, value}) => {this.setState({[name]: value})}}/>} */}
            {/* {type === 'sutra' && <div className="form-title">Source Strings</div>} */}
            {/* {type === 'sutra' && <Select multiple placeholder="Select Source Strings" name="storySourceStrings" options={sourceStrings} onChange={(e, {name, value}) => {this.setState({[name]: value})}}/>} */}
            <MobileView>
              {this.buildVideo()}
              <div className="form-title">Cover</div>
              <div className="upload-cover">
                {videoThumbnail ?
                  <Image src={videoThumbnail} size="medium" /> :
                  <UploadPanel onFileUpload={videoThumbnail => this.setState({ videoThumbnail })} uploadType={ContentType.Image} description={"Choose a thumbnail for your video"} />
                }
              </div>
            </MobileView>
            <div style={{ height: "1rem" }} />
            <Button className="upload-action" size="large" type="primary" block onClick={this.onSubmit}>Upload</Button>
          </div>
          <div >
            {error && <Result title="Error" subTitle={error} />}
          </div>
        </Form>
        <Modal onCancel={() => this.setState({ showGallery: false })} footer={[]} visible={showGallery}><VideoGallery onSubmitted={(video) => {
          this.setState({ videoUrl: undefined });
          setTimeout(() => {
            this.setState({ ...video, showGallery: false })
          }, 100)
        }} /></Modal>
      </div>
    );
  }

  buildVideo() {
    const { videoUrl, videoThumbnail } = this.state;
    return <>
      <div className="upload-video">
        <div className="form-title">{"Video"}</div>
        {/* <Button onClick={() => {
          this.setState({ showGallery: true })
        }} type="primary" size="large" >Choose From Gallery</Button>
        <div style={{ height: "1rem" }} />
        <p>Or</p> */}
        {videoUrl ?
          <video controls height={"60%"} width={"100%"}>
            <source type="video/webm" src={videoUrl} />
            <source type="video/mp4" src={videoUrl} />
            <source type="video/ogg" src={videoUrl} />
          </video> :
          <UploadPanel description={"Choose a video for your story"} onFileUpload={videoUrl => this.setState({ videoUrl })} uploadType={ContentType.Video} />
        }
        <div style={{ height: "0.6rem" }} />
        {
          videoThumbnail ?
            <Image src={videoThumbnail} size="medium" /> :
            <UploadPanel onFileUpload={videoThumbnail => this.setState({ videoThumbnail })} uploadType={ContentType.Image} description={"Choose a thumbnail for your video"} />
        }
      </div>
    </>;
  }

}

export default authConnector(withRouter(UploadPage));