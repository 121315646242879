import { Empty, Input, Layout, Spin } from 'antd';
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Header } from 'semantic-ui-react';
import IntentsController from '../../controllers/IntentsController';
import ProductController from '../../controllers/ProductController';
import { ProfileType } from '../../controllers/ProfileController';
import SourceStringController from '../../controllers/SourceStringController';
import SpecController from '../../controllers/SpecController';
import StoryController from '../../controllers/StoryController';
import AppConstants from '../../helpers/AppConstants';
import AppRoutes from '../../helpers/AppRoutes';
import HomeNavigationComponent from '../homeNavigation/HomeNavigationComponent';
import SourceStringComponent from '../story/StoryComponent';
import './style.css';

const { Search } = Input;

class DiscoverPage extends Component<any, any> {

    state = {
        isLoading: true,
        stories: [],
        sourceStrings: {},
        intents: {},
        products: [],
        query: '',
        specs: {},
        type: undefined,
        selected: ProfileType.getPluralType(ProfileType.SUTRA),
    }


    getTabs = () => {
        return [ProfileType.getPluralType(ProfileType.SUTRA), AppConstants.Products];
    }


    componentDidMount() {
        this.loadData()
    }

    loadData = async () => {
        this.setState({ isLoading: true });
        await Promise.all([
            async () => {
                let response = await StoryController.getHomeStories();
                if (response.data)
                    this.setState({ stories: response.data })
            },
            async () => {
                let result = await IntentsController.getIntents();
                var intents = {};
                if (result) {
                    result.forEach((val) => {
                        intents[val.id] = val;
                    })
                }
                this.setState({ intents: intents })
            },
            async () => {
                let result = await SourceStringController.getSourceStrings();
                var sourceStrings = {};
                if (result) {
                    result.forEach((val) => {
                        sourceStrings[val.id] = val;
                    })
                }
                this.setState({ sourceStrings })
            },
            async () => {
                let result = await SpecController.getSpecs();
                var specs = {};
                if (result.data) {
                    result.data.forEach((val) => {
                        specs[val.id] = val;
                    })
                }
                this.setState({ specs })
            },
            async () => {
                let result = await ProductController.getProducts();
                if (result.data) {
                    this.setState({ products: result.data })
                }

            },
        ].map((e) => (e)()));
        this.setState({ isLoading: false });
    }


    onSelected = (tab) => {
        this.setState({ selected: tab })
    }

    onSearch = query => {
        this.setState({ query })
    }



    render() {
        const { isLoading = true, selected } = this.state;
        let index = this.getTabs().indexOf(selected);
        return (
            <Layout className="discover-page" style={{ background: "white" }}>
                <HomeNavigationComponent tabs={this.getTabs()} onSelected={this.onSelected} selected={selected} />
                {isLoading ? <Spin /> : <div />}
                <div style={{ overflow: "scroll" }}>
                    <Search
                        placeholder="Search Here"
                        allowClear
                        enterButton="Search"
                        size="large"
                        onChange={(e) => this.onSearch(e.target.value)}
                        style={{ margin: "2rem 2rem", width: "80%" }}
                        onSearch={this.onSearch}
                    />
                    {index == 0 ? this.buildSourceStringView() : <div />}
                    {index == 1 ? this.buildSpecsView() : <div />}
                </div>
            </Layout>
        );
    }

    buildIntentView = () => {
        let { stories = [], intents = {}, query = '' } = this.state;

        let intentsMap = {};
        stories.forEach((story) => {
            if (!story) {
                return;
            }
            let { intents = [], sourceStrings = [] } = story;
            intents.forEach((item) => {
                if (!intentsMap[item]) {
                    intentsMap[item] = [];
                }
                intentsMap[item].push(story);
            });
        });
        Object.keys(intents).forEach((id) => {
            let intent = intents[id];
            if (intent.name && intent.name.toLowerCase().indexOf(query.toLowerCase()) < 0) {
                intentsMap[id] = undefined;
            }
        })
        return <div> {Object.keys(intentsMap).map((id) => intentsMap[id] ? this.buildList(intents[id], intentsMap[id]) : <div />)}</div>
    }

    buildSourceStringView = () => {
        const { stories = [], sourceStrings = {}, query = '' } = this.state;
        let sourceStringMap = {};
        stories.forEach((story) => {
            if (!story) {
                return;
            }
            let { sourceStrings = [] } = story;
            sourceStrings.forEach((item) => {
                if (!sourceStringMap[item]) {
                    sourceStringMap[item] = [];
                }
                sourceStringMap[item].push(story);
            });
        });
        Object.keys(sourceStrings).forEach((id) => {
            let sourceString = sourceStrings[id];
            if (sourceString.name && sourceString.name.toLowerCase().indexOf(query.toLowerCase()) < 0) {
                sourceStringMap[id] = undefined;
            }
        })
        return <div> {Object.keys(sourceStringMap).map((id) => sourceStringMap[id] ? this.buildList(sourceStrings[id], sourceStringMap[id]) : <div />)}</div>
    }

    buildSpecsView = () => {
        const { products = [], specs = {}, query = '' } = this.state;
        let specsMap = {};
        products.forEach((product) => {
            if (!product) {
                return;
            }
            let { specs = [] } = product;
            specs.forEach((item) => {
                if (!specsMap[item]) {
                    specsMap[item] = [];
                }
                specsMap[item].push(product);
            });
        });
        Object.keys(specs).forEach((id) => {
            let spec = specs[id];
            if (spec.name && spec.name.toLowerCase().indexOf(query.toLowerCase()) < 0) {
                specsMap[id] = undefined;
            }
        })
        return <div> {Object.keys(specsMap).map((id) => specsMap[id] ? this.buildList(specs[id], specsMap[id]) : <div />)}</div>
    }


    buildList = (hashtag, stories) => {
        if (!hashtag) {
            return <div />
        }
        hashtag = hashtag || {};
        stories = stories || [];
        if (stories.length == 0) {
            return <div />
        }
        const { selected } = this.state;
        return <div >
            <Header as="h2" style={{ margin: '2rem 0 0 2rem' }}># {hashtag.name}</Header>
            <div style={{ margin: '0 2rem' }}>
                {stories.length ?
                    <div className="hashtag-story-container">
                        {stories.map((val) =>
                            <div className="hashtag-story-slide">
                                <SourceStringComponent customClass="hashtag-story" key={val.id} onProfileClick={(val) => {
                                    this.props.history.push(AppRoutes.USER_PROFILE(val.type, val.id))
                                }}
                                    onClick={(val) => {
                                        if (selected == AppConstants.Products) {
                                            //
                                        } else
                                            this.props.history.push(AppRoutes.STORY(val.id))
                                    }}
                                    sourceString={val}
                                />
                            </div>
                        )}
                    </div> : <Empty />
                }
            </div>
        </div>
    }


}

export default withRouter(DiscoverPage);