import React, { Component } from 'react';
import Dragger from 'antd/lib/upload/Dragger';
import { Icon, Result, Button, Upload, Progress, Layout } from 'antd';
import UploadController from '../../controllers/UploadController';
import { RcFile } from 'antd/lib/upload';
import firebase from 'firebase';
import './UploadPanelStyle.css';
import { ContentType } from '../../models/upload';

type UploadProps = {
    onFileUpload: (filePath: string, type: string) => void,
    uploadType: string,
    title?: string,
    description?: string
}

class UploadPanel extends Component<UploadProps, any> {

    state = {
        file: null,
        uploadTask: null,
        progress: 0,
        completed: false,
        filePath: null,
        downloadFile: null
    }

    uploadFile = async (file: File) => {
        this.setState({ file: file });
        let uploadTask = UploadController.uploadFile(file, this.props.uploadType);
        this.setState({
            uploadTask: uploadTask
        })
        uploadTask.on(firebase.storage.TaskEvent.STATE_CHANGED, (snapshot) => {
            var percent = (snapshot.bytesTransferred / snapshot.totalBytes * 100).toFixed(2);
            console.log(percent + "% done");
            this.setState({
                progress: percent
            });
        });
        uploadTask.on(
            firebase.storage.TaskEvent.STATE_CHANGED,
            null,
            null,
            () => {
                console.log(uploadTask.snapshot);
                uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
                    console.log(downloadURL)
                    this.setState({
                        completed: true,
                        filePath: downloadURL
                    })
                    this.props.onFileUpload(downloadURL, this.props.uploadType)
                });

            });

    }
    render() {
        let { file } = this.state;
        return (
            !file ? this.buildPlaceholder() : this.buildUploadStatus()
        )
    }

    buildPlaceholder = () => {
        const props = {
            name: 'file',
            multiple: true,
            action: 'https://www.mocky.io/v2/5cc8019d300000980a055e76',
            onChange: (info) => {
                const file = info.file;
                console.log(file);
                this.uploadFile(file);
            },
            beforeUpload: () => { return false }
        };
        const { uploadType } = this.props;
        return <Dragger {...props} accept={uploadType == ContentType.Video ? ".mp4" : ".png, .gif, .jpg"} style={{ padding: '1rem' }}>
            <p className="ant-upload-drag-icon">
                <Icon type="inbox" />
            </p>
            <p className="ant-upload-text">{this.props.title || "Click or drag file to this area to upload"}</p>
            <p className="ant-upload-hint">
                {this.props.description || " Support for a single or bulk upload. Strictly prohibit from uploading company data or otherband files"}
            </p>
        </Dragger>
    }

    buildUploadStatus = () => {
        const { file, progress, completed, uploadTask, filePath } = this.state;
        return <div>
            <Result
                icon={<Progress
                    type="circle"
                    strokeColor={{
                        '0%': '#108ee9',
                        '100%': '#87d068',
                    }}
                    percent={progress}
                />}
                title={<div>{"Uploading file " + (progress) + "%"}</div>}
                subTitle={file.name}
                extra={!completed ? <Button type="danger" onClick={() => { uploadTask.cancel() }}>Cancel</Button>
                    : <Button type="primary" onClick={() => {
                        this.setState({
                            file: null
                        })

                    }}>Send</Button>}
            />
        </div>
    }
}

export default UploadPanel;