import { List, Spin } from 'antd';
import React, { Component } from 'react';
import MessageController from '../../controllers/MessageController';
import Message from '../../models/chat/Message';
import MessageComponent from '../chat/message/MessageCompoent';
import SearchBox from '../searchBox/SearchBox';

type SearchProps = {
    chatId: string
}

class ChatSearchModal extends Component<SearchProps, any> {

    state = {
        isSearching: false,
        messages: []
    }

    searchQuery = async (query) => {
        this.setState({
            isSearching: true
        })
        let result = await MessageController.searchMessages(this.props.chatId, query);
        this.setState({
            isSearching: false,
            messages: result
        })
    }

    render() {
        const { isSearching, messages } = this.state;

        return (
            <div>
                <SearchBox
                    onSearch={value => this.searchQuery(value)}
                />
                {isSearching ? <Spin tip="Searching" /> : <List itemLayout="horizontal" dataSource={messages} renderItem={(message: Message) => {
                    return <MessageComponent message={message} />;
                }}></List>}
            </div>
        );
    }
}

export default ChatSearchModal;