import * as firebase from 'firebase';
var firebaseConfig = {
  apiKey: "AIzaSyB2-Elsl_JQEy0a9BtlTtqR4DyWEVxO-iA",
  authDomain: "sutras-a72f7.firebaseapp.com",
  databaseURL: "https://sutras-a72f7.firebaseio.com",
  projectId: "sutras-a72f7",
  storageBucket: "sutras-a72f7.appspot.com",
  messagingSenderId: "1015801388194",
  appId: "1:1015801388194:web:1854770bdcf8c13c850bfb",
  measurementId: "G-21VNJLQ49D"
};
class Firebase {
  constructor() {
    if (!firebase.apps.length) {
      firebase.initializeApp(firebaseConfig);

    }
  }
}

export default Firebase;
