import firebase, { firestore } from "firebase";
import { v4 as uuidv4 } from 'uuid';
import AppResponse from "../models/AppResponse";



class VideoController {

    static VIDEOS = "videos";

    static async addVideo(video): Promise<AppResponse<string>> {
        try {
            let temp = { ...video };
            temp.id = uuidv4();
            await firestore().collection(this.VIDEOS).doc(temp.id).set(temp);
            return new AppResponse(temp.id, undefined);
        } catch (error) {
            console.log("Error", error);
            return new AppResponse(undefined, error);
        }
    }
    static async updateVideo(video): Promise<AppResponse<boolean>> {
        try {

            await firestore().collection(this.VIDEOS).doc(video.id).update(video);
            return new AppResponse(true, null);
        } catch (error) {
            console.log("Error", error);
            return new AppResponse(null, error);
        }
    }
    static async deleteVideo(id, undo?: boolean): Promise<AppResponse<boolean>> {
        try {
            if (undo == undefined) {
                undo = false;
            }
            await this.updateVideo({ "isDeleted": !undo, id: id })
            return new AppResponse(true, null);
        } catch (error) {
            console.log("Error", error);
            return new AppResponse(null, error);
        }
    }


    static async getDetails(id): Promise<{}> {
        try {
            var snapshot = await firestore().collection(this.VIDEOS).doc(id).get();
            return snapshot.data();
        } catch (error) {
            return undefined;
        }
    }

    static async seeVideo(videoId: string): Promise<boolean> {
        try {
            let count = firebase.firestore.FieldValue.increment(1);
            var snapshot = await firestore().collection(this.VIDEOS).doc(videoId).update({ "viewCount": count });
            return true;
        } catch (error) {
            return undefined;
        }
    }

    static async getList(): Promise<Array<any>> {
        try {
            var snapshot = await firestore().collection(this.VIDEOS).get();
            if (snapshot.docs.length > 0) {
                return snapshot.docs.map((e) => e.data());
            }
            return [];
        } catch (error) {
            return [];
        }
    }

    static async parseData(data): Promise<any> {
        let newData = [...data]
        let videoData = {};
        await Promise.all(Object.keys(newData).map(async (key) => {
            let val = newData[key];
            if (val && val.videoId) {
                if (videoData[val.videoId]) {
                    val.video = videoData[val];
                }
                else {
                    let result = await this.getDetails(val.videoId);
                    if (result) {
                        val.video = result
                        videoData[val.videoId] = result
                    }
                }
            }
        }));
        return newData;
    }



}

export default VideoController;