import React, { Component } from 'react';
import { BrowserView, MobileView } from 'react-device-detect';
import ReactGA from 'react-ga';
import { Redirect, Route, Router, Switch } from 'react-router-dom';
import 'semantic-ui-css/semantic.min.css';
import 'swiper/swiper.scss';
import './App.css';
import BrowserApp from './components/app/BrowserApp';
import MobileApp from './components/app/MobileApp';
import ChatPage from './components/chatPage/ChatPage';
import DiscoverItemPage from './components/discover/DiscoverItemPage';
import DiscoverPage from './components/discover/DiscoverPage';
import HashtagPage from './components/hashtagPage/HashtagPage';
import LoginPage from './components/login/LoginPage';
import ProfileEditPage from './components/myProfile/ProfileEditPage';
import NotFoundPage from './components/notFound/NotFoundPage';
import ProductNewDetailPage from './components/product/ProductNewDetailPage';
import StoryPage from './components/storyPage/StoryPage';
import SugesstedPage from './components/suggestionsBox/SugesstedPage';
import UploadPage from './components/uploadPage/UploadPage';
import UserProfilePage from './components/userProfilePage/UserProfilePage';
import ProfileController from './controllers/ProfileController';
import Firebase from './Firebase';
import './GoogleAnalytics';
import ReactGAApp from './GoogleAnalytics';
import appHistory from './helpers/AppHistory';
import AppRoutes from './helpers/AppRoutes';
import { authConnector } from './stores/auth/connector';


const FirebaseApp = new Firebase();

ReactGAApp.initialize();

appHistory.listen(location => {
  ReactGA.set({ page: location.pathname }); // Update the user's current page
  ReactGA.pageview(location.pathname); // Record a pageview for the given page
});


class App extends Component<any> {


  constructor(props) {
    super(props);
  }

  componentDidMount() {
    this.updateProfile();
  }

  updateProfile = async () => {
    const { user } = this.props;
    let response = await ProfileController.getWholeDetails(user.id);
    if (response) {
      this.props.updateUser(response)
    }
    else {
      this.props.logOut();
    }
  }

  componentWillUnmount() {
  }

  buildHome = () => {
    return (
      <>
        <BrowserView style={{ height: "100%" }}>
          <BrowserApp />
        </BrowserView>
        <MobileView style={{ height: "100%" }}>
          <MobileApp />
        </MobileView>
      </>
    );
  }


  render() {
    const { user } = this.props;
    return (
      <Router history={appHistory}>
        <>

          <Switch>

            <Route path={"/chat"} component={ChatPage} />
            <Route path={AppRoutes.HASHTAG(":id")} component={HashtagPage} />
            <Route path={AppRoutes.DISCOVER_ITEM(":id")} component={DiscoverItemPage} />
            <Route path={AppRoutes.DISCOVER} component={DiscoverPage} />
            <Route path={AppRoutes.CUSTOMER(":id")} component={UserProfilePage} />
            <Route path={AppRoutes.APP(":id")} component={UserProfilePage} />
            <Route path={AppRoutes.SUTRA(":id")} component={UserProfilePage} />
            <Route path={AppRoutes.USER_PROFILE("user-profile", ":id")} component={UserProfilePage} />
            <Route path={AppRoutes.STORY(":id")} component={StoryPage} />
            <Route path={AppRoutes.PRODUCT(":id")} component={ProductNewDetailPage} />
            <Route path={AppRoutes.UPLOAD_PAGE} component={UploadPage} />
            <Route path={AppRoutes.SUGGESTED} component={SugesstedPage} />
            <Route exact path={AppRoutes.PROFILE_SETTINGS} component={ProfileEditPage} />
            <Route path={AppRoutes.PROFILE} render={() => user ? <Redirect to={AppRoutes.USER_PROFILE(user.type, user.username)} /> : <Redirect to={AppRoutes.LOGIN} />} />
            <Route path={AppRoutes.LOGIN} component={LoginPage} />
            <Route path={'/home'} render={(props) => this.buildHome()} />
            <Route exact path={AppRoutes.HOME} render={(props) => this.buildHome()} />
            <Route path={AppRoutes.UNKNOWN} component={NotFoundPage} />
          </Switch>
        </>
      </Router>

    )
  }
}

export default authConnector(App);
