import assert from "assert";
import { firestore } from "firebase";
import { v4 } from 'uuid';
import AppResponse from "../models/AppResponse";
import LanguageController from "./LanguageController";

type Spec = {
    id,
    title,
    language
}

class SpecController {

    static SPECS = "specs";

    static async addSpec(spec): Promise<AppResponse<boolean>> {
        try {
            let temp = { ...spec };
            assert(spec.name && spec.language)
            temp.id = v4();
            await firestore().collection(this.SPECS).doc(temp.id).set(temp);
            return new AppResponse(true, undefined);
        } catch (error) {
            return new AppResponse(undefined, error);
        }
    }


    static async deleteSpec(id, undo?: boolean): Promise<AppResponse<boolean>> {
        try {
            if (undo == undefined) {
                undo = false;
            }
            await firestore().collection(this.SPECS).doc(id).delete();
            return new AppResponse(true, null);
        } catch (error) {
            console.log("Error", error);
            return new AppResponse(null, error);
        }
    }


    static onSpecsLoaded(onLoaded: (val) => void) {
        try {
            firestore().collection(this.SPECS).onSnapshot(async (snapshot) => {
                var result = snapshot.docs.map((val) => val.data());
                let newResult = await LanguageController.parseData(result);
                onLoaded(newResult);
            })

        } catch (error) {
            console.log("Error", error);

        }
    }

    static async getDetails(id): Promise<{}> {
        try {
            var snapshot = await firestore().collection(this.SPECS).doc(id).get();
            return snapshot.data();
        } catch (error) {
            return undefined;
        }
    }

    static async updateSpec(spec): Promise<AppResponse<boolean>> {
        try {

            await firestore().collection(this.SPECS).doc(spec.id).update(spec);
            return new AppResponse(true, null);
        } catch (error) {
            console.log("Error", error);
            return new AppResponse(null, error);
        }
    }

    static async getSpecs(): Promise<AppResponse<Array<any>>> {
        try {
            let languageResponse = await LanguageController.getLanguageByCode(LanguageController.currentLanguage);
            if (languageResponse.error) {
                return new AppResponse([]);
            }
            let language = languageResponse.data;
            var snapshot = await firestore().collection(this.SPECS).where("language", "==", language.id).get();
            var result = snapshot.docs.map((val) => val.data());
            let newResult = await LanguageController.parseData(result);
            return new AppResponse(newResult, null);

        } catch (error) {
            console.log("Error", error);
            return new AppResponse(null, error.toString());

        }
    }


}

export default SpecController;