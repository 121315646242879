import { Layout, PageHeader, Spin } from 'antd';
import React, { Component } from 'react';
import { RouteComponentProps, StaticContext, withRouter } from 'react-router';
import { Route, Switch } from 'react-router-dom';
import AppRoutes from '../../helpers/AppRoutes';
import ProfileAddPage from '../ProfileAddPage/ProfileAddPage';
import ForgotEmailPage from './ForgotEmailPage';
import LoginEmailPage from './LoginEmailPage';
import LoginMobilePage from './LoginMobilePage';
import LoginOptionsPage from './LoginOptionsPage';
import FillDetailsPage from './ProfileCompletePage';
import SignupEmailPage from './SignupEmailPage';
import './style.css';

class LoginPage extends Component<RouteComponentProps<any, StaticContext>, any>  {

    state = {
        isLoading: false,
        isSignup: false
    }

    componentDidMount() {
        this.authListener = this.authListener.bind(this);
        this.authListener();
        let pathname = this.props.history.location.pathname;

    }


    componentWillUnmount() {
        this.authListener = undefined;
    }

    authListener() {
        // firebase.auth().onAuthStateChanged((user) => {
        //     if (user) {
        //         let user = LoginController.getNewCurrentUser();
        //         if (user && !user.profileComplete) {
        //             this.props.history.push(AppRoutes.PROFILE_COMPLETE)
        //         }
        //         else {
        //             this.props.history.push(AppRoutes.HOME)
        //         }
        //     } else {
        //         this.setState({
        //             isLoading: false
        //         })
        //     }
        // });
    }

    render() {
        const { isLoading, } = this.state;
        return (
            <>
                {isLoading ? <Spin /> : <Layout className="login-page">
                    <PageHeader title={<div style={{ display: "inline-flex", margin: "auto", width: "80vw", alignItems: "center", alignContent: "center", }}>
                        <a href={AppRoutes.HOME}>  <img src="/logo.jpg" style={{ height: "2rem" }} /></a>
                    </div>} style={{ background: "white", boxShadow: "0 4px 6px -6px #999" }}

                    />
                    <div className="login-body">
                        <Switch>
                            <Route path={AppRoutes.LOGIN_MOBILE} component={LoginMobilePage} />
                            <Route path={AppRoutes.LOGIN_EMAIL} component={LoginEmailPage} />
                            <Route path={AppRoutes.SIGNUP_EMAIL} component={SignupEmailPage} />
                            <Route path={AppRoutes.FORGOT_EMAIL} component={ForgotEmailPage} />
                            <Route path={AppRoutes.PROFILE_COMPLETE} component={FillDetailsPage} />
                            <Route exact path={AppRoutes.ADD_PROFILE(":type")} component={ProfileAddPage} />
                            <Route path={AppRoutes.LOGIN} component={LoginOptionsPage} />
                        </Switch>
                    </div>
                    <footer className="login-footer">
                        <div style={{ width: '0', flexGrow: 1 }} />
                        <div className="company-label">© 2020 Vanilla Industries</div>
                        <div style={{ width: '0', flexGrow: 1 }} />

                    </footer>
                </Layout>
                }
            </>
        );
    }
}

export default withRouter(LoginPage);