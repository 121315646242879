import { Button, Col, Drawer, Icon, Row } from 'antd';
import React, { Component } from 'react';
import { BrowserView, MobileView } from 'react-device-detect';
import { withRouter } from 'react-router-dom';
import AppRoutes from '../../helpers/AppRoutes';
import { connectedRouteProps } from '../../props';
import { authConnector } from '../../stores/auth/connector';
import LangSelection from '../langSelection/LangSelection';
import ProfileAvatarComponent from '../profileAvatarComponent/ProfileAvatarComponent';
import SidePanel from '../sidePanel/SidePanel';
import TabBar from '../tabs/TabBar';
import UploadButton from '../uploadButton/UploadButton';
import './style.css';


type navigationProps = connectedRouteProps & {
    onSelected: (type: string) => void,
    selected: string
}

class HomeNavigationComponent extends Component<navigationProps, any> {

    state = {
        drawerVisible: false
    }

    render() {

        return (
            <>
                <BrowserView>{this.buildDesktopView()}</BrowserView>
                <MobileView>{this.buildMobileView()}</MobileView>
            </>
        );
    }

    buildMobileView() {
        const { drawerVisible } = this.state;
        const { selected, onSelected, tabs = [] } = this.props;
        return (
            <div >
                <Row className="home-navigation">
                    <div className="home-navigation-body">
                        <div style={{ width: "1rem" }}></div>
                        <a href={AppRoutes.HOME}><img src="/icon.jpg" className="home-logo" /></a>
                        <TabBar tabs={tabs} onTabChanged={onSelected} current={selected} />
                        <Button style={{ marginRight: '0.3rem' }} onClick={() => this.setState({ drawerVisible: true })}><Icon type="menu" className="drawer-icon" /></Button>
                    </div>
                    <Drawer visible={drawerVisible}
                        placement={"right"}
                        width={window.innerWidth < 480 ? "80%" : "25%"}
                        height={"100%"}
                        onClose={() => { this.setState({ drawerVisible: false }) }}>
                        <SidePanel />
                    </Drawer>
                </Row>

            </div>
        );
    }




    buildDesktopView() {
        const { drawerVisible } = this.state;
        const { selected, onSelected, tabs = [] } = this.props;
        return (
            <div >
                <Row className="home-navigation">
                    <Col span={17} className="home-navigation-body">
                        <Button type="link" onClick={() => this.setState({ drawerVisible: true })}><Icon type="menu" className="drawer-icon" /></Button>
                        <div style={{ width: "1rem" }}></div>
                        <a href={AppRoutes.HOME}><img src="/logo.jpg" className="home-logo" /></a>
                        <TabBar tabs={tabs} onTabChanged={onSelected} current={selected} />
                    </Col>
                    <Col span={7} className="home-navigation-menu">
                        <UploadButton />
                        <div style={{ width: "1rem" }} />
                        <ProfileAvatarComponent />
                        <div style={{ width: "1rem" }} />
                        <LangSelection />
                    </Col>
                    <Drawer visible={drawerVisible}
                        placement={"left"}
                        width={window.innerWidth < 480 ? "80%" : "25%"}
                        height={"100%"}
                        onClose={() => { this.setState({ drawerVisible: false }) }}>
                        <SidePanel />
                    </Drawer>
                </Row>

            </div>

        );
    }
}

export default authConnector(withRouter(HomeNavigationComponent));