import { Select } from "antd";
import i18next from "i18next";
import * as React from "react";
import LanguageController from '../../controllers/LanguageController';

const { Option } = Select;

const LangSelection = (props) => {
    const changeLanguage = (language: string) => {
        i18next.changeLanguage(language);
        window.location.reload();
    };
    const currentLang = LanguageController.currentLanguage;
    const languageOptions = LanguageController.getLocalLanguages();
    return <Select defaultValue={currentLang} onChange={changeLanguage} size="large"  {...props}>
        {
            Object.keys(languageOptions).map((key) => <Option value={key}>{key}</Option>)
        }
    </Select>
}

export default LangSelection;