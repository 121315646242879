import { BottomNavigation, BottomNavigationAction } from "@material-ui/core";
import { Chat, CompassCalibration, Home, Search } from "@material-ui/icons";
import { Layout } from "antd";
import { Component, default as React } from "react";
import { Trans } from "react-i18next";
import { Route, Switch, withRouter } from "react-router-dom";
import { authConnector } from "../../stores/auth/connector";
import ChatPage from "../chatPage/ChatPage";
import ChatPopup from "../chatPopup/ChatPopup";
import DiscoverPage from "../discover/DiscoverPage";
import HomePage from "../home/HomePage";
import SugesstedPage from "../suggestionsBox/SugesstedPage";
import './style.css';

class MobileApp extends Component<any, any> {

    componentDidMount() {
        let index = this.getCurrentIndex();
        this.props.history.replace(this.getPath(index));
    }

    state = {
        currentPage: 0
    }

    getClass(index) {
        const { currentPage } = this.state;
        return "home-mobile-page " + (currentPage == index ? "active" : "");
    }

    onChange(newValue) {

        // this.setState({ currentPage: newValue });
        let history = this.props.history;
        history.push(this.getPath(newValue));
    }

    values = ['', 'discover', 'chat', 'suggested'];

    getPath = (index) => {
        if (index == 0) {
            return '/home';
        }
        return '/home/' + this.values[index];
    }

    getCurrentIndex = () => {
        let { location } = this.props.history;
        let pathname = location.pathname;
        console.log("Pathname", pathname);
        let index = this.values.map((val, index) => this.getPath(index)).indexOf(pathname)
        if (index == -1) {
            return 0;
        }
        return index;
    }

    render() {
        const { currentPage } = this.state;
        return (
            <Layout className="home">
                <div className="home-mobile">
                    <div className="home-mobile-body" >
                        <Switch>
                            <Route exact path={this.getPath(1)} render={() => <SugesstedPage />} />
                            <Route exact path={this.getPath(2)} render={() => <ChatPage />} />
                            <Route exact path={this.getPath(3)} render={() => <DiscoverPage />} />
                            <Route exact path={'/home'} render={() => <HomePage />} />
                        </Switch>
                    </div>
                    <ChatPopup />
                    <BottomNavigation
                        style={{ background: "white" }}
                        value={this.getCurrentIndex()}
                        onChange={(event, newValue) => {
                            this.onChange(newValue)
                        }}
                        showLabels
                    >
                        <BottomNavigationAction label={<Trans>Home</Trans>} icon={<Home />} />
                        <BottomNavigationAction label={<Trans>Search</Trans>} icon={<Search />} />
                        <BottomNavigationAction label={<Trans>Chats</Trans>} icon={<Chat />} />
                        <BottomNavigationAction label={<Trans>Discover</Trans>} icon={<CompassCalibration />} />
                    </BottomNavigation>
                </div>
            </Layout>
        );
    }
}

export default authConnector(withRouter(MobileApp));