import firebase from 'firebase';
import LanguageController from './LanguageController';

class SourceStringController {


    static SOURCE_STRINGS: string = "sourceStrings";
    static SOURCE_STRING_VIEWS: string = "SourceStringViews";


    static async getSourceStrings(limit?: number): Promise<Array<any>> {
        try {
            let languageResponse = await LanguageController.getLanguageByCode(LanguageController.currentLanguage);
            if (languageResponse.error) {
                return [];
            }
            let language = languageResponse.data;
            var collectionRef = firebase.firestore().collection(SourceStringController.SOURCE_STRINGS).where("language", "==", language.id);
            var snapshot;
            if (limit) {
                snapshot = await collectionRef.limit(limit).get();
            }
            else {
                snapshot = await collectionRef.get();
            }
            var result = snapshot.docs.map((val) => val.data());
            return result;
        } catch (error) {
            console.log("Error", error);
            return null;
        }
    }

    static async getSourceString(id: string): Promise<any> {
        try {
            var snapshot = await firebase.firestore().collection(SourceStringController.SOURCE_STRINGS).doc(id).get();
            var data = snapshot.data();
            if (data)
                return data;
            return "Does not exists";
        } catch (error) {
            console.log("Error", error)
            return null;
        }
    }


}

export default SourceStringController;