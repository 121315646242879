import { Button, message } from 'antd';
import queryString from 'query-string';
import React, { Component } from 'react';
import { Trans } from 'react-i18next';
import { withRouter } from 'react-router';
import LoginController from '../../controllers/LoginController';
import { ProfileType } from '../../controllers/ProfileController';
import AppConstants from '../../helpers/AppConstants';
import AppRoutes from '../../helpers/AppRoutes';
import ProfileHelper from '../../helpers/ProfileHelper';
import { connectedRouteProps } from '../../props';
import { authConnector } from '../../stores/auth/connector';
import './style.css';
class LoginOptionsPage extends Component<connectedRouteProps, any>  {

    state = {
        type: undefined
    }

    componentDidMount() {
        let search = this.props.history.location.search;
        let query = queryString.parse(search);
        if (query["type"]) {
            this.setState({ type: query["type"] })
        }
    }

    isSignup = () => {
        let search = this.props.history.location.search;
        let query = queryString.parse(search);
        if (query["option"] && query["option"] == AppConstants.REGISTER.toLowerCase()) {
            return true;
        }
    }

    loginWithGoogle = async () => {
        const { redirectLink } = this.props;
        await LoginController.logOut();
        let response = await LoginController.loginWithGoogle();
        if (response.error) {
            message.error(response.error);
        } else {
            message.success("Logged in with google")
            let user = response.data;
            ProfileHelper.loginProfile(user, this.props.history, this.props.login, ProfileHelper.getType(this.props.history.location.search), redirectLink);
        }
    }

    loginWithFacebook = async () => {
        const { redirectLink } = this.props;
        await LoginController.logOut();
        let response = await LoginController.loginWithFacebook();
        if (response.error) {
            message.error(response.error);
        } else {
            message.success("Logged in with facebook")
            let user = response.data;
            ProfileHelper.loginProfile(user, this.props.history, this.props.login, ProfileHelper.getType(this.props.history.location.search), redirectLink);
        }
    }

    render() {

        let labelText = this.isSignup() ? "Signup" : "Login";
        let search = this.props.history.location.search;
        let { options } = this.props;
        if (!options) {
            options = ['google', 'facebook', 'mobile', 'email']
        }
        let { type = ProfileType.CUSTOMER } = this.state;
        return (
            <div className="login-cover">
                <div className="login-section">
                    <h3 className="login-title">
                        {this.isSignup() ? <Trans>
                            {labelText} as {ProfileType.getType(type)}
                        </Trans> : <Trans>{labelText} to Sutras</Trans>}
                    </h3>
                    <h4 key="login-description" className="login-description">
                        <Trans>Manage your account, check notifications, comment on videos and more.</Trans>
                    </h4>
                    {
                        options.includes("mobile") ? <>
                            <div style={{ height: '1rem ' }} />
                            <Button key={"mobile"} className="login-button" onClick={() => {
                                this.props.history.replace(AppRoutes.LOGIN_MOBILE + search)
                            }}>
                                <img src="/icons/sms.png" />
                                <Trans>{labelText} with mobile number</Trans>
                                <div style={{ width: "1rem", height: '0.5rem' }} />
                            </Button></> : <section />
                    }
                    {
                        options.includes('email') ? <>  <Button key={"username"} className="login-button" onClick={() => {
                            this.props.history.replace(AppRoutes.LOGIN_EMAIL + search)
                        }}>
                            <img src="/icons/person.png" />
                            <Trans>{labelText} with email address</Trans>
                            <div style={{ width: "1rem", height: '0.5rem' }} />
                        </Button></> : <section />
                    }
                    {
                        options.includes('facebook') ? <Button key={"facebook"} className="login-button" onClick={async () => {
                            let response = await LoginController.loginWithFacebook();
                            if (response.error) {
                                message.error(response.error);
                            } else {
                                this.loginWithFacebook();
                            }
                        }}>
                            <img src="/icons/facebook.png" />

                            <Trans>{labelText} with Facebook</Trans>
                            <div style={{ width: "1rem", height: '0.5rem' }} />
                        </Button> : <section />
                    }
                    {
                        options.includes("google") ? <>
                            <Button key={"google"} className="login-button" onClick={async () => {
                                this.loginWithGoogle();
                            }}>
                                <img src="/icons/google.png" />
                                <Trans>{labelText} with Google</Trans>
                                <div style={{ width: "1rem", height: '0.5rem' }} />
                            </Button></> : <section />
                    }
                </div>
            </div>

        );
    }
}

export default authConnector(withRouter(LoginOptionsPage));