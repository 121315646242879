import { ProfileType } from "../controllers/ProfileController";

class AppRoutes {

    static get HOME() {
        return "/";
    }

    static get SUGGESTED() {
        return "/suggested";
    }

    static get LOGIN() {
        return "/login";
    }


    static get REGISTER_OPTION() {
        return "option=register";
    }

    static get SUTRA_TYPE() {
        return "type=sutra";
    }


    static get REGISTER() {
        return "/login?" + this.REGISTER_OPTION;
    }

    static REGISTER_PROFILE(type) {
        return this.REGISTER + "&type=" + type;
    }



    static get PROFILE() {
        return "/profile";
    }

    static get PROFILE_SETTINGS() {
        return "/profile/settings";
    }

    static get LOGIN_MOBILE() {
        return "/login/mobile";
    }

    static get LOGIN_EMAIL() {
        return "/login/email";
    }

    static get SIGNUP_EMAIL() {
        return "/login/signup/email";
    }

    static get FORGOT_EMAIL() {
        return "/login/forgot";
    }

    static get PROFILE_COMPLETE() {
        return "/login/profile/complete";
    }


    static get UNKNOWN() {
        return "/:unknown";
    }

    static get UPLOAD_PAGE() {
        return "/upload";
    }

    static STORY(id) {
        return "/story/" + id;
    }

    static PRODUCT(id) {
        return "/product/" + id;
    }


    static USER_PROFILE(type, id) {
        if (type == ProfileType.STORE) {
            return this.APP(id);
        }
        if (type == ProfileType.SUTRA) {
            return this.BRAND(id);
        }
        return `/${type}/` + id;
    }

    static CUSTOMER(id) {
        return "/customer/" + id;
    }

    static APP(id) {
        return "/app/" + id;
    }

    static SUTRA(id) {
        return "/brand/" + id;
    }
    static BRAND(id) {
        return "/brand/" + id;
    }

    static DISCOVER_ITEM(id, type?) {
        return "/discover/" + id + (type ? ("?type=" + type) : "");
    }

    static get DISCOVER() {
        return "/discover";
    }

    static HASHTAG(id) {
        return "/hashtag/" + id;
    }

    static ADD_PROFILE(type) {
        return "/login/add/" + type;
    }

    static CHAT(username) {
        return "/chat/" + username;
    }

}

export default AppRoutes;