import { Button } from 'antd';
import React, { Component } from 'react';
import Message from '../../../models/chat/Message';

type props = {
    message: any,
    onCancel: () => void
}

class ReplyChatComponent extends Component<props, any> {

    render() {
        const { message, onCancel } = this.props;
        const { sender = {} } = message;
        return (
            <div className="reply-container">
                <div className="reply-body">
                    <span className="sender-name">{sender.name}</span>
                    <span className="message">{Message.getText(message)}</span>
                </div>
                <Button onClick={onCancel} icon="close" className="close-action" />
            </div>
        );
    }
}

export default ReplyChatComponent;