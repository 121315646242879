import { EnvironmentOutlined } from '@ant-design/icons';
import { Button, Col, Divider, Empty, Layout, Row, Spin, Tag } from 'antd';
import React, { Component } from 'react';
import { BrowserView, MobileView } from 'react-device-detect';
import { Trans } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import ProductController from '../../controllers/ProductController';
import ProfileController, { ProfileType } from '../../controllers/ProfileController';
import StoryController from '../../controllers/StoryController';
import AppRoutes from '../../helpers/AppRoutes';
import FollowHelper from '../../helpers/FollowHelper';
import ProfileHelper from '../../helpers/ProfileHelper';
import { authConnector } from '../../stores/auth/connector';
import AppHeader from '../appHeader/AppHeader';
import ScaffoldPage from '../scaffoldPage/ScaffoldPage';
import StoryGridPage from '../storyPage/StoryGridPage';
import SuggestionBox from '../suggestionsBox/SuggestionsBox';
import TabBar from '../tabs/TabBar';
import './style.css';

class UserProfilePage extends Component<any, any> {

    state = {
        isProfileLoading: true,
        isStoriesLoading: true,
        isProductsLoading: true,
        selectedTab: "Stories",
        profile: null,
        stories: null,
        products: null,
        showFollowing: false,
        showFollowingDialog: false
    }


    async componentDidMount() {
        let location = this.props.location;
        let username: string;
        let urls = [AppRoutes.CUSTOMER(""), AppRoutes.APP(""), AppRoutes.SUTRA(""), AppRoutes.USER_PROFILE("user-profile", "")];
        urls.forEach((path) => {
            if (location.pathname.startsWith(path)) {
                username = location.pathname.replace(path, "");
            }
        });
        if (username) {
            const profile = await this.loadProfileDetails(username);

            const { id } = profile;
            this.loadStories(id);
            if (profile.type == ProfileType.SUTRA) {
                this.loadProducts(id);
            }
        } else {

            this.setState({ error: "Does not exists", isStoriesLoading: false });
        }
    }

    loadProfileDetails = async (username) => {
        let profile = await ProfileController.getProfileByUsername(username);
        this.setState({
            profile: profile,
            isProfileLoading: false
        })
        return profile || {
            id: ''
        };
    }

    loadStories = async (profileId) => {
        let storyResponse = await StoryController.getStoriesByProfile(profileId);
        this.setState({
            stories: storyResponse.data,
            isStoriesLoading: false
        })

    }


    loadProducts = async (profileId) => {
        let productResponse = await ProductController.getProductsByProfile(profileId);
        this.setState({
            products: productResponse.data,
            isProductsLoading: false
        })

    }

    follow = async () => {
        const { user, updateUser } = this.props;
        const userProfile = { ...user } || {};
        const { profile } = this.state;
        if (!user.id) {
            ProfileHelper.showLogin();
            return;
        }
        const followerResponse = await FollowHelper.follow(userProfile.id, profile.id, updateUser);
        this.setState({
            profile: { ...profile, ...followerResponse },
        });
    }

    render() {
        return (
            <div>
                <BrowserView>
                    {this.buildHomeView()}
                </BrowserView>
                <MobileView>
                    {this.buildMobileView()}
                </MobileView>
            </div>
        );
    }

    buildMobileView = () => {
        const { isStoriesLoading, stories = [], profile } = this.state;
        const { user } = this.props;
        return (
            <ScaffoldPage
                appBar={<AppHeader title="Profile" />}
                body={<>
                    {profile ? this.buildProfile(profile, stories ? stories.length : 0, user) : <div />}
                    <Divider style={{ margin: "0rem 4rem" }} />

                    <div style={{ padding: '0rem' }}>
                        {profile ? this.buildBody() : <section />}
                    </div>
                    <Divider style={{ margin: "0rem 4rem" }} />
                    <div style={{ margin: '2rem 1rem' }}>
                        <SuggestionBox type={profile ? profile.type : undefined} />
                    </div>
                </>}
            />

        );
    }

    buildHomeView = () => {
        const { isStoriesLoading, stories = [], profile } = this.state;
        const { user } = this.props;
        return (
            <Layout style={{ background: "white" }}>
                <AppHeader title="Profile" />
                <Row>
                    <Col span={17}>
                        {profile ? this.buildProfile(profile, stories ? stories.length : 0, user) : <div />}
                        <Divider style={{ margin: "0rem 4rem" }} />
                        {profile ? this.buildBody() : <section />}
                    </Col>
                    <Col span={7}>
                        <div style={{ margin: '2rem 1rem' }}>
                            <SuggestionBox type={profile ? profile.type : undefined} />
                        </div>
                    </Col>
                </Row>
            </Layout>
        );
    }

    buildBody = () => {
        let { profile, selectedTab } = this.state;
        let { type = ProfileType.CUSTOMER } = profile;
        if (type == ProfileType.SUTRA) {
            return (
                <div style={{ padding: '2rem 0rem' }}>
                    <TabBar tabs={["Stories", "Products"]} onTabChanged={(selectedTab) => { this.setState({ selectedTab }) }} current={selectedTab} />
                    {selectedTab == "Stories" ? this.buildStories() : this.buildProducts()}
                </div>
            );
        }
        return this.buildStories();
    }

    buildStories = () => {
        let { isStoriesLoading, stories = [], } = this.state;

        return (isStoriesLoading ?
            <div style={{ padding: '2rem 3rem' }}>
                <Spin tip="Loading Stories" />
            </div> :
            <div style={{ padding: '2rem' }}>
                {stories.length > 0 ? <StoryGridPage stories={stories} /> : <Empty />}
            </div>
        );
    }

    buildProducts = () => {
        let { isProductsLoading, products = [], } = this.state;

        return (isProductsLoading ?
            <div style={{ padding: '2rem 3rem' }}>
                <Spin tip="Loading Products" />
            </div> : <div style={{ padding: '2rem' }}>
                {products.length > 0 ? <StoryGridPage onClick={(val) => {
                    this.props.history.push(AppRoutes.PRODUCT(val.id))
                }} stories={products} /> : <Empty />}
            </div>
        );
    }


    goToCare = (profile) => {
        window.location.href = AppRoutes.CHAT(profile.username)
    }

    buildProfile = (profile, storiesCount, user) => {

        const userProfile = { ...user };
        const { following = [] } = userProfile;
        const isFollowing = following.some(profileId => profileId === profile.id);
        const followers = (profile.followers || []).length;
        const profileFollowing = (profile.following || []).length;
        return <div className="profile-cover">
            <img className="profile-pic" src={profile.profilePic} />
            <div style={{ width: "1rem" }} />
            <div className="profile-content">
                <span className="title">{profile.username}</span>
                <div className="profile-name-container">
                    <Tag color={"orange"}>{ProfileType.getType(profile.type)}</Tag>
                </div>
                <MobileView>
                    {profile.type == ProfileType.STORE ? <Button type="link">Download App</Button> : <div />}
                </MobileView>
                {profile.location && profile.location.areaDetails ? <div style={{ marginTop: '1rem' }}>
                    <EnvironmentOutlined translate={0} /> <span style={{ marginTop: "1rem" }}>{profile.location.areaDetails}</span>
                </div> : <span />}
                {profile.bio ? <div style={{ marginTop: '1rem' }}>
                    {profile.bio}
                </div> : <div />}
                <div className="follow-container" >
                    <div className="follow-data" onClick={() => {
                        this.setState({ showFollowing: true, showFollowingDialog: true });
                    }}>
                        <span className="key"><Trans>Following</Trans></span>
                        <span className="value">{profileFollowing}</span>
                    </div>
                    <div className="follow-data" onClick={() => {
                        this.setState({ showFollowing: false, showFollowingDialog: true });
                    }}>
                        <span className="key"><Trans>Followers</Trans></span>
                        <span className="va;ue">{followers}</span>
                    </div>
                    <div className="follow-data">
                        <span className="key"><Trans>Stories</Trans></span>
                        <span className="value">{storiesCount}</span>
                    </div>
                </div>
                <MobileView>
                    <div className="follow-btn-container">
                        <div style={{ height: "1rem" }} />
                        {profile.id == user.id ? <div /> : <Button type={isFollowing ? "dashed" : "primary"} block className="follow-btn" onClick={this.follow}><Trans>{!isFollowing ? "Follow" : "Unfollow"}</Trans></Button>}
                        <div style={{ height: "0.4rem" }} />
                        {this.buildChat(profile)}
                    </div>

                </MobileView>
            </div>
            <BrowserView>
                <div className="follow-btn-container">
                    {profile.id == user.id ? <div /> : <Button type={isFollowing ? "dashed" : "primary"} block className="follow-btn" onClick={this.follow}><Trans>{!isFollowing ? "Follow" : "Unfollow"}</Trans></Button>}
                    <div style={{ height: "0.4rem" }} />
                    {profile.type == ProfileType.STORE ? <Button type="link">Download App</Button> : <div />}
                    {this.buildChat(profile)}
                </div>
            </BrowserView>
        </div>
    }

    buildChat = (profile) => {
        return (<Button onClick={() => this.props.history.push(AppRoutes.CHAT(profile.username))} style={{ marginTop: "0.5rem" }} block type="danger"><Trans>Start Chat</Trans></Button>);
    }

}

export default authConnector(withRouter(UserProfilePage));