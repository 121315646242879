import { Empty, Input, Layout, Select, Spin } from 'antd';
import queryString from 'query-string';
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Header } from 'semantic-ui-react';
import HashtagController from '../../controllers/HashtagController';
import StoryController from '../../controllers/StoryController';
import AppRoutes from '../../helpers/AppRoutes';
import AppHeader from '../appHeader/AppHeader';
import SourceStringComponent from '../story/StoryComponent';
import './style.css';

const { Search } = Input;


type props = {
  stories: []
}

const params = {
  pagination: {
    el: '.swiper-pagination',
    clickable: true,
    dynamicBullets: true
  },
  spaceBetween: 30,
  slidesPerView: 'auto' as 'auto',
}

class HashtagPage extends Component<any & props, any> {
  state = {
    stories: [],
    isLoading: true,
    hashtags: [],
    hashtagId: '',
    query: '',
    // profileType: ProfileType.SUTRA,
  }

  componentDidMount() {
    let location = this.props.location;
    if (location.pathname.startsWith(AppRoutes.HASHTAG(""))) {
      let params = queryString.parse(location.search);
      let hashtagId: string = location.pathname.replace(AppRoutes.HASHTAG(""), "");
      this.loadHashtags(hashtagId)
    }
    else {
      this.loadHashtags(undefined)
    }
  }


  loadHashtags = async (hashtag) => {
    let storiesResponse;
    let hashtags = await HashtagController.getHomeHashtags();
    if (hashtag) {
      storiesResponse = await StoryController.getStoriesByHashtag([hashtag]);
      hashtags = hashtags.filter((e) => e.id == hashtag);
    }
    else {
      storiesResponse = await StoryController.getStoriesByHashtag(hashtags.map((e) => e.id));
    }
    this.setState({
      hashtags,
      stories: storiesResponse.data || [],
      isLoading: false
    })
  }

  buildList = (hashtag, stories) => {
    return <div >
      <Header as="h2" style={{ margin: '2rem 0 0 2rem' }}># {hashtag.name}</Header>
      <div style={{ margin: '0 2rem' }}>
        {stories.length ?
          <div className="hashtag-story-container">
            {stories.map((val) =>
              <div className="hashtag-story-slide">
                <SourceStringComponent customClass="hashtag-story" key={val.id} onProfileClick={(val) => {
                  this.props.history.push(AppRoutes.USER_PROFILE(val.type, val.id))
                }}
                  onClick={(val) => {
                    this.props.history.push(AppRoutes.STORY(val.id))
                  }}
                  sourceString={val}
                />
              </div>
            )}
          </div> : <Empty />
        }
      </div>
    </div>
  }

  render() {
    const { stories = [], isLoading, hashtags = [], hashtagId, query = '', } = this.state;
    let hashtagResult = [];
    if (stories && hashtags) {
      hashtagResult = stories.map((value) => {
        let id = Object.keys(value)[0];
        let result = hashtags.filter((e) => e.id == id && `${e.name}`.toLowerCase().includes(query.toLowerCase()));
        if (result.length > 0) {
          let hashtag = result[0];
          let subStories = value[hashtag.id];
          if (subStories && subStories.length > 0) {
            return {
              hashtag: hashtag,
              stories: subStories
            };
          }
        }
      });
    }
    hashtagResult = hashtagResult.filter((e) => e);
    const { Option } = Select;
    const hashtag = (hashtags.find(tag => tag.value === hashtagId) || {}).label || '';
    return (
      <Layout style={{ background: "white" }} className='hashtag-page'>
        <AppHeader title={"Hashtags"} />
        {
          isLoading ? <Spin tip="Loading" /> : <div className="hashtag-page-body">
            <Search
              style={{ margin: '2rem 2rem', width: '80%' }}
              placeholder="Search for hashtags here"
              enterButton="Search"
              size="large"
              onSearch={query => {
                console.log("Query", query);
                this.setState({ query })
              }}
            />
            {
              hashtagResult.map((result) => {
                return this.buildList(result.hashtag, result.stories);
              })
            }
          </div>
        }
      </Layout>
    );
  }
}

export default withRouter(HashtagPage);