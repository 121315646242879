import { Button, Layout, PageHeader, Spin } from 'antd';
import React, { Component } from 'react';
import VideoController from '../../controllers/VideoController';
import './style.css';

type VideoProps = {
    onSubmitted: (video) => void
}

class VideoGallery extends Component<VideoProps, any> {

    state = {
        videos: [],
        isLoading: true
    }

    componentDidMount() {
        this.loadVideos();
    }

    loadVideos = async () => {
        let videos = await VideoController.getList();
        this.setState({ videos, isLoading: false })
    }

    render() {
        let { videos = [], isLoading } = this.state;
        return (
            <Layout className="gallery-scaffold">
                <PageHeader title="Gallery" style={{ background: "white" }} />
                {isLoading ? <Spin tip="Loading" /> : <div className="gallery-list">
                    {videos.map((e) => this.buildVideo(e))}
                </div>}
            </Layout>
        );
    }

    buildVideo = (video) => {
        const { videoUrl = '', videoThumbnail = '', id = '' } = video;
        return <div className="gallery-item">
            <img onClick={() => {
                this.props.onSubmitted(video);
            }} key={id} src={videoThumbnail} />
            <Button block={false} type="primary" size="small" onClick={() => {
                window.open(videoUrl, '_blank')
            }}>Play</Button>
        </div>
    }
}

export default VideoGallery;