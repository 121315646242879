
class AppConstants {

    static getURL(url) {
        return "https://cors-anywhere.herokuapp.com/" + url;
    }

    static get BUY() {
        return "Buy";
    }

    static get CHAT() {
        return "Chat"
    }

    static get BUY_NOW() {
        return "Buy Now"
    }

    static get GET_QUOTE() {
        return "Get Quote"
    }

    static CHANNEL_READ_ONLY() {
        return "Channel Read Only";
    }

    static get DESCRIPTION() {
        return "Description";
    }

    static get TYPE_A_MESSAGE() {
        return "Type a message";
    }

    static get DASHBOARD_PATH() {
        return '/dashboard';
    }

    static get FORGOT_PASSWORD() {
        return "Forgot Password";
    }

    static get USERS_PATH() {
        return this.DASHBOARD_PATH + '/users';
    }

    static get CONTACTS_PATH() {
        return this.DASHBOARD_PATH + '/contacts';
    }
    static get SUTRAS_PATH() {
        return this.DASHBOARD_PATH + '/sutras';
    }
    static get CHANNELS_PATH() {
        return this.DASHBOARD_PATH + '/channels';
    }
    static get USER_INBOX_PATH() {
        return this.DASHBOARD_PATH + '/user-inbox';
    }
    static get QUERY_PATH() {
        return "/ask";
    }

    static askPathFromSourceString(source: string, sourceStringId: string, sutraId): string {
        return `${AppConstants.DASHBOARD_PATH}?source=${source}&${AppConstants.SOURCE_STRING_ID}=${sourceStringId}&${AppConstants.SUTRA_ID}=${sutraId}`;
    }

    static askPathFromIntent(source: string, intentId: string): string {
        return `${AppConstants.DASHBOARD_PATH}?source=${source}&${AppConstants.INTENT_ID}=${intentId}`;
    }

    static get CONTINUE() {
        return "Continue";
    }

    static get USERNAME() {
        return "Username";
    }

    static get NAME() {
        return "Name";
    }

    static get PHONE_NUMBER() {
        return "Phone Number";
    }

    static get PASSWORD() {
        return "Password";
    }

    static get CONFIRM_PASSWORD() {
        return "Confirm Password";
    }

    static get CHOOSE_QUESTION() {
        return "Choose questions";
    }

    static get CHOOSE_QUESTION_DESCRIPTION() {
        return "Choose questions which you can answer";
    }

    static get GENERIC_DESCRIPTION() {
        return "User can also ask you question not related to selected topics";
    }
    static get SUBMIT() {
        return "Submit";
    }
    static get LOGIN() {
        return "Login";
    }
    static get REGISTER() {
        return "Register";
    }


    static get REGISTER_USER() {
        return "Register As User";
    }

    static get REGISTER_SUTRA() {
        return "Register As Sutra";
    }

    static get DONT_HAVE_ACCOUNT() {
        return "Don't have an account sign up now";
    }

    static get ALREADY_HAVE_ACCOUNT() {
        return "Already have an account login now";
    }

    static get ASK_QUESTION() {
        return "Ask Question";
    }

    static get SUTRAS() {
        return "Sutraz";
    }

    static get USERS() {
        return "Users";
    }

    static get CONTACTS() {
        return "Contacts";
    }

    static get CHANNELS() {
        return "Channels";
    }

    static get USER_INBOX() {
        return "User Inbox";
    }

    static get ACCOUNT() {
        return "Account";
    }

    static get INVITES() {
        return "Invites";
    }
    static get Logout() {
        return "Logout";
    }

    static get DEFAULT_LANGUAGE_CODE() {
        return "HI";
    }

    static get DEFAULT_CATEGORY_IMAGE() {
        return "https://sutras.store/images/placeholder.jpg";
    }

    static get INTENT_ID() {
        return "intentId";
    }

    static get INTENT() {
        return "intent";
    }

    static get SPEC() {
        return "spec";
    }

    static get SOURCE_STRING() {
        return "sourceString";
    }

    static get SOURCE_STRING_ID() {
        return "sourceStringId";
    }

    static get SUTRA_ID() {
        return "sutraId";
    }

    static get VIDEO_VIEW() {
        return "VideoView";
    }

    static get ADVANCED_SEARCH() {
        return "Advanced Search";
    }
    static get SEND_INVITE() {
        return "Send Invite";
    }
    static get MESSAGE() {
        return "Message";
    }
    static get INVITE() {
        return "Invite";
    };
    static get CLOSE() {
        return "Close";
    }
    static get OK() {
        return "Ok";
    }
    static get USER_VIEW() {
        return "User View";
    }
    static get IMAGE() {
        return "Image"
    };
    static get VIDEO() {
        return "Video";
    }
    static get CREATE() {
        return "Create";
    }

    static get CREATE_CHANNEL() {
        return "Create Channel";
    }

    static get CONVERSATIONS() {
        return "Conversations";
    }

    static get Content() {
        return "Content";
    }
    static get COMMUNITY() {
        return "Community";
    }

    static get SETTINGS() {
        return "Settings";
    }

    static get CHANNEL_INFO() {
        return "Channel Info";
    }

    static get JOIN_CHANNEL() {
        return "Join Channel";
    }

    static get DASHBOARD() {
        return "Dashboard";
    }
    static get RESEARCH() {
        return "Research"
    }

    static get SHOPPING() {
        return "Shopping";
    }

    static get COMPARE() {
        return "Compare";
    }

    static get START_CHAT() {
        return "Start Chat";
    }
    static get SHOPPING_OVER_CHAT() {
        return "The Alternative App Store";
    }
    static get EMPTY_ERROR_MESSAGE() {
        return "Please enter a valid query";
    }

    static get SUTRA_PAGE_PATH() {
        return "/sutra";
    }

    static get SUGGESTED() {
        return "/sugessted";
    }

    static getUserProfile(username) {
        return "/user-profile/" + username;
    }


    static getSutraPagePath(sutraId: string) {
        return this.SUTRA_PAGE_PATH + "?id=" + sutraId;
    }

    static get Products() {
        return "Products";
    }
}


export default AppConstants;