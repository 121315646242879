import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { authConnector } from '../../stores/auth/connector';
import User from '../../models/User';
import { Modal, Spin, List, Tag, Avatar, Button, Divider } from 'antd';
import { throws } from 'assert';
import ProfileController, { ProfileType } from '../../controllers/ProfileController';
import AppRoutes from '../../helpers/AppRoutes';

class ProfileDialog extends Component<any,any> {

    state={
        isLoading:false,
        profiles:[]
    }

    componentDidMount(){
        this.loadProfiles();
    }

    loadProfiles=async()=>{
        let {user={}}=this.props
        this.setState({isLoading:true})
        let result=await ProfileController.getAllProfile(user.userId);
        this.setState({isLoading:false,profiles:result||[]})
    }

    render() {
        const {isLoading=false,profiles=[]}=this.state;
        let {user={}}=this.props
        console.log(profiles)
        let profileTypes=[]
        profileTypes.push(ProfileType.CUSTOMER);
        profileTypes.push(ProfileType.SUTRA)
        profileTypes.push(ProfileType.STORE)
        return (
           Object.keys(user).length>0? <Modal title={"Switch Profile"} destroyOnClose={true} visible={this.props.visible} onCancel={this.props.onClose} footer={[]}>
                <div >
                {isLoading?<Spin tip="Loading Profiles..." />:<div>
                <List
                 dataSource={profiles}
                 renderItem={(profile)=>this.buildProfile(profile)}
                />
                {
                   profileTypes.filter((type)=>profiles.filter((profile)=>profile.type==type).length==0).map((type)=><div>
                    <Button onClick={()=>{
                    this.props.history.push(AppRoutes.ADD_PROFILE(type))
                    }} icon="plus" type="link">Add {type==ProfileType.STORE?"app":type}</Button>
                <div style={{width:'1rem',display:"inline-block"}}/>
              
                    </div>)
                }
                    </div>}
                </div>
            </Modal>:<div/>
        );
    }

    buildProfile=(profile)=>{
        const {user}=this.props;
        let isCurrent=user.id==profile.id;
        return <List.Item style={{cursor:isCurrent?"cursor":"pointer"}}  onClick={isCurrent?undefined:()=>{
            this.props.login(profile);
            this.props.onClose()
            window.location.reload();
        }}>
            <List.Item.Meta title={profile.username} description={profile.type==ProfileType.STORE?"app":profile.type} avatar={<Avatar src={profile.profilePic} />} />
            {isCurrent?<Tag color={"orange"}>Current</Tag>:<span/>}
        </List.Item>
    }
}

export default authConnector(withRouter(ProfileDialog));