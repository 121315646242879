import firebase from "firebase";
import AppResponse from "../models/AppResponse";

export default class LangController {
  static LANGUAGES = "languages";

  static async getLanguage(id: string): Promise<{}> {
    try {
      var snapshot = await firebase.firestore().collection(LangController.LANGUAGES).doc(id).get();
      var data = snapshot.data();
      if (data)
          return data;
      return "Does not exists";
    } catch (error) {
      console.log("Error", error)
      return null;
    }
  }

  static async getLanguages(): Promise<Array<any>> {
    try {
      var snapshot = await firebase.firestore().collection(LangController.LANGUAGES).get();
      var result = snapshot.docs.map((val) => val.data());
      return result;
    } catch (error) {
      console.log("Error", error);
      return null;
    }
  }
}
