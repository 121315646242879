import React, { Component } from 'react';
import Search from 'antd/lib/input/Search';
import i18next from 'i18next';


const SearchBox = (props) => {
    const { onSearch } = props;
    return (
        <Search
            className="search-input"
            enterButton
            onChange={(e) => { onSearch(e.target.value) }}
            onSearch={onSearch}
            size="large"
            placeholder={i18next.t("Search Here")}
        />
    );
}

export default SearchBox;