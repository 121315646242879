import React, { Component } from 'react';
import i18next from 'i18next';

const AppInput = (props) => {
    const { placeholder, onChange, className, value, onSubmit } = props
    return <input className={className} onChange={onChange} onKeyDown={(e) => {
        if (e.key == 'Enter') {
            onSubmit(e.currentTarget.value)
        }
    }} placeholder={i18next.t(placeholder)} value={value} />
}

export default AppInput;