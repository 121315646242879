import React, { Component } from 'react';
import { Form, Input, Select, Button, message, Icon, List, Switch, Checkbox, Modal, Spin } from 'antd';
import { RouteComponentProps, withRouter, StaticContext } from 'react-router';
import AppRoutes from '../../helpers/AppRoutes';
import LocationInput from '../location/LocationInput';
import { connectedRouteProps } from '../../props';
import { authConnector } from '../../stores/auth/connector';
import ProfileController, { ProfileType } from '../../controllers/ProfileController';
import ProfessionController from '../../controllers/ProfessionController';
import WrappedAddProfileForm from '../profileForm/ProfileForm';
import SutrazController from '../../controllers/SutrazController';
import ProfileHelper from '../../helpers/ProfileHelper';

const { Option, OptGroup } = Select;
const InputGroup = Input.Group;


class ProfileCompletePage extends Component<connectedRouteProps, any> {

    state = {
        isLoading: false,
        username: null,
        name: null,
        location: null,
        type:undefined,
        profession:undefined,
        isLoadingData:true,
        professions:[]  
      }



    componentDidMount() {
        let { user } = this.props;
        if(user){
          this.setState({type:user.type})  
        }
        this.loadData();

    }

  
    loadData = async () => {
        const response = await ProfessionController.getProfessions();
        if(response.data){
            var professions=response.data||[];
            this.setState({professions})
        }
      this.setState({isLoadingData:false});
    }
  

    updateUser = async (newUser) => {
        this.setState({isLoading:true});
        const user = this.props.user||{};
        let response=await ProfileHelper.updateProfile(user,{...newUser,});
        this.setState({isLoading:false});
        if(response){
            this.props.updateUser(response);
            this.props.history.push(AppRoutes.HOME);        }
    }

    render() {
        const { isLoading,type ,professions,isLoadingData } = this.state;

        const isSutra=type==ProfileType.SUTRA;
        const { user } = this.props;
        return (
            <div className="login-section">
                <h3 className="login-title">Complete Profile</h3>
                <h4 className="login-description">
                    Fill your all details<br /> and start exploring.
                </h4>
                <div style={{ height: '1rem ' }} />
                {
                    isLoadingData?<Spin tip="Loading Data" />:
                   <WrappedAddProfileForm user={user} onSubmit={(user)=>{
                    this.updateUser(user);
                }} isLoading={isLoading} />
                }
              </div>
        );
    }
}

export default authConnector(withRouter(ProfileCompletePage));