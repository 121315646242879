import AppResponse from "../models/AppResponse";
import { firestore } from "firebase";
import crypto from 'crypto';
import LanguageController from "./LanguageController";



class ProfessionController {

    static PROFESSIONS = "professions";

    static async addProfession(profession): Promise<AppResponse<boolean>> {
        try {
            let temp = { ...profession };
            temp.name = profession.name.trim();
            var hash = crypto.createHash('md5').update(profession.name).digest('hex');
            temp.id = hash
            await firestore().collection(this.PROFESSIONS).doc(temp.id).set(temp);
            return new AppResponse(true, undefined);
        } catch (error) {
            console.log("Error", error);
            return new AppResponse(undefined, error);
        }
    }


    static async deleteProfession(id, undo?: boolean): Promise<AppResponse<boolean>> {
        try {
            if (undo == undefined) {
                undo = false;
            }
            await firestore().collection(this.PROFESSIONS).doc(id).update({ "isDeleted": !undo });
            return new AppResponse(true, null);
        } catch (error) {
            console.log("Error", error);
            return new AppResponse(null, error);
        }
    }

    static onProfessionsLoaded(onLoaded: (val) => void) {
        try {
            firestore().collection(this.PROFESSIONS).onSnapshot(async (snapshot) => {
                var result = snapshot.docs.map((val) => val.data());
                let newResult = await LanguageController.parseData(result);
                onLoaded(newResult);
            })

        } catch (error) {
            console.log("Error", error);

        }
    }

    static async getDetails(id): Promise<{}> {
        try {
            var snapshot = await firestore().collection(this.PROFESSIONS).doc(id).get();
            return snapshot.data();
        } catch (error) {
            return undefined;
        }
    }

    static async updateProfession(profession): Promise<AppResponse<boolean>> {
        try {

            await firestore().collection(this.PROFESSIONS).doc(profession.id).update(profession);
            return new AppResponse(true, null);
        } catch (error) {
            console.log("Error", error);
            return new AppResponse(null, error);
        }
    }

    static async getProfessions(): Promise<AppResponse<Array<any>>> {
        try {
            var snapshot = await firestore().collection(this.PROFESSIONS).get();
            var result = snapshot.docs.map((val) => val.data());
            return new AppResponse(result, null);

        } catch (error) {
            console.log("Error", error);
            return new AppResponse(null, error.toString());

        }
    }

    static async parseData(data): Promise<any> {
        let newData = [...data]
        let professionData = {};
        await Promise.all(Object.keys(newData).map(async (key) => {
            let val = newData[key];
            if (val && val.profession) {
                if (professionData[val.profession]) {
                    val.professionName = professionData[val].name;
                }
                else {
                    let result = await this.getDetails(val.profession);
                    if (result) {
                        val.professionName = result["name"];
                        professionData[val.profession] = result
                    }
                }
            }
        }));
        return newData;
    }


}

export default ProfessionController;