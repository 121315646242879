import firebase from "firebase";
import AppCollections from "../helpers/AppCollections";
import AppResponse from "../models/AppResponse";
import { v4 as uuidV4 } from 'uuid';
import LanguageController from "./LanguageController";
import ProfileController, { ProfileType } from "./ProfileController";
import CategoryController from "./CategoryController";
import JsonHelper from "../helpers/JsonHelper";

export default class SutrazController {

    static SUTRAS = "sutras";

    static async getDetails(id: string,): Promise<AppResponse<{}>> {
        try {
            var snapshot = await firebase.firestore().collection(SutrazController.SUTRAS).doc(id).get();
            var data = snapshot.data();
            if (data){
            let result=await ProfileController.getDetails(data.profileId);
                if(result){
                    return new AppResponse({...result,...data,},null)
                }
            }
            return new AppResponse(null, "Does not exists");

        } catch (error) {
            console.log("Error", error)
            return new AppResponse(null, error.toString);

        }
    }

    static async getParticularDetails(profileId: string,): Promise<AppResponse<{}>> {
        try {
            console.log("Get Sutra");
            var snapshot = await firebase.firestore().collection(SutrazController.SUTRAS).where("profileId","==",profileId).get();
            console.log("Docs",snapshot.docs.map((e)=>e.data()));
            if(snapshot.docs.length>=1){
                var doc = snapshot.docs[0];
                if (doc.exists){
                    let data=doc.data();
                    let sutraId=data["id"];
                    data["id"]=undefined;
                    data["sutraId"]=sutraId;
                    console.log("Sutra Data");
                  return new AppResponse({...data,sutraId},null);
                }
    
            }
            return new AppResponse(null, "Does not exists");

        } catch (error) {
            console.log("Error", error)
            return new AppResponse(null, error.toString);

        }
    }

    static async getDetailsByProfile(profileId: string,): Promise<AppResponse<{}>> {
        try {
            var snapshot = await firebase.firestore().collection(SutrazController.SUTRAS).where("profileId","==",profileId).get();
            var docs = snapshot.docs;
            if (docs.length&&docs.length>0){
              return new AppResponse({...docs[0].data()},null);
            }
            return new AppResponse(null, "Does not exists");

        } catch (error) {
            console.log("Error", error)
            return new AppResponse(null, error.toString);

        }
    }


    static async addSutra(sutra): Promise<AppResponse<string>> {
        try {
            let { username, profilePic, userId, language,profession,name } = sutra;
            let profile = { username, profilePic, userId, type: ProfileType.SUTRA, language,name };
            let profileResponse = await ProfileController.addProfile(profile);
            if (profileResponse.error) {
                return new AppResponse(null, profileResponse.error);
            }
            let profileId = profileResponse.data;
            let newSutra = { id: uuidV4(), profileId,profession };
            try {
                var snapshot =await  firebase.firestore().collection(this.SUTRAS).doc(newSutra.id).set(JsonHelper.cleanJson(newSutra));
            } catch (error) {
                await ProfileController.deleteProfileForever(profileId);
                return new AppResponse(null, error);

            }
            return new AppResponse(newSutra.id, null);
        } catch (error) {

            console.log("Error", error)
            return new AppResponse(null, error["message"] || "Some error occurred");
        }
    }

    static async updateSutra(sutra): Promise<AppResponse<string>> {
        try {
            let { profileId,username, profilePic, userId, profession, language, id,name,} = sutra;
            let profile = { username, profilePic, userId, type: ProfileType.SUTRA, language,id,name,};
            let profileResponse = await ProfileController.update(profile);
            if (profileResponse.error) {
                return new AppResponse(null, profileResponse.error);
            }
            let newSutra = {id, profileId, profession,  };
            try {
                var snapshot = firebase.firestore().collection(this.SUTRAS).doc(newSutra.id).update(newSutra);
            } catch (error) {

            }
            return new AppResponse(id, null);
        } catch (error) {

            console.log("Error", error)
            return new AppResponse(null, error["message"] || "Some error occurred");
        }
    }

    static async updateSutraSpecific(sutra): Promise<AppResponse<string>> {
        try {
            let newSutra = {...sutra  };
            try {
                var snapshot = firebase.firestore().collection(this.SUTRAS).doc(newSutra.id).update(newSutra);
            } catch (error) {

            }
            return new AppResponse(sutra.id, null);
        } catch (error) {

            console.log("Error", error)
            return new AppResponse(null, error["message"] || "Some error occurred");
        }
    }


    static async deleteSutra(id, undo?: boolean): Promise<AppResponse<boolean>> {
        try {
            if (undo == undefined) {
                undo = false;
            }
            await this.updateSutra({ "isDeleted": !undo, id: id })
            return new AppResponse(true, null);
        } catch (error) {
            console.log("Error", error);
            return new AppResponse(null, error);
        }
    }


    static onSutrasDetailLoaded(onLoaded: (val) => void) {
        try {
            firebase.firestore().collection(this.SUTRAS).onSnapshot(async (snapshot) => {
                var result = snapshot.docs.map((val) => val.data());
                var profileResult = await ProfileController.parseData(result);
                onLoaded(profileResult)
            })

        } catch (error) {
            console.log("Error", error);

        }
    }


    static async getSutras(): Promise<AppResponse<Array<any>>> {
        try {
            var snapshot = await firebase.firestore().collection(this.SUTRAS).get();
            var result = snapshot.docs.map((val) => val.data());
            var newResult = await LanguageController.parseData(result);
            let profileResult = await ProfileController.parseData(newResult);
            return new AppResponse(profileResult, null);

        } catch (error) {
            console.log("Error", error);
            return new AppResponse(null, error.toString());

        }
    }

}