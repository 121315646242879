import { Button, Form, Icon, Input, message, Select } from 'antd';
import React, { Component } from 'react';
import { Trans } from 'react-i18next';
import { RouteComponentProps, StaticContext, withRouter } from 'react-router';
import LoginController from '../../controllers/LoginController';
import AppRoutes from '../../helpers/AppRoutes';
import AppResponse from '../../models/AppResponse';

const InputGroup = Input.Group;
const { Option } = Select;


class ForgotEmailPage extends Component<RouteComponentProps<any, StaticContext>, any> {

    state = {
        email: null,
        isLoading: false,
    }


    sendForgotLink = async () => {
        const { email, } = this.state;
        if (!email) {
            message.error("Email required");
            return;
        }

        this.setState({ isLoading: true });
        let response: AppResponse<boolean> = await LoginController.sendForgotPasswordLink(email);
        this.setState({ isLoading: false });
        if (response.error) {
            message.error(response.error);
        }
        else {
            message.success("Success");
        }
    }


    render() {
        const { isLoading, } = this.state;
        return (
            <div className="login-section">
                <h3 className="login-title"><Trans>Forgot Password</Trans></h3>
                <h4 className="login-description">
                    <Trans>Send reset password link to your email</Trans>.
                </h4>
                <div style={{ height: '1rem ' }} />
                <Form>
                    <Input
                        type="email"
                        className="login-input"
                        size="large"
                        prefix={<Icon type="mail" />}
                        onChange={(event) => {
                            this.setState({
                                email: event.target.value
                            })
                        }}
                        required
                        placeholder="Email"
                        style={{ marginBottom: "1.2rem" }}
                    />
                    <Button formAction="submit" formMethod="submit" onSubmit={(val) => {
                        console.log("Submit")
                    }} loading={isLoading} disabled={isLoading} onClick={this.sendForgotLink} className="login-action-button"><Trans>Send reset link</Trans></Button>
                    <div style={{ height: '0.4rem' }} />
                    <Button onClick={() => {
                        this.props.history.replace(AppRoutes.LOGIN_EMAIL);
                    }} type="link"><Trans>Login</Trans></Button>
                    <Button style={{ marginLeft: "auto" }} onClick={() => {
                        this.props.history.replace(AppRoutes.LOGIN);
                    }} type="link">Go Back</Button>
                    <div style={{ height: '0.5rem' }} />
                </Form>
            </div>
        );
    }
}

export default withRouter(ForgotEmailPage);