import { firestore } from 'firebase';
import uuid from 'uuid';
import JsonHelper from '../helpers/JsonHelper';
import AppResponse from '../models/AppResponse';
import Message from '../models/chat/Message';
import LoginController from './LoginController';
import ProfileController from './ProfileController';

export default class MessageController {

    static get messages() { return "messages" };

    static get messageCollection(): firestore.CollectionReference {
        return firestore().collection(this.messages);
    }

    static messageQuery(chatId): firestore.Query {
        return firestore().collection(this.messages).where("chatId", "==", chatId);
    }

    static async sendPersonalMessage(message: Message): Promise<AppResponse<string>> {
        let userId = await LoginController.getCurrentProfile();
        if (userId) {
            let newMessage = { ...message, sender: { id: userId } }
            return await this.sendMessage(newMessage);
        }
        else {
            return new AppResponse(null, "User not logged in");
        }

    }

    static async sendMessage(message: Message): Promise<AppResponse<string>> {
        try {
            if (!message.chatId || !message.content || !message.type) {
                return new AppResponse(null, "Send complete message");
            }
            message.id = uuid.v4();
            message.recordedAt = new Date();
            let { sender = {} } = message;
            if (!sender.id) {
                return new AppResponse(null, "Sender is not in");
            }
            let json = this.toJson(message);
            await this.messageCollection.doc(message.id).set(json);
            return new AppResponse(message.id);

        } catch (error) {
            return new AppResponse(null, `${error}`);
        }
    }

    static async getMessage(chatId: string, id: string, loadUser?: boolean): Promise<AppResponse<Message>> {
        try {
            var snapshot = await this.messageCollection.doc(id).get();
            if (snapshot.exists) {
                var data = snapshot.data();
                let message = await this.parseJson(data);
                return new AppResponse(message, null);
            }
            else {
                return new AppResponse(null, "Message does not exists");
            }
        } catch (error) {
            console.log("Error", error);
            return new AppResponse(null, `${error}`);
        }
    }

    static async parseJson(data, loadUser?) {
        var senderId = data["senderId"];
        if (senderId) {
            data["sender"] = await ProfileController.getDetails(senderId, loadUser);
        }
        var replyToId = data["replyToId"];
        if (replyToId) {
            var replyResponse = await this.getMessage(data.chatId, replyToId, loadUser);
            if (replyResponse.data)
                data["replyTo"] = replyResponse.data;
        }
        let userId = await LoginController.getCurrentProfile();
        if (data.senderId == userId) {
            data.isSelf = true;
        }
        let message = this.fromJson(data);
        return message;
    }

    static async getMessages(chatId: string, count?, page?): Promise<Array<Message>> {
        try {
            if (!count) {
                count = 40;
            }
            if (!page) {
                page = 1;
            }
            let snapshot = await this.messageQuery(chatId).orderBy("counter", "desc").limit(count).get();
            var docs = snapshot.docs.map((doc) => doc.data());
            let messages = await this.parseMessages(docs);
            return this.sortMessages(messages);
        } catch (error) {
            console.log("Error", error);
            return [];
        }
    }

    static sortMessages(messages: Array<Message>): Array<Message> {
        let newMessages = [...messages];
        newMessages = newMessages.sort((a, b) => {
            let arecordedAt = a.recordedAt || (new Date(1971))
            let brecordedAt = b.recordedAt || (new Date(1971))
            return arecordedAt.toLocaleString().localeCompare(brecordedAt.toLocaleString())
        })
        return newMessages;
    }

    static onMessage(chatId: string, onMessage: (val: Message) => void): any {
        return this.messageQuery(chatId).orderBy("counter", "desc").limit(1).onSnapshot({
            next: async (snapshot) => {
                if (snapshot.docs.length > 0) {
                    try {
                        let doc = snapshot.docs[0].data();
                        let message = await this.parseJson(doc);
                        console.log("Message ", message);
                        onMessage(message);
                    } catch (error) {
                        console.log("Error", error);
                    }
                }
            }
        });
    }

    static onMessageEveryone(onMessage: (val: Message) => void): any {
        return this.messageCollection.orderBy("counter").limitToLast(1).onSnapshot({
            next: async (snapshot) => {
                if (snapshot.docs.length > 0) {
                    try {
                        let doc = snapshot.docs[0].data();
                        let message = await this.parseJson(doc);
                        onMessage(message);
                    } catch (error) {
                        console.log("Error", error);
                    }
                }
            }
        });
    }

    static async parseMessages(docs): Promise<Array<any>> {
        let profiles = {};
        docs.forEach((e) => {
            profiles[e.senderId] = undefined;
        })
        await Promise.all(Object.keys(profiles).map(async (key) => {
            let profile = await ProfileController.getDetails(key);
            if (profile) {
                profiles[key] = profile;
            }
        }));
        var messages = [];
        messages = await Promise.all(docs.map(async (e) => {
            try {
                if (profiles[e.senderId]) {
                    var message = await this.parseJson(e);
                    message.sender = profiles[e.senderId];
                    return message;
                }
            } catch (error) {
                return null;
            }
        }))
        messages = messages.filter((e) => e);
        return messages;
    }

    static async searchMessages(chatId: string, query: string): Promise<Array<any>> {
        try {
            let snapshot = this.messageQuery(chatId).where("content.content", ">=", query).where("content.content", "<=", query + "\uf8ff").get();
            let docs = (await snapshot).docs.map((e) => e.data());
            return await this.parseMessages(docs);
        } catch (error) {
            console.log("Error", error);
            return [];
        }
    }

    static toJson(message: Message) {
        var json: any = { ...message };
        json["senderId"] = (message.sender || {}).id;
        json["sender"] = undefined;
        json["replyToId"] = (message.replyTo || {}).id;
        json["replyTo"] = undefined;
        json["isSelf"] = undefined;
        json["recordedAt"] = message.recordedAt.toISOString();
        json["counter"] = Date.parse((new Date()).toISOString());
        return JsonHelper.cleanJson(json);
    }

    static fromJson(json) {
        var message = { ...json };
        return message;
    }
}