import { Avatar, Button, Form, Input, message, Select } from 'antd';
import React, { Component } from 'react';
import ProfessionController from '../../controllers/ProfessionController';
import { ContentType } from '../../models/upload';
import UploadPanel from '../uploadPanel/UploadPanel';

const { Option } = Select;

type props = {
    user: { name, profession, username, location, }
    isLoading: boolean,
    onSubmit: (user: { name, profession, username, location, }) => void

}

class AddProfileForm extends Component<props | any, any> {

    state = {
        profession: undefined,
        isLoadingData: true,
        professions: [],
        profilePic: undefined,
        location: undefined,
    }

    componentDidMount() {
        let { user } = this.props;
        if (!user) {
            return;
        }
        this.setState({ profilePic: user.profilePic })

        this.loadData();
    }

    loadData = async () => {
        const response = await ProfessionController.getProfessions();
        if (response.data) {
            var professions = response.data || [];
            this.setState({ professions })
        }
        this.setState({ isLoadingData: false });
    }

    handleSubmit = (e) => {
        e.preventDefault();
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                let newUser = { ...values };
                let { profilePic, location } = this.state;
                if (!profilePic) {
                    message.warning("No profile pic choosen");
                }
                // if (!location) {
                //     message.warning("No location choosen");
                // }
                let result = { ...newUser, location };
                if (profilePic) {
                    result["profilePic"] = profilePic;
                }
                this.props.onSubmit(result);

            }
            else {
                message.error("Please enter all required fields")
            }
        })
    }

    render() {
        const { user = {}, isLoading, updateUser } = this.props;
        let { professions, profilePic } = this.state;
        console.log("User", user);
        const { getFieldDecorator } = this.props.form;
        return (
            <Form className="login-form" onSubmit={this.handleSubmit} >
                {
                    profilePic ? <section>
                        <Avatar src={profilePic} size={150} />
                        <br />
                        <Button size="large" icon="edit" onClick={() => {
                            this.setState({ profilePic: undefined })
                        }} type={"link"}>Edit</Button>
                    </section> : <div style={{ width: "20vw" }}>
                            <UploadPanel title={"Upload your pic"} uploadType={ContentType.Image} onFileUpload={(file, type) => {
                                this.setState({ profilePic: file })
                            }} />
                            <div style={{ height: '1.2rem' }} />
                        </div>
                }
                {
                    getFieldDecorator("name", {
                        rules: [
                            { required: true, message: "Please input your name", }
                        ], initialValue: user.name
                    })(
                        <Input
                            className="login-input"
                            size="large"
                            maxLength={20}
                            // prefix={<Icon type="user" />}
                            required
                            placeholder="Name"
                        />
                    )
                }
                <div style={{ height: '1.2rem' }}
                />
                {
                    getFieldDecorator("username", {
                        rules: [
                            { required: true, message: "Please input your username" },
                        ], initialValue: user.username
                    })(
                        <Input
                            className="login-input"
                            size="large"
                            maxLength={20}
                            // prefix={<Icon type="user" />}
                            required
                            placeholder="Username"
                        />
                    )
                }
                {/* 
                {
                    getFieldDecorator("location", {
                        initialValue: user.location
                    })(
                        <div >
                            <LocationInput
                                onLocationConfirmed={(location) => {
                                    this.setState({ location })
                                }} />
                            <div style={{ paddingTop: '0.7rem' }}>{user.location ? user.location.areaDetails : undefined}</div>
                        </div>

                    )
                } */}
                <div style={{ height: '1.2rem' }} />
                {
                    getFieldDecorator("bio", {
                        rules: [
                            { required: true, message: "Please input your bio" },
                        ], initialValue: user.bio
                    })(
                        <Input
                            className="login-input"
                            size="large"
                            maxLength={20}
                            // prefix={<Icon type="user" />}
                            required
                            placeholder="Bio"
                        />
                    )
                }
                <div style={{ height: '1.2rem' }} />
                {/* {
                    user.type == ProfileType.SUTRA ?
                        getFieldDecorator("profession", {
                            rules: [
                                { required: true, message: "Please input your name", },
                            ], initialValue: user.profession
                        })(<Select style={{ width: "22rem", }}
                            size="large" >
                            {professions.map((e) => <Option value={e.id} title={e.name} >{e.name}</Option>)}
                        </Select>) : <div />
                } */}
                <Form.Item>
                    <Button type="primary" htmlType="submit" loading={isLoading} disabled={isLoading} className="login-action-button">Continue</Button>
                </Form.Item>
                <div style={{ height: '0.4rem' }} />
                <div style={{ height: '0.5rem' }} />
            </Form>
        );
    }
}

const WrappedAddProfileForm: any = Form.create({ name: "add_form", })(AddProfileForm)

export default WrappedAddProfileForm;