import { Button, Form, Input, message, Select } from 'antd';
import firebase from 'firebase';
import queryString from 'query-string';
import React, { Component } from 'react';
import { Trans } from 'react-i18next';
import { withRouter } from 'react-router';
import LoginController from '../../controllers/LoginController';
import ProfileController from '../../controllers/ProfileController';
import UserController from '../../controllers/UserController';
import AppConstants from '../../helpers/AppConstants';
import AppRoutes from '../../helpers/AppRoutes';
import ProfileHelper from '../../helpers/ProfileHelper';
import UIHelper from '../../helpers/UIHelper';
import { connectedRouteProps } from '../../props';
import { authConnector } from '../../stores/auth/connector';

const InputGroup = Input.Group;
const { Option } = Select;

interface Window { recaptchaVerifier: firebase.auth.RecaptchaVerifier; }

class LoginMobilePage extends Component<connectedRouteProps, any> {

    recaptcha;
    state = {
        mobileNumber: null,
        otp: null,
        name: undefined,
        isLoading: false,
        isOTPSent: false,
        isCaptchaVerifed: false,
        recaptchaVerifier: null,
        isNewUser: false,
    }


    componentDidMount() {
        window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier(this.recaptcha, {
            'size': 'normal',
            'callback': async (response: firebase.auth.ApplicationVerifier) => {
                const { mobileNumber } = this.state;
                this.setState({ isCaptchaVerifed: true, })

            },
            'expired-callback': function () {
                // Response expired. Ask user to solve reCAPTCHA again.
                // ...
            }
        });
        window.recaptchaVerifier.render().then(function (widgetId) {
        });
    }


    isSignup = () => {
        let search = this.props.history.location.search;
        let query = queryString.parse(search);
        if (query["option"] && query["option"] == AppConstants.REGISTER.toLowerCase()) {
            return true;
        }
    }


    sendOTP = async () => {
        const { mobileNumber, name, isNewUser } = this.state;
        if (!mobileNumber) {
            message.error("Please enter mobile number");
            return;
        }
        if (isNewUser && !name) {
            message.error("Please enter name");
            return;
        }
        this.setState({ isLoading: true })
        if (!isNewUser) {
            let profile = await UserController.getUserDetailsByField("mobileNumber", mobileNumber);
            if (!profile) {
                this.setState({ isNewUser: true, isLoading: false });
                message.error("Your profile does not exists. Please enter your name");
                return;
            }
        }
        let loginResponse = await LoginController.sendOTP("+91" + mobileNumber, window.recaptchaVerifier);
        this.setState({ isLoading: false })

        if (loginResponse.data) {
            message.success("OTP Sent");
        }
        else {
            message.error(loginResponse.error)
        }
        this.setState({ isOTPSent: true, })
    }

    verifyOTP = async () => {
        const { mobileNumber, otp, name } = this.state;
        this.setState({
            isLoading: true
        })
        let response = await LoginController.verifyOTP("+91" + mobileNumber, otp, name);
        this.setState({
            isLoading: false
        })
        if (!response || response.error != null)
            message.error(response.error);
        else {
            let user = response.data;
            ProfileHelper.loginProfile({ id: user.id, name, profilePic: UIHelper.getProfilePicture(name) }, this.props.history, this.props.login, ProfileHelper.getType(this.props.history.location.search));
        }
    }


    navigateToProfile = async (profile) => {
        if (ProfileController.isProfileComplete(profile))
            this.props.history.push(AppRoutes.HOME)
        else {
            this.props.history.push(AppRoutes.PROFILE_COMPLETE)
        }
    };

    render() {
        const { isOTPSent, isCaptchaVerifed, isLoading, isNewUser } = this.state;
        let labelText = this.isSignup() ? "Signup" : "Login";
        return (
            <div className="login-section">
                <h3 className="login-title"><Trans>{labelText}</Trans> </h3>
                <h4 className="login-description">
                    <Trans>{labelText} with mobile number</Trans>
                </h4>
                <div style={{ height: '1rem ' }} />
                <Form>
                    <InputGroup compact style={{ display: "flex", alignItems: "center", margin: "auto auto", width: "22rem", }}>
                        <Select defaultValue="+91" size="large" >
                            <Option value="+91">+91</Option>
                        </Select>
                        <div style={{ width: '1rem ' }} />
                        <Input
                            className="login-input"
                            disabled={isOTPSent || isNewUser}
                            size="large"
                            style={{ width: "0px", flexGrow: 1 }}
                            onChange={(event) => {
                                this.setState({ mobileNumber: event.target.value })
                            }}
                            placeholder="Mobile Number"
                        />
                    </InputGroup>
                    <div style={{ height: '1.2rem' }} />
                    <Input
                        className="login-input"
                        size="large"
                        disabled={!isOTPSent}
                        onChange={(event) => {
                            this.setState({
                                otp: event.target.value
                            })
                        }}
                        placeholder="One Time Password"
                    />
                    {
                        isNewUser ? <Input
                            className="login-input"
                            size="large"
                            style={{ width: "0px", marginTop: '1.2rem', flexGrow: 1 }}
                            onChange={(event) => {
                                this.setState({ name: event.target.value })
                            }}
                            placeholder="Name"
                        /> : <div />
                    }
                    <div style={{ height: '1.2rem' }} />
                    <div ref={(ref) => this.recaptcha = ref} style={{ marginLeft: "8rem", display: isOTPSent ? "none" : "block", marginBottom: "1.2rem" }}></div>
                    <Button loading={isLoading} disabled={(!isOTPSent && !isCaptchaVerifed)} onClick={!isOTPSent ? this.sendOTP : this.verifyOTP} className="login-action-button"><Trans>{isOTPSent ? "Verify OTP" : "Send OTP"}</Trans>></Button>
                    <div style={{ height: '0.4rem' }} />
                    <Button onClick={() => {
                        this.props.history.replace(AppRoutes.LOGIN);
                    }} type="link">Go Back</Button>
                </Form>
            </div>
        );
    }
}

export default authConnector(withRouter(LoginMobilePage));