import { Avatar, Checkbox, Dropdown, Icon, Menu, Tooltip } from 'antd';
import React, { Component } from 'react';
import VisibilitySensor from 'react-visibility-sensor';
import UserController from '../../../controllers/UserController';
import Message, { ImageMessage, MessageType, TextMessage } from '../../../models/chat/Message';
import './style.css';

type MessageProps = {
    message: Message,
    onReply?: (message: any) => void,
    onForward?: (message: any) => void,
    onScrollTo?: (id: string) => void,
    onSeen?: (message: any) => void,
    selected?: boolean,
    onSelected?: (selected: boolean, message: any) => void,
    canSelect?: boolean
}


class MessageComponent extends Component<MessageProps, {}> {

    render() {
        let { message, onReply, onScrollTo, onForward, onSelected, canSelect, selected } = this.props;
        let { sender = {} } = message;
        let time = new Date();
        try {
            let milliseconds = Date.parse(`${message.recordedAt}`)
            time = new Date(milliseconds);
        } catch (error) {

        }
        const options = (
            <Menu>
                <Menu.Item onClick={() => {
                    onReply(message);
                }}>
                    <span>
                        Reply
              </span>
                </Menu.Item>
                {/* <Menu.Item onClick={() => {
                    if (onForward)
                        onForward(message);
                }}>
                    <span>
                        Forward
                   </span>
                </Menu.Item> */}
            </Menu >
        );
        let content = <div />;
        if (message.type == MessageType.text) {
            content = TextMessageComponent(message.content);
        }
        if (message.type == MessageType.image) {
            content = ImageMessageComponent(message.content);
        }
        if (message.type == MessageType.video) {
            content = VideoMessageComponent(message.content);
        }
        return (
            <VisibilitySensor onChange={(visible) => {
                if (!visible) {
                    return;
                }
                const { onSeen, message } = this.props;
                if (onSeen)
                    onSeen(message);
            }}>
                <div className={"private-message-parent " + (selected ? "selected" : "")} >
                    {canSelect ? <Checkbox checked={selected} className={"checkbox"} onChange={(e) => {
                        if (onSelected)
                            onSelected(e.target.checked, message);
                    }} /> : <div />}
                    <div id={message.id} className={"private-message-container " + (message.isSelf ? "sent" : "received")} key={message.id}>
                        <Avatar className="private-message-avatar" src={sender.profilePic || UserController.getGravatar(sender.name)} />
                        <div className="private-message-body">
                            <div className="private-message-content">
                                <span className="private-message-name">{sender.name}
                                    {message.isForward ? <Tooltip title="Forwarded"><img className="icon" src={"/icons/forward.png"} /></Tooltip> : <span />}
                                    {canSelect ? <div /> : onReply ? <Dropdown overlay={options} >
                                        <Icon type="down" className="menu-btn" onClick={() => { }} />
                                    </Dropdown> : <span />}</span>
                                {message.replyTo ? <div className="reply-body" onClick={onScrollTo ? () => { onScrollTo(message.id) } : undefined}>
                                    <span className="sender-name">{(message.replyTo.sender || {}).name}</span>
                                    <span className="message">{Message.getText(message.replyTo)}</span>
                                </div> : <div />}
                                {content}
                            </div>
                            <div className="private-message-date">
                                {time.toLocaleString()}
                                <div />
                                {/* <span className="seen-text">
                                    {message.seenAt && message.isSelf ? <Tooltip title={"Seen at " + message.seenAt.toDateString()}><DoneAllIcon fontSize="inherit" style={{ fontSize: '1rem' }} /></Tooltip> : ""}
                                </span> */}
                            </div>
                        </div>
                    </div>
                </div>
            </VisibilitySensor>
        );
    }

}

export const TextMessageComponent = (props: TextMessage) => {
    let { content } = props;
    return (
        <div>
            {content}
        </div>
    );
}

export const ImageMessageComponent = (props: ImageMessage) => {
    let { url } = props;
    return (
        <img src={url} />
    );
}

export const VideoMessageComponent = (props: ImageMessage) => {
    let { url } = props;
    return (
        <video src={url} controls />
    );
}


export default MessageComponent;