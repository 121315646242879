import firebase from 'firebase';
import 'firebase/auth';
import Cookies from 'universal-cookie';
import Firebase from '../Firebase';
import AppCollections from '../helpers/AppCollections';
import AppResponse from '../models/AppResponse';
import NewUser from '../models/NewUser';
import User from '../models/User';
import CustomerController from './CustomerController';
import ProfileController, { ProfileType } from './ProfileController';
import StoreController from './StoreController';
import SutrazController from './SutrazController';
import UserController from './UserController';
var ip = require('ip');

declare global {
    interface Window { recaptchaVerifier: firebase.auth.RecaptchaVerifier; }
}

const app = new Firebase();



class LoginController {



    static auth = firebase.app().auth();

    static confirmationResult;


    static async sendOTP(mobileNumber, appVerifier: firebase.auth.ApplicationVerifier): Promise<AppResponse<boolean>> {
        try {
            LoginController.confirmationResult = await this.auth.signInWithPhoneNumber(mobileNumber, appVerifier);
            return new AppResponse(true, null);
        } catch (error) {
            console.log("Error", error)
            return new AppResponse(null, error["message"] || 'Some error occurred');
        }
    }

    static async verifyOTP(mobileNumber, otp, name): Promise<AppResponse<NewUser>> {
        try {
            console.log("Login ", LoginController.confirmationResult)
            let crediential = await LoginController.confirmationResult.confirm(otp);
            let isNewUser = crediential.additionalUserInfo.isNewUser;
            let newUser: NewUser = new NewUser(crediential.user.uid, name, null, new Date(), null, null, mobileNumber, null, null, null, false);
            if (isNewUser) {
                return await this.createUser(newUser);
            }
            else {
                let user = await this.getUser(crediential.user.uid);
                return user ? new AppResponse(user, null) : new AppResponse(null, "Not found");
            }

        } catch (error) {
            console.log("Error", error)
            return new AppResponse(null, error["message"] || "Some error occurred");
        }
    }


    static async getDefaultProfile(user: { id, name, profilePic, language }, create?: boolean, type?: string): Promise<AppResponse<any>> {
        try {
            let profiles = await ProfileController.getAllProfile(user.id,);
            profiles = profiles.filter((e) => e.type == (type || ProfileType.CUSTOMER));
            if (profiles.length == 0) {
                if (create) {
                    let { profilePic, name, id } = user;
                    let profile = { userId: id, name, profilePic };
                    profile["username"] = ProfileController.getUsername(name);
                    let response;
                    if (type == ProfileType.SUTRA) {
                        response = await SutrazController.addSutra(profile);
                    }
                    else if (type == ProfileType.STORE) {
                        response = await StoreController.addStore(profile);
                    }
                    else {
                        response = await CustomerController.addCustmomer(profile);
                    }
                    if (response.data) {
                        let profileResponse = await ProfileController.getProfileByUserId(id, type || ProfileType.CUSTOMER);
                        if (profileResponse) {
                            return new AppResponse(profileResponse, null);
                        }
                        else {
                            return new AppResponse(null, "Profile not created");
                        }
                    }
                    else {
                        return new AppResponse(null, response.error);
                    }
                }
                else {
                    return new AppResponse(null, "Profile not exists");
                }
            }
            else {
                return new AppResponse(profiles[0], null);
            }
        } catch (error) {
            return new AppResponse(null, error);
        }

    }

    /**
     * 
     * Login with facebook 
     */
    static async loginWithFacebook(): Promise<AppResponse<NewUser>> {
        try {
            let facebookProvider = new firebase.auth.FacebookAuthProvider();
            let crediential = await firebase.auth().signInWithPopup(facebookProvider);
            let profile = crediential.additionalUserInfo.profile;
            if (crediential.additionalUserInfo.isNewUser) {
                let newUser = new NewUser(crediential.user.uid, profile["name"], profile["picture"]["data"]["url"], new Date(), null, profile["id"], null, profile["email"], null, null, false);
                return (await this.createUser(newUser));
            } else {
                let user = await this.getUser(crediential.user.uid)
                return user ? new AppResponse<NewUser>(user, null) : new AppResponse<NewUser>(null, "Not Found");
            }
        } catch (error) {
            console.log("Error", error)
            return new AppResponse(null, error["message"] || "Some error occurred");
        }
    }


    /**
     * 
     * Login with google 
     */
    static async loginWithGoogle(): Promise<AppResponse<NewUser>> {
        try {
            let googleProvider = new firebase.auth.GoogleAuthProvider();
            let crediential = await firebase.auth().signInWithPopup(googleProvider);
            let profile = crediential.additionalUserInfo.profile;
            let uid = crediential.user.uid;
            let existUser = await UserController.getUser(uid);
            if (!existUser) {
                let newUser = new NewUser(crediential.user.uid, profile["name"], profile["picture"], new Date(), profile["id"], null, null, profile["email"], null, null, false);
                return (await this.createUser(newUser));
            }
            else {
                let user = await this.getUser(crediential.user.uid)
                return user ? new AppResponse<NewUser>(user, null) : new AppResponse<NewUser>(null, "Not Found");
            }

        } catch (error) {
            console.log("Error", error)
            return new AppResponse(null, error["message"] || "Some error occurred");
        }
    }

    /**
* 
* Login with email 
*/
    static async loginWithEmail(email: string, password: string): Promise<AppResponse<NewUser>> {
        try {
            let crediential = await firebase.auth().signInWithEmailAndPassword(email, password);
            return (await this.loginWithcCredential(crediential));

        } catch (error) {
            console.log("Error", error)
            return new AppResponse(null, error["message"] || "Some error occurred");
        }
    }

    /**
    * 
    * Signup with email 
    */
    static async signupWithEmail(user: any): Promise<any> {
        try {
            const credientials = await firebase.auth().createUserWithEmailAndPassword(user.email, user.password);
            let newUser = { ...user };
            newUser.id = credientials.user.uid;
            const createdUser = await this.createUser(newUser);
            await this.loginWithEmail(user.email, user.password);
            return createdUser;
        } catch (error) {
            console.log("Error", error)
            return new AppResponse(null, error["message"] || "Some error occurred");
        }
    }

    /**
    * 
    * Forgot email 
    */
    static async sendForgotPasswordLink(email: string): Promise<AppResponse<boolean>> {

        try {
            await firebase.auth().sendPasswordResetEmail(email);
            return new AppResponse(true, null);
        } catch (error) {
            console.log("Error", error)
            return new AppResponse(null, error["message"] || "Some error occurred");
        }
    }


    /**
     * 
     * User Response
     */

    static async loginWithcCredential(crediential: firebase.auth.UserCredential): Promise<AppResponse<NewUser>> {
        if (crediential.additionalUserInfo.isNewUser) {
            let newUser = new NewUser(crediential.user.uid, null, null, new Date(), null, null, null, null, null, null, false);
            var newUserResponse = await this.createUser(newUser);
            return newUserResponse;
        }
        else {
            let user = await this.getUser(crediential.user.uid);
            if (user) {
                return new AppResponse(user, null);
            }
            else {
                return new AppResponse(null, "User not found");
            }
        }
    }

    /**
     * 
     * Create User
     */

    static async createUser(user: NewUser): Promise<AppResponse<NewUser>> {
        try {
            user.createdAt = new Date();
            await firebase.firestore().collection(AppCollections.USERS).doc(user.id).set(JSON.parse(JSON.stringify(user)));
            await this._saveCurrentUser(user);
            return new AppResponse(user, null);
        } catch (error) {
            console.log("Error", error);
            return new AppResponse(null, error);
        }
    }


    /**
     * 
     * Get User
     */

    static async getUser(uid: string): Promise<NewUser> {
        try {
            var snapshot = await firebase.firestore().collection(AppCollections.USERS).doc(uid).get();
            if (snapshot.exists) {
                return NewUser.fromJson(snapshot.data())
            }
        } catch (error) {
            console.log("Error", error);
            return null;
        }
    }


    /**
     * 
     * Validate User
     */
    static validateUser(user): boolean {
        return user && Object.keys(user).length > 0 && user.id;
    }



    static async isUsernameAvailable(username: string): Promise<boolean> {
        if (!username) {
            return false;
        }
        let docs = await firebase.firestore().collection(AppCollections.USERS).where("username", "==", username).get();
        return docs.docs.length == 0;
    }

    static cookies = new Cookies();

    static async logOut() {
        await firebase.auth().signOut();
        localStorage.removeItem(UserController.USER);
    }

    static async _saveCurrentUser(user: NewUser) {
        localStorage.setItem(UserController.USER, JSON.stringify(user));
    }

    static async saveCurrentProfile(id: string) {
        console.log("Profile ", id)
        localStorage.setItem(ProfileController.PROFILES, id);
    }

    static async getCurrentProfile(): Promise<string> {
        var item = localStorage.getItem(ProfileController.PROFILES,);
        return item;
    }

    static async deleteCurrentProfile(): Promise<void> {
        localStorage.removeItem(ProfileController.PROFILES,);
    }

    static async getCurrentUser(): Promise<User> {
        try {
            let resultString = localStorage.getItem(UserController.USER);
            let result: {};
            if (resultString != null) {
                result = JSON.parse(resultString);
                return User.fromJson(result);
            }
            return null;
        }
        catch (e) {
            return null;
        }
    }

    static async getCurrenActivetUser(): Promise<AppResponse<User>> {
        try {
            let resultString = localStorage.getItem("persist:root");
            let result: {};
            if (resultString != null) {
                result = JSON.parse(resultString);
                const userData = JSON.parse(result[0]);
                const user = userData.user;
                const usr = User.fromJson(user);
                return new AppResponse(usr, null);
            }
            return null;
        }
        catch (e) {
            return null;
        }
    }

    static getNewCurrentUser(): NewUser {
        try {
            let resultString = localStorage.getItem(UserController.USER);
            console.log(resultString);

            let result: {};
            if (resultString) {
                result = JSON.parse(resultString);
                return NewUser.fromJson(result);
            }
            return null;
        }
        catch (e) {
            return null;
        }
    }




}

export default LoginController;