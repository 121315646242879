import { Spin } from 'antd';
import React, { Component } from 'react';
import { isMobile } from 'react-device-detect';
import { Trans } from 'react-i18next';
import { ProfileType } from '../../controllers/ProfileController';
import StoryController from '../../controllers/StoryController';
import StoryGridPage from '../storyPage/StoryGridPage';

class StoryHome extends Component<any, any>{

    state = {
        stories: [],
        isLoading: true
    }

    componentDidMount() {
        let { stories } = this.props;
        if (stories) {
            this.setState({ isLoading: false });
        } else
            this.loadStories();
    }

    getTitle = () => {
        const { profileType } = this.props;
        return ProfileType.getPluralType(profileType);
    }

    getDescription = () => {
        const { profileType } = this.props;
        return ProfileType.getDescription(profileType)
    }

    loadStories = async () => {
        this.setState({ isLoading: true });
        let storiesResponse = await StoryController.getHomeStories();
        this.setState({ stories: storiesResponse.data, isLoading: false });
        let { onStoriesLoaded } = this.props;
        if (onStoriesLoaded && storiesResponse.data) {
            onStoriesLoaded(storiesResponse.data);
        }
    }

    render() {
        const { isLoading } = this.state;
        const { profileType, stories = [], } = this.props;
        let filtered = stories.filter((val) => val.profile.type == profileType);
        filtered = stories.filter((val) => val.profile.type == profileType);
        return (
            isLoading ? <Spin tip="Loading Stories..." /> : <div style={{ padding: isMobile ? "1rem" : "2rem 4rem", }}>
                <span style={{ fontSize: '3rem', fontWeight: "bold", color: "black" }}><Trans>{this.getTitle()}</Trans> </span>
                <h2 style={{ fontSize: '1.14rem', fontWeight: 'normal', color: "rgba(0,0,0,0.4)" }}><Trans>{this.getDescription()}</Trans> </h2>
                <StoryGridPage stories={filtered} />
            </div>
        );
    }
}

export default StoryHome;