import { default as i18n } from "i18next";
import detector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
import './helpers/AppConstants';


i18n.use(detector).use(initReactI18next).init({
  // we init with resources
  resources: {
    EN: {
      translations:
      {
        "Introduction": "Introduction",
        "is an internationalization-framework which offers a complete solution to localize your product from web to mobile and desktop":
          "is an internationalization-framework which offers a complete solution to localize your product from web to mobile and desktop",
        "Plugins to detect the user language":
          "Plugins to detect the user language",
        "Plugins to load translations": "Plugins to load translations",
        "Optionally cache the translations":
          "Optionally cache the translations",
        "Advantages": "Advantages",
        "Flexibility to use other packages": "Flexibility to use other packages",
        "Sutraz": "Sutras",
        "An Alternative AppStore": "An Alternative AppStore",
        "A Products Search Engine": "A Products Search Engine",
        "Search Engine for Products": "Search Engine for Products",
        "Find All Products Here!": "Find All Products Here!",
        "There is an App for that": "There is an App for that",
        "Find Your Product": "Find Your Product",
        "Products": "Products",
        "Review": "Review",
        "List": "List",
        "How To": "How To",
        "FAQ": "FAQ",
        "Find your App": "Find your App",
        "Log In": "Log In",
        "Tell Us what You Need": "Tell Us what You Need",
        "Needs Fulfillment Store": "Needs Fulfillment Store",
        "Shopping by Seeing Video": "Shopping by Seeing Video",
        "Enter your message and press ENTER": "Enter your message and press ENTER",
        "Send": "Send",
        "Start Chat": "Start Chat",
        "Don't have an account sign up now": "Don't have an account sign up now",
        "Email Address": "Email Address",
        "Continue": "Continue",
        "Name": "Name",
        "Username": "Username",
        "Phone Number": "Phone Number",
        "Password": "Password",
        "Confirm Password": "Confirm Password",
        "Submit": "Submit",
        "Login": "Login",
        "Register": "Register",
        "Register As User": "Register As User",
        "Register As Sutra": "Register As Sutra",
        "Already have an account login now": "Already have an account login now",
        "Contacts": "Contacts",
        "Channels": "Channels",
        "Users": "Users",
        "Invites": "Invites",
        "Profile": "Profile",
        "Log Out": "Log Out",
        "Search Here": "Search Here",
        "Image": "Image",
        "Video": "Video",
        "Advanced Search": "Advanced Search",
        "Create Channel": "Create Channel",
        "Send Invite": "Send Invite",
        "Channel Name": "Channel Name",
        "Channel Detaiks": "Channel Detaiks",
        "Read Only ": "Read Only ",
        "Only admin can send messages": "Only admin can send messages",
        "Creating Channel": "Creating Channel",
        "No Data": "No Data",
        "Invite": "Invite",
        "Update": "Update",
        "Close": "Close",
        "Buy Now": "Buy Now",
        "Chat": "Chat",
        "Buy": "Buy",
        "Research": "Research",
        "Shopping": "Shopping",
        "Compare": "Compare"
      }
    },
    HI: {
      translations: {
        "Introduction": "प्रस्तावना",
        "is an internationalization-framework which offers a complete solution to localize your product from web to mobile and desktop":
          "एक अंतर्राष्ट्रीयकरण - ढांचा है जो आपके उत्पाद को वेब से मोबाइल और डेस्कटॉप पर स्थानांतरित करने का एक संपूर्ण समाधान प्रदान करता है",
        "Plugins to detect the user language":
          "उपयोगकर्ता भाषा का पता लगाने के लिए प्लगइन्स",
        "Plugins to load translations": "अनुवाद लोड करने के लिए प्लगइन्स",
        "Optionally cache the translations": "वैकल्पिक रूप से अनुवाद कैश करें",
        "Advantages": "लाभ",
        "Flexibility to use other packages":
          "अन्य पैकेजों का उपयोग करने के लिए लचीलापन",
        "Sutraz": "सूत्र",
        "An Alternative AppStore": "एक वैकल्पिक AppStore",
        "A Products Search Engine": "एक उत्पाद खोज इंजन",
        "Search Engine for Products": "उत्पादों के लिए खोज इंजन",
        "Find All Products Here!": "यहाँ सभी उत्पादों का पता लगाएं!",
        "Find Your Product": "अपने उत्पाद का पता लगाएं",
        "There is an App for that": "उसके लिए एक ऐप है",
        "Products": "उत्पाद",
        "Review": "समीक्षा",
        "List": "सूची",
        "How To": "कैसे",
        "FAQ": "सामान्य प्रश्न",
        "Find your App": "अपना ऐप ढूंढें",
        "Login": "लॉग इन करें",
        "Log In": "लॉग इन करें",
        "Register As User": "उपयोगकर्ता के रूप में पंजीकृत करें",
        "Register As Sutra": "प्रकाशक के रूप में पंजीकृत करें",
        "Register": "साइन अप करें",
        "Tell Us what You Need": "अपनी ज़रूरत के बारे में हमें बताएं",
        "Needs Fulfillment Store": "आवश्यकता पूर्ति स्टोर",
        "Shopping by Seeing Video": "चैट पर खरीदारी करें",
        "Enter your message and press ENTER": "अपना संदेश दर्ज करें और ENTER दबाएँ",
        "Send": "भेजना",
        "Start Chat": "बातचीत शुरू कीजिए",
        "Username": "उपयोगकर्ता नाम",
        "Password": "पारण शब्द",
        "Submit": "प्रस्तुत",
        "Don't have an account sign up now": "अब खाता साइन अप न करें",
        "Name": "नाम",
        "Email Address": "ईमेल पता",
        "Phone Number": "फ़ोन नंबर",
        "Confirm Password": "पासवर्ड की पुष्टि कीजिये",
        "Already have an account login now": "पहले से ही एक खाता लॉगिन है",
        "Contacts": " संपर्क",
        "Channels": " चैनल",
        "Users": " उपयोगकर्ता",
        "Invites": " आमंत्रण",
        "Profile": " प्रोफ़ाइल",
        "Log Out": " लॉग आउट",
        "Search Here": " यहाँ ढूँढे",
        "Image": " छवि",
        "Video": " वीडियो",
        "Advanced Search": " उन्नत खोज",
        "Create Channel": " चैनल बनाएँ",
        "Send Invite": " आमंत्रण भेजो",
        "Channel Name": " चैनल का नाम",
        "Channel Detaiks": " चैनल विवरण",
        "Read Only ": " सिफ़ पढ़िये",
        "Only admin can send messages": " केवल व्यवस्थापक ही संदेश भेज सकते हैं",
        "Creating Channel": " चैनल बनाना",
        "No Data": " कोई डेटा उपलब्ध नहीं है",
        "Invite": " आमंत्रण",
        "Update": " अपडेट करें",
        "Close": " बंद करे",
        "Buy Now": " अभी खरीदें",
        "Chat": " चैट",
        "Conversations": "बात चिट",
        "Settings": "समायोजन",
        "Logout": "लॉग आउट",
        "Account": "लेखा",
        "Message": "संदेश",
        "Cancel": "रद्द करना",
        "Channel Info": "चैनल की जानकारी",
        "User View": "उपयोगकर्ता दृश्य",
        "Type a message": "एक संदेश लिखें",
        "Create": "सृजन करना",
        "Description": "विवरण",
        "Join Channel": "चैनल से जुड़ें",
        "Dashboard": "डैशबोर्ड",
        "Buy": "खरीदें",
        "Research": "अनुसंधान",
        "Shopping": "खरीदारी",
        "Compare": "तुलना",
        "Consumer": "उपभोक्ता",
        "Consumers": "उपभोक्ता",
        "Businesses": "व्यापारयों",
        "Business": "व्यापार",
        "View Profile": "प्रोफाइल देखिये",
        "Login to view comments": "टिप्पणी देखने के लिए लॉगिन करें",
        "Search": "खोज",
        "App": "ऐप",
        "Post Your Question": "अपना प्रश्न पोस्ट करें",
        "Discover Businesses": "व्यापारयों खोजें",
        "Home": "होम",
        "Suggested": "सुझाव दिया",
        "Discover": "खोज",
        "Welcome to Sutras": "सूत्र में आपका स्वागत है",
        "Please tap a chat to start messaging": "मैसेजिंग शुरू करने के लिए कृपया चैट पर टैप करें",
        "Start Conversation": "बातचीत शुरू करें",
        "Postyourquestions,problems": "अपने प्रश्न, समस्याएँ पोस्ट करें",
        "Describe your problem": "अपनी समस्या बताएं",
        "Title": "टाइटल",
        "Post your Requirement": "अपने प्रश्न, समस्याएँ पोस्ट करें",
        "Hashtags": "हैशटैग",
        "Signup to Sutras": "सूत्र को साइनअप करें",
        "Manage your account, check notifications,comment on videos and more.": "अपना अकाउंट मैनेज करें, सूचनाएं जांचें, नोटिफिकेशन्स चेक करें, वीडियो पर टिप्पणी करें कमेंट और अधिक।",
        "Signup": "साइन-अप करें",
        "Signup with mobile number": "मोबाइल नंबर के साथ साइन-अप करें",
        "Signup with email address": "ईमेल पते के साथ साइन-अप करें",
        "Signup with Facebook": "फेसबुक पर साइन-अप करें",
        "Signup with Google": "गूगल पर  साइन-अप करें",
        "Login with mobile number": "मोबाइल नंबर से लॉगिन करें",
        "Login with email address": "ईमेल एड्रेस पर लॉगिन करें",
        "Login with Facebook": "फेसबुक पर लॉगिन करें",
        "Login with Google": "गूगल पर लॉगिन करें",
        "These are the Answers that Businesses are giving": "ये वे उत्तर हैं जो व्यवसाय दे रहे हैं",
        "These are the Questions that our Consumers are asking about Businesses": "ये ऐसे प्रश्न हैं जो हमारे उपभोक्ता व्यवसाय के बारे में पूछ रहे हैं",
        "Apps": "ऐप्स",
        "Chats": "चैट",
        "My Profile": "मेरी प्रोफ़ाइल",
        "Manage your account, check notifications, comment on videos and more.": "अपना खाता प्रबंधित करें, सूचनाएं जांचें, वीडियो पर टिप्पणी करें और बहुत कुछ।",
        "Login to Sutras": "सूत्र में लॉग इन करें",
        "Verify OTP": "OTP सत्यापित करें",
        "Send OTP": "OTP भेजें",
        "Signup with email": "ईमेल के लिए साइन इन करें",
        "Send reset password link to your email": "अपने ईमेल पर रीसेट पासवर्ड लिंक भेजें।",
        "Send reset link": "रीसेट लिंक भेजें",
        "Forgot Password": "पासवर्ड भूल गए",
        "Follow": "फॉलो",
        "Unfollow": "अनफॉलो",
        "Following": "निम्नलिखित",
        "Followers": "समर्थक",
        "Stories": "कहानियों",
        "Brand": "ब्रांड",
        "Brands": "ब्रांड्स",
        "These are the products and offerings of our Retailers": "ये हमारे रिटेलर्स के उत्पाद और प्रसाद हैं",
        "These are the requirements that Consumers are putting": "ये ऐसी आवश्यकताएं हैं जो उपभोक्ता डाल रहे हैं"
      }
    },

    MR: {
      translations: {

        "Introduction": "परिचय",
        "Advantages": "फायदे",
        "Flexibility to use other packages":
          "इतर पॅकेजेस वापरण्याची लवचिकता",
        "An Alternative AppStore": "एक वैकल्पिक अ‍ॅपस्टोर",
        "A Products Search Engine": "एक उत्पादन शोध इंजिन",
        "Search Engine for Products": "उत्पादनांसाठी शोध इंजिन",
        "Find All Products Here!": "येथे सर्व उत्पादने शोधा!",
        "Find Your Product": "आपले उत्पादन शोधा",
        "There is an App for that": "त्यासाठी एक अ‍ॅप आहे",
        "Products": "उत्पादने",
        "Review": "पुनरावलोकन",
        "List": "यादी",
        "How To": "कसे",
        "FAQ": "सामान्य प्रश्न",
        "Find your App": "आपला अ‍ॅप शोधा",
        "Login": "लॉगिन",
        "Log In": "लॉगिन",
        "Register As User": "वापरकर्ता म्हणून नोंदणी करा",
        "Register As Sutra": "प्रकाशक म्हणून नोंदणी करा",
        "Register": "साइन अप करा",
        "Tell Us what You Need": "आपल्याला काय हवे ते सांगा",
        "Needs Fulfillment Store": "भरणे दुकान आवश्यक आहे",
        "Shopping by Seeing Video": "गप्पांमधून खरेदी",
        "Enter your message and press ENTER": "आपला संदेश प्रविष्ट करा आणि ENTER दाबा",
        "Send": "पाठवा",
        "Start Chat": "गप्पा सुरू करा",
        "Username": "वापरकर्तानाव",
        "Password": "संकेतशब्द",
        "Submit": "सबमिट करा",
        "Don't have an account sign up now": "आता खाते साइन अप करू नका",
        "Name": "नाव",
        "Email Address": "ईमेल पत्ता",
        "Phone Number": "फोन नंबर",
        "Confirm Password": "पासवर्ड की पुष्टि कीजिये",
        "Already have an account login now": "आधीच आधीपासूनच खाते लॉगिन करा",
        "Sutraz": "सूत्र",
        "Contacts": "संपर्क",
        "Channels": "चैनल",
        "Users": "उपयोगकर्ता",
        "Invites": "आमंत्रण",
        "Profile": "प्रोफ़ाइल",
        "Log Out": "लॉग आउट",
        "Search Here": "यहाँ ढूँढे",
        "Image": "छवि",
        "Video": "वीडियो",
        "Advanced Search": "उन्नत खोज",
        "Create Channel": "चैनल बनाएँ",
        "Send Invite": "आमंत्रण भेजो",
        "Channel Name": "चैनल का नाम",
        "Channel Detaiks": "चैनल विवरण",
        "Read Only ": "सिफ़ पढ़िये",
        "Only admin can send messages": "केवल व्यवस्थापक ही संदेश भेज सकते हैं",
        "Creating Channel": "चैनल बनाना",
        "No Data": "कोई डेटा उपलब्ध नहीं है",
        "Invite": "आमंत्रण",
        "Update": "अपडेट करें",
        "Close": "बंद करे",
        "Buy Now": "अभी खरीदें",
        "Chat": "चैट",
        "Buy": "വാങ്ങാൻ",
        "Research": "संशोधन",
        "Shopping": "खरेदी",
        "Compare": "तुलना करा",
        "Brand": "ब्रांड",
        "Brands": "ब्रांड्स",
        "Conversations": "സംഭാഷണങ്ങൾ", "Settings": "ക്രമീകരണങ്ങൾ", "Logout": "ലോഗ് .ട്ട് ചെയ്യുക", "Account": "അക്കൗണ്ട്", "Message": "സന്ദേശം", "Cancel": "റദ്ദാക്കുക", "Channel Info": "ചാനൽ വിവരം", "User View": "ഉപയോക്തൃ കാഴ്ച", "Type a message": "ഒരു സന്ദേശം ടൈപ്പുചെയ്യുക", "Create": "സൃഷ്ടിക്കാൻ", "Description": "വിവരണം", "Join Channel": "ചാനലിൽ ചേരുക"
      }
    },

    BN: {
      translations: {
        "Introduction": "ভূমিকা",
        "Advantages": "সুবিধাদি",
        "Flexibility to use other packages":
          "অন্যান্য প্যাকেজ ব্যবহার করার নমনীয়তা",
        "An Alternative AppStore": "একটি বিকল্প অ্যাপস্টোর",
        "Sutraz": "সূত্র",
        "A Products Search Engine": "একটি পণ্য অনুসন্ধান ইঞ্জিন",
        "Search Engine for Products": "পণ্যের জন্য অনুসন্ধান ইঞ্জিন",
        "Find All Products Here!": "সমস্ত পণ্য এখানে সন্ধান করুন!",
        "Find Your Product": "আপনার পণ্যটি সন্ধান করুন",
        "There is an App for that": "তার জন্য একটি অ্যাপ রয়েছে",
        "Products": "পণ্য",
        "Review": "পর্যালোচনা",
        "List": "তালিকা",
        "How To": "কিভাবে",
        "FAQ": "প্রায়শই জিজ্ঞাসিত প্রশ্নাবলী",
        "Find your App": "আপনার অ্যাপটি সন্ধান করুন",
        "Login": "লগইন",
        "Log In": "লগইন",
        "Register As User": "ব্যবহারকারী হিসাবে নিবন্ধন করুন",
        "Register As Sutra": "প্রকাশক হিসাবে নিবন্ধন করুন",
        "Register": "নিবন্ধন করুন",
        "Tell Us what You Need": "আমাদের বলুন আপনি কি চান",
        "Needs Fulfillment Store": "ফুলফিলমেন্ট স্টোর দরকার",
        "Shopping by Seeing Video": "চ্যাট উপর কেনাকাটা",
        "Enter your message and press ENTER": "আপনার বার্তা প্রবেশ করুন এবং ENTER টিপুন",
        "Send": "পাঠান",
        "Start Chat": "চ্যাট শুরু করুন",
        "Username": "ব্যবহারকারীর নাম",
        "Password": "পাসওয়ার্ড",
        "Submit": "জমা দিন",
        "Don't have an account sign up now": "এখনই কোনও অ্যাকাউন্ট সাইন আপ করবেন না",
        "Name": "নাম",
        "Email Address": "ইমেল ঠিকানা",
        "Phone Number": "ফোন নম্বর",
        "Confirm Password": "পাসওয়ার্ড নিশ্চিত করুন",
        "Already have an account login now": "ইতিমধ্যে এখনই একটি অ্যাকাউন্ট লগইন করুন",
        "Contacts": " যোগাযোগ",
        "Channels": " চ্যানেল",
        "Users": " ব্যবহারকারীরা",
        "Invites": " আমন্ত্রণ",
        "Profile": " প্রোফাইল",
        "Log Out": " প্রস্থান",
        "Search Here": " এখানে অনুসন্ধান করুন",
        "Image ": " ভাবমূর্তি",
        "Video": " ভিডিও",
        "Advanced Search": " উন্নত অনুসন্ধান",
        "Create Channel": " চ্যানেল তৈরি করুন",
        "Send Invite": " আমন্ত্রণ পাঠান",
        "Channel Name": " চ্যানেলের নাম",
        "Channel Detaiks": " চ্যানেল বিশদ",
        "Read Only ": " শুধুমাত্র পাঠযোগ্য",
        "Only admin can send messages": " কেবল অ্যাডমিন ম্যাসেজ পাঠাতে পারবেন",
        "Creating Channel": " চ্যানেল তৈরি করা হচ্ছে",
        "No Data": " কোন তথ্য নেই",
        "Invite": " আমন্ত্রণ করা",
        "Update": " হালনাগাদ",
        "Close ": " ঘনিষ্ঠ",
        "Buy Now": " এখন কেন",
        "Chat": " চ্যাট",
        "Buy": "কেনা",
        "Research": "গবেষণা",
        "Shopping": "কেনাকাটা",
        "Compare": "তুলনা করা",
        "Conversations": "কথোপকথন", "Settings": "সেটিংস", "Logout": "প্রস্থান", "Account": "হিসাব", "Message": "বার্তা", "Cancel": "বাতিল", "Channel Info": "চ্যানেল তথ্য", "User View": "ব্যবহারকারী দেখুন", "Type a message": "একটি বার্তা টাইপ করুন", "Create": "সৃষ্টি", "Description": "বিবরণ", "Join Channel": "চ্যানেলে যোগদান করুন"
      }
    },

    PA: {
      translations: {
        "Introduction": "ਜਾਣ ਪਛਾਣ",
        "Advantages": "ਲਾਭ",
        "Flexibility to use other packages":
          "ਹੋਰ ਪੈਕੇਜ ਵਰਤਣ ਦੀ ਲਚਕਤਾ",
        "An Alternative AppStore": "ਇੱਕ ਵਿਕਲਪਿਕ ਐਪਸਟੋਰ",
        "Sutraz": "ਸੂਤਰ",
        "A Products Search Engine": "ਇੱਕ ਉਤਪਾਦ ਖੋਜ ਇੰਜਨ",
        "Search Engine for Products": "ਉਤਪਾਦਾਂ ਲਈ ਖੋਜ ਇੰਜਨ",
        "Find All Products Here!": "ਇੱਥੇ ਸਾਰੇ ਉਤਪਾਦ ਲੱਭੋ!",
        "Find Your Product": "ਆਪਣਾ ਉਤਪਾਦ ਲੱਭੋ",
        "There is an App for that": "ਉਸ ਲਈ ਇੱਕ ਐਪ ਹੈ",
        "Products": "ਉਤਪਾਦ",
        "Review": "ਸਮੀਖਿਆ",
        "List": "ਸੂਚੀ",
        "How To": "ਕਿਵੇਂ",
        "FAQ": "ਅਕਸਰ ਪੁੱਛੇ ਜਾਂਦੇ ਪ੍ਰਸ਼ਨ",
        "Find your App": "ਆਪਣੀ ਐਪ ਲੱਭੋ",
        "Login": "ਲਾਗਿਨ",
        "Log In": "ਲਾਗਿਨ",
        "Register As User": "ਉਪਭੋਗਤਾ ਦੇ ਤੌਰ ਤੇ ਰਜਿਸਟਰ ਕਰੋ",
        "Register As Sutra": "ਪ੍ਰਕਾਸ਼ਕ ਵਜੋਂ ਰਜਿਸਟਰ ਕਰੋ",
        "Register": "ਸਾਇਨ ਅਪ",
        "Tell Us what You Need": "ਸਾਨੂੰ ਦੱਸੋ ਕਿ ਤੁਹਾਨੂੰ ਕੀ ਚਾਹੀਦਾ ਹੈ",
        "Needs Fulfillment Store": "ਪੂਰਨ ਭੰਡਾਰ ਦੀ ਜ਼ਰੂਰਤ ਹੈ",
        "Shopping by Seeing Video": "ਗੱਲਬਾਤ ਉੱਤੇ ਖਰੀਦਦਾਰੀ",
        "Enter your message and press ENTER": "ਆਪਣਾ ਸੁਨੇਹਾ ਦਰਜ ਕਰੋ ਅਤੇ ENTER ਦਬਾਓ",
        "Send": "ਭੇਜੋ",
        "Start Chat": "ਗੱਲਬਾਤ ਸ਼ੁਰੂ ਕਰੋ",
        "Username": "ਉਪਯੋਗਕਰਤਾ ਨਾਮ",
        "Password": "ਪਾਸਵਰਡ",
        "Submit": "ਜਮ੍ਹਾ ਕਰੋ",
        "Don't have an account sign up now": "ਹੁਣੇ ਇੱਕ ਅਕਾਉਂਟ ਸਾਈਨ ਅਪ ਨਾ ਕਰੋ",
        "Name": "ਨਾਮ",
        "Email Address": "ਈਮੇਲ ਖਾਤਾ",
        "Phone Number": "ਫੋਨ ਨੰਬਰ",
        "Confirm Password": "ਪਾਸਵਰਡ ਪੱਕਾ ਕਰੋ",
        "Already have an account login now": "ਪਹਿਲਾਂ ਹੀ ਇਕ ਖਾਤਾ ਲੌਗਇਨ ਹੈ",
        "Contacts": " ਸੰਪਰਕ",
        "Channels": " ਚੈਨਲ",
        "Users": " ਉਪਭੋਗਤਾ",
        "Invites": " ਸੱਦਾ ਦਿੰਦਾ ਹੈ",
        "Profile": " ਪ੍ਰੋਫਾਈਲ",
        "Log Out": " ਲਾੱਗ ਆਊਟ, ਬਾਹਰ ਆਉਣਾ",
        "Search Here": " ਇੱਥੇ ਲੱਭੋ",
        "Image ": " ਚਿੱਤਰ",
        "Video": " ਵੀਡੀਓ",
        "Advanced Search": " ਆਧੁਨਿਕ ਖੋਜ",
        "Create Channel": " ਚੈਨਲ ਬਣਾਓ",
        "Send Invite": " ਚੈਨਲ ਵੇਰਵਾ",
        "Channel Name": " ਸਿਰਫ ਪੜ੍ਹਨ ਲਈ",
        "Channel Detaiks": " ਸਿਰਫ ਐਡਮਿਨ ਹੀ ਸੰਦੇਸ਼ ਭੇਜ ਸਕਦਾ ਹੈ",
        "Read Only ": " ਚੈਨਲ ਬਣਾਇਆ ਜਾ ਰਿਹਾ ਹੈ",
        "Only admin can send messages": " ਕੋਈ ਡਾਟਾ ਨਹੀਂ",
        "Creating Channel": " ਸੱਦਾ",
        "No Data": " ਅਪਡੇਟ",
        "Invite": " ਬੰਦ ਕਰੋ",
        "Update": " ਹੁਣੇ ਖਰੀਦੋ",
        "Close ": " ਗੱਲਬਾਤ",
        "Buy Now": " ",
        "Buy": "ਖਰੀਦੋ",
        "Research": "ਖੋਜ",
        "Compare": "ਤੁਲਨਾ ਕਰੋ",
        "Shopping": "ਖਰੀਦਦਾਰੀ",
        "Conversations": "ਗੱਲਬਾਤ", "Settings": "ਸੈਟਿੰਗਜ਼", "Logout": "ਲਾੱਗ ਆਊਟ, ਬਾਹਰ ਆਉਣਾ", "Account": "ਖਾਤਾ", "Message": "ਸੁਨੇਹਾ", "Cancel": "ਰੱਦ ਕਰੋ", "Channel Info": "ਚੈਨਲ ਜਾਣਕਾਰੀ", "User View": "ਉਪਭੋਗਤਾ ਦ੍ਰਿਸ਼", "Type a message": "ਇੱਕ ਸੁਨੇਹਾ ਟਾਈਪ ਕਰੋ", "Create": "ਬਣਾਓ", "Description": "ਵੇਰਵਾ", "Join Channel": "ਚੈਨਲ ਨਾਲ ਜੁੜੋ"
      }
    },

    OR: {
      translations: {
        "Introduction": "ଇନ୍ତ୍ରଦ୍ୱକ୍ସନ",
        "Advantages": "ଅଡ଼ବାଣ୍ଟାଗେସ",
        "Flexibility to use other packages":
          "ଫ୍ଲେକ୍ସଇବିଲିଟି ତୋ ଉସେ ଓଥେର ପାବକଗଇସ",
        "An Alternative AppStore": "ଅଂ ଅଲଟେର୍ନାଟିବେ ଅପ୍ପସ୍ତରେ",
        "Sutraz": "ସୁତରାଶ",
        "A Products Search Engine": "ଏ ପ୍ରଡୁସସ୍ତା ସେଅର୍ଚ୍ଚ ଇଂଗିନେ",
        "Search Engine for Products": "ସେଅର୍ଚ୍ଚ ଇଂଗିନେ ଫୋର ପ୍ରଡୁସସ୍ତା",
        "Find All Products Here!": "ଫାଇଣ୍ଡ ଳ ପ୍ରଡୁସସ୍ତା ହେରେ!",
        "Find Your Product": "ଫାଇଣ୍ଡ ୟୌର ପ୍ରଡୁସତ",
        "There is an App for that": "ଥେର ଇସଃ ଅଂ ଆପ୍ପ ଫୋର ଥାଟ",
        "Products": "ପ୍ରଡୁସସ୍ତା",
        "Review": "ରେଭିଏଵ",
        "List": "ଲିଷ୍ଟ",
        "How To": "ହଉ ତୋ",
        "FAQ": "ଫକ୍ଯୁ",
        "Find your App": "ଫାଇଣ୍ଡ ୟୌର ଆପ୍ପ",
        "Login": "ଲଗିଂ",
        "Log In": "ଲଗିଂ",
        "Register As User": "ରେଜିଷ୍ଟ୍ର ଆସ ଉସେର",
        "Register As Sutra": "ରେଜିଷ୍ଟ୍ର ଆସ ପୂର୍ବଲିଷେର",
        "Register": "ସିଜିନ୍ରୁ ଉପ",
        "Tell Us what You Need": "ଏ ପ୍ରଡୁସସ୍ତା ସେଅର୍ଚ୍ଚ ଇଂଗିନେ",
        "Needs Fulfillment Store": "ରେଜିଷ୍ଟ୍ର ଆସ ପୂର୍ବଲିଷେର",
        "Shopping by Seeing Video": "ଶୋପପିଙ୍ଗ ଅବେର ୩",
        "Enter your message and press ENTER": "ଏଣ୍ଟେର ୟୌର ମେଷସାଙ୍ଗେ ଆଣ୍ଡ ପ୍ରେସ୍ର ଏଣ୍ଟେର",
        "Send": "ସେଣ୍ଡ",
        "Start Chat": "ଷ୍ଟାର୍ଟ ୩",
        "Username": "ଉସେର୍ଣ୍ଣାମେ",
        "Password": "ପାସ୍ରେବୋର୍ଡ",
        "Submit": "ସୁବିମ୍ୱିତ",
        "Don't have an account sign up now": "ଦଣ୍ତ ହବେ ଅଂ ଆସିକୋଡ ସିଜିନ୍ରୁଉପ ନବ",
        "Name": "ନାମେ",
        "Email Address": "ଏମଆଇଲ ୱାଡ଼ଡ୍ରେସ୍ରେ",
        "Phone Number": "ଫୋନେ ନୁଁମ୍ବର",
        "Confirm Password": "କଂଫିର୍ମ ପାସ୍ରେବୋର୍ଡ",
        "Already have an account login now": "ଅଲରାଡି ହବେ ଅଂ ଆସିକୋଡ ଲଗିଂ ନବ",
        "Contacts": " କଂଟକଟସ ",
        "Channels": " ଚାଁନେଲସ ",
        "Users": " ଉସେଶ ",
        "Invites": " ଈଂଭିତେଷୁ ",
        "Profile": " ପ୍ରୋଫାଇଲେ ",
        "Log Out": " ଲୋଗ ଓଟ ",
        "Search Here": " ସେଅର୍ଚ୍ଚ ହେରେ ",
        "Image": " ଈମାଗେ ",
        "Video": " ଭିଡ଼ିଓ ",
        "Advanced Search": " ଆଡ଼ଭାନ୍ସଦ ସେଅର୍ଚ୍ଚ ",
        "Create Channel": " ସେଣ୍ଡ ଈଂବିଟେ ",
        "Send Invite": " ଚାଁନେଲ ନାମେ ",
        "Channel Name": " ଚାଁନେଲ ଡଟାଇଲସ ",
        "Channel Detaiks": " ରେଡ ଅଁଳୟ ",
        "Read Only ": " ଅଁଳୟ ଆଡମିନ ଚାଁ ସେଣ୍ଡ ମେଁସ୍ସାଗେସ ",
        "Only admin can send messages": " ସରାଟିଙ୍ଗ ଚାଁନେଲ ",
        "Creating Channel": " ନ ଡାଟା ",
        "No Data": " ଈଂବିଟେ ",
        "Invite": " ଉପଦଟିଏ ",
        "Update": " ସାଲୋସେ ",
        "Close": " ବୁୟ ନବ ",
        "Buy Now": " ୩ ",
        "Chat": " ",
        "Buy": "",
        "Shopping": ""
      }
    },

    TA: {
      translations: {
        "Introduction": "அறிமுகம்",
        "Advantages": "நன்மைகள்",
        "Flexibility to use other packages":
          "பிற தொகுப்புகளைப் பயன்படுத்துவதற்கான வளைந்து கொடுக்கும் தன்மை",
        "An Alternative AppStore": "ஒரு மாற்று ஆப்ஸ்டோர்",
        "Sutraz": "சூத்திரங்கள்",
        "A Products Search Engine": "ஒரு தயாரிப்புகள் தேடுபொறி",
        "Search Engine for Products": "தயாரிப்புகளுக்கான தேடுபொறி",
        "Find All Products Here!": "அனைத்து தயாரிப்புகளையும் இங்கே கண்டுபிடி!",
        "Find Your Product": "உங்கள் தயாரிப்பைக் கண்டறியவும்",
        "There is an App for that": "அதற்கு ஒரு பயன்பாடு உள்ளது",
        "Products": "தயாரிப்புகள்",
        "Review": "விமர்சனம்",
        "List": "பட்டியல்",
        "How To": "எப்படி",
        "FAQ": "அடிக்கடி கேட்கப்படும் கேள்விகள்",
        "Find your App": "உங்கள் பயன்பாட்டைக் கண்டறியவும்",
        "Login": "உள் நுழை",
        "Log In": "உள் நுழை",
        "Register As User": "பயனராக பதிவுசெய்க",
        "Register As Sutra": "வெளியீட்டாளராக பதிவு செய்யுங்கள்",
        "Register": "பதிவுபெறுக",
        "Tell Us what You Need": "உங்களுக்கு என்ன தேவை என்று எங்களிடம் கூறுங்கள்",
        "Needs Fulfillment Store": "நிறைவேற்றும் கடை தேவை",
        "Shopping by Seeing Video": "அரட்டை மீது ஷாப்பிங்",
        "Enter your message and press ENTER": "உங்கள் செய்தியை உள்ளிட்டு ENTER ஐ அழுத்தவும்",
        "Send": "அனுப்புக",
        "Start Chat": "அரட்டை தொடங்கவும்",
        "Username": "பயனர்பெயர்",
        "Password": "கடவுச்சொல்",
        "Submit": "சமர்ப்பி",
        "Don't have an account sign up now": "இப்போது கணக்கு பதிவு இல்லை",
        "Name": "பெயர்",
        "Email Address": "மின்னஞ்சல் முகவரி",
        "Phone Number": "தொலைபேசி எண்",
        "Confirm Password": "கடவுச்சொல்லை உறுதிப்படுத்தவும்",
        "Already have an account login now": "ஏற்கனவே கணக்கு உள்நுழைவு உள்ளது",
        "Contacts": " தொடர்புகள்",
        "Channels": " சேனல்கள்",
        "Users": " பயனர்கள்",
        "Invites": " அழைப்பிதழ்கள்",
        "Profile": " பதிவு செய்தது",
        "Log Out": " வெளியேறு",
        "Search Here": " இங்கே தேடவும்",
        "Image": " பட",
        "Video": " வீடியோ",
        "Advanced Search": " மேம்பட்ட தேடல்",
        "Create Channel": " சேனலை உருவாக்கவும்",
        "Send Invite": " அழைப்பை அனுப்பு",
        "Channel Name": " சேனல் பெயர்",
        "Channel Detaiks": " சேனல் விவரங்கள்",
        "Read Only ": " படிக்க மட்டும்",
        "Only admin can send messages": " நிர்வாகி மட்டுமே செய்திகளை அனுப்ப முடியும்",
        "Creating Channel": " சேனலை உருவாக்குகிறது",
        "No Data": " தகவல் இல்லை",
        "Invite": " அழைக்கவும்",
        "Update": " புதுப்பிக்கப்பட்டது",
        "Close": " நெருக்கமான",
        "Buy Now": " இப்போது வாங்க",
        "Chat": " அரட்டை",
        "Buy": "அரட்டை",
        "Research": "ஆராய்ச்சி",
        "Shopping": "கடையில் பொருட்கள் வாங்குதல்",
        "Compare": "ஒப்பிடு",
        "Conversations": "உரையாடல்கள்", "Settings": "அமைப்புகள்", "Logout": "வெளியேறு", "Account": "கணக்கு", "Message": "செய்தி", "Cancel": "ரத்து", "Channel Info": "சேனல் தகவல்", "User View": "பயனர் பார்வை", "Type a message": "செய்தியைத் தட்டச்சு செய்க", "Create": "உருவாக்கவும்", "Description": "விளக்கம்", "Join Channel": "சேனலில் சேரவும்"
      }
    },

    TE: {
      translations: {
        "Introduction": "పరిచయం",
        "Advantages": "ప్రయోజనాలు",
        "Flexibility to use other packages":
          "ఇతర ప్యాకేజీలను ఉపయోగించడానికి వశ్యత",
        "An Alternative AppStore": "ప్రత్యామ్నాయ యాప్‌స్టోర్",
        "Sutraz": "సూత్రాలు",
        "A Products Search Engine": "ఉత్పత్తుల శోధన ఇంజిన్",
        "Search Engine for Products": "ఉత్పత్తుల కోసం శోధన ఇంజిన్",
        "Find All Products Here!": "అన్ని ఉత్పత్తులను ఇక్కడ కనుగొనండి!",
        "Find Your Product": "మీ ఉత్పత్తిని కనుగొనండి",
        "There is an App for that": "దాని కోసం ఒక యాప్ ఉంది",
        "Products": "ఉత్పత్తులు",
        "Review": "సమీక్ష",
        "List": "జాబితా",
        "How To": "ఎలా",
        "FAQ": "ఎఫ్ ఎ క్యూ",
        "Find your App": "మీ అనువర్తనాన్ని కనుగొనండి",
        "Login": "లాగిన్",
        "Log In": "లాగిన్",
        "Register As User": "వినియోగదారుగా నమోదు చేయండి",
        "Register As Sutra": "ప్రచురణకర్తగా నమోదు చేయండి",
        "Register": "చేరడం",
        "Tell Us what You Need": "మీకు ఏమి అవసరమో మాకు చెప్పండి",
        "Needs Fulfillment Store": "నెరవేర్పు స్టోర్ అవసరం",
        "Shopping by Seeing Video": "చాట్ ద్వారా షాపింగ్",
        "Enter your message and press ENTER": "మీ సందేశాన్ని నమోదు చేసి, ENTER నొక్కండి",
        "Send": "పంపండి",
        "Start Chat": "చాట్ ప్రారంభించండి",
        "Username": "యూజర్ పేరు",
        "Password": "పాస్వర్డ్",
        "Submit": "సమర్పించండి",
        "Don't have an account sign up now": "ఇప్పుడు ఖాతా సైన్ అప్ లేదు",
        "Name": "పేరు",
        "Email Address": "ఇమెయిల్ చిరునామా",
        "Phone Number": "ఫోను నంబరు",
        "Confirm Password": "పాస్వర్డ్ను నిర్ధారించండి",
        "Buy": "చాట్",

        "Already have an account login now": "ఇప్పటికే ఖాతా లాగిన్ కలిగి ఉంది",
        "Research": "రీసెర్చ్",
        "Shopping": "షాపింగ్",
        "Compare": "సరిపోల్చండి",
        "Conversations": "సంభాషణలు", "Settings": "సెట్టింగులు", "Logout": "లాగ్ అవుట్", "Account": "ఖాతా", "Message": "సందేశం", "Cancel": "రద్దు చేయండి", "Channel Info": "ఛానెల్ సమాచారం", "User View": "వినియోగదారు వీక్షణ", "Type a message": "సందేశాన్ని టైప్ చేయండి", "Create": "సృష్టించు", "Description": "వివరణ", "Join Channel": "ఛానెల్‌లో చేరండి"
      }
    },

    KA: {
      translations: {
        "Introduction": "ಪರಿಚಯ",
        "Advantages": "ಪ್ರಯೋಜನಗಳು",
        "Flexibility to use other packages":
          "ಇತರ ಪ್ಯಾಕೇಜ್‌ಗಳನ್ನು ಬಳಸಲು ಹೊಂದಿಕೊಳ್ಳುವಿಕೆ",
        "An Alternative AppStore": "ಪರ್ಯಾಯ ಆಪ್‌ಸ್ಟೋರ್",
        "Sutraz": "ಸೂತ್ರಗಳು",
        "A Products Search Engine": "ಉತ್ಪನ್ನಗಳ ಹುಡುಕಾಟ ಎಂಜಿನ್",
        "Search Engine for Products": "ಉತ್ಪನ್ನಗಳಿಗಾಗಿ ಸರ್ಚ್ ಎಂಜಿನ್",
        "Find All Products Here!": "ಎಲ್ಲಾ ಉತ್ಪನ್ನಗಳನ್ನು ಇಲ್ಲಿ ಹುಡುಕಿ!",
        "Find Your Product": "ನಿಮ್ಮ ಉತ್ಪನ್ನವನ್ನು ಹುಡುಕಿ",
        "There is an App for that": "ಅದಕ್ಕಾಗಿ ಒಂದು ಅಪ್ಲಿಕೇಶನ್ ಇದೆ",
        "Products": "ಉತ್ಪನ್ನಗಳು",
        "Review": "ವಿಮರ್ಶೆ",
        "List": "ಪಟ್ಟಿ",
        "How To": "ಹೇಗೆ",
        "FAQ": "FAQ",
        "Find your App": "ನಿಮ್ಮ ಅಪ್ಲಿಕೇಶನ್ ಹುಡುಕಿ",
        "Login": "ಲಾಗಿನ್ ಮಾಡಿ",
        "Log In": "ಲಾಗಿನ್ ಮಾಡಿ",
        "Register As User": "ಬಳಕೆದಾರರಾಗಿ ನೋಂದಾಯಿಸಿ",
        "Register As Sutra": "ಪ್ರಕಾಶಕರಾಗಿ ನೋಂದಾಯಿಸಿ",
        "Register": "ಸೈನ್ ಅಪ್ ಮಾಡಿ",
        "Tell Us what You Need": "ನಿಮಗೆ ಬೇಕಾದುದನ್ನು ನಮಗೆ ತಿಳಿಸಿ",
        "Needs Fulfillment Store": "ಪೂರೈಸುವ ಅಂಗಡಿ ಅಗತ್ಯವಿದೆ",
        "Shopping by Seeing Video": "ಚಾಟ್ ಮೂಲಕ ಶಾಪಿಂಗ್",
        "Enter your message and press ENTER": "ನಿಮ್ಮ ಸಂದೇಶವನ್ನು ನಮೂದಿಸಿ ಮತ್ತು ENTER ಒತ್ತಿರಿ",
        "Send": "ಕಳುಹಿಸು",
        "Start Chat": "ಚಾಟ್ ಪ್ರಾರಂಭಿಸಿ",
        "Username": "ಬಳಕೆದಾರ ಹೆಸರು",
        "Password": "ಗುಪ್ತಪದ",
        "Submit": "ಸಲ್ಲಿಸು",
        "Don't have an account sign up now": "ಈಗ ಖಾತೆ ಸೈನ್ ಅಪ್ ಹೊಂದಿಲ್ಲ",
        "Name": "ಹೆಸರು",
        "Email Address": "ಇಮೇಲ್ ವಿಳಾಸ",
        "Phone Number": "ದೂರವಾಣಿ ಸಂಖ್ಯೆ",
        "Confirm Password": "ಪಾಸ್ವರ್ಡ್ ಅನ್ನು ದೃ irm ೀಕರಿಸಿ",
        "Already have an account login now": "ಈಗಾಗಲೇ ಖಾತೆ ಲಾಗಿನ್ ಅನ್ನು ಹೊಂದಿರಿ",
        "Contacts": " ಸಂಪರ್ಕಗಳು",
        "Channels": " ಚಾನಲ್‌ಗಳು",
        "Users": " ಬಳಕೆದಾರರು",
        "Invites": " ಆಹ್ವಾನಿಸುತ್ತದೆ",
        "Profile": " ಪ್ರೊಫೈಲ್",
        "Log Out": " ಲಾಗ್ ಔಟ್",
        "Search Here": " ಇಲ್ಲಿ ಹುಡುಕಿ",
        "Image": " ಚಿತ್ರ",
        "Video": " ವೀಡಿಯೊ",
        "Advanced Search": " ವಿಸ್ತೃತ ಹುಡುಕಾಟ",
        "Create Channel": " ಚಾನಲ್ ರಚಿಸಿ",
        "Send Invite": " ಆಹ್ವಾನವನ್ನು ಕಳುಹಿಸಿ",
        "Channel Name": " ಚಾನಲ್ ಹೆಸರು",
        "Channel Detaiks": " ಚಾನಲ್ ವಿವರಗಳು",
        "Read Only ": " ಓದಲು ಮಾತ್ರ",
        "Only admin can send messages": " ನಿರ್ವಾಹಕರು ಮಾತ್ರ ಸಂದೇಶಗಳನ್ನು ಕಳುಹಿಸಬಹುದು",
        "Creating Channel": " ಚಾನಲ್ ರಚಿಸಲಾಗುತ್ತಿದೆ",
        "No Data": " ಮಾಹಿತಿ ಇಲ್ಲ",
        "Invite": " ಆಹ್ವಾನಿಸಿ",
        "Update": " ನವೀಕರಿಸಿ",
        "Close": " ಮುಚ್ಚಿ",
        "Buy Now": " ಈಗ ಖರೀದಿಸು",
        "Chat": " ಚಾಟ್",
        "Buy": "ಖರೀದಿಸಿ",
        "Research": "ಸಂಶೋಧನೆ",
        "Shopping": "ಶಾಪಿಂಗ್",
        "Compare": "ಹೋಲಿಸಿ",
        "Conversations": "ಸಂಭಾಷಣೆಗಳು", "Settings": "ಸಂಯೋಜನೆಗಳು", "Logout": "ಲಾಗ್ ಔಟ್", "Account": "ಖಾತೆ", "Message": "ಸಂದೇಶ", "Cancel": "ರದ್ದುಮಾಡಿ", "Channel Info": "ಚಾನಲ್ ಮಾಹಿತಿ", "User View": "ಬಳಕೆದಾರರ ನೋಟ", "Type a message": "ಸಂದೇಶವನ್ನು ಟೈಪ್ ಮಾಡಿ", "Create": "ರಚಿಸಿ", "Description": "ವಿವರಣೆ", "Join Channel": "ಚಾನಲ್‌ಗೆ ಸೇರಿ"
      }
    },

    ML: {
      translations: {
        "Introduction": "ആമുഖം",
        "Advantages": "പ്രയോജനങ്ങൾ",
        "Flexibility to use other packages":
          "മറ്റ് പാക്കേജുകൾ ഉപയോഗിക്കാനുള്ള സ ibility കര്യം",
        "An Alternative AppStore": "ഒരു ഇതര ആപ്പ്സ്റ്റോർ",
        "Sutraz": "സൂത്രങ്ങൾ",
        "A Products Search Engine": "ഒരു ഉൽപ്പന്ന തിരയൽ എഞ്ചിൻ",
        "Search Engine for Products": "ഉൽപ്പന്നങ്ങൾക്കായി തിരയൽ എഞ്ചിൻ",
        "Find All Products Here!": "എല്ലാ ഉൽപ്പന്നങ്ങളും ഇവിടെ കണ്ടെത്തുക!",
        "Find Your Product": "നിങ്ങളുടെ ഉൽപ്പന്നം കണ്ടെത്തുക",
        "There is an App for that": "അതിനായി ഒരു ആപ്പ് ഉണ്ട്",
        "Products": "ഉൽപ്പന്നങ്ങൾ",
        "Review": "അവലോകനം",
        "List": "പട്ടിക",
        "How To": "എങ്ങിനെ",
        "FAQ": "പതിവുചോദ്യങ്ങൾ",
        "Find your App": "നിങ്ങളുടെ അപ്ലിക്കേഷൻ കണ്ടെത്തുക",
        "Login": "ലോഗിൻ",
        "Log In": "ലോഗിൻ",
        "Register As User": "ഉപയോക്താവായി രജിസ്റ്റർ ചെയ്യുക",
        "Register As Sutra": "പ്രസാധകനായി രജിസ്റ്റർ ചെയ്യുക",
        "Register": "സൈൻ അപ്പ് ചെയ്യുക",
        "Tell Us what You Need": "നിങ്ങൾക്ക് ആവശ്യമുള്ളത് ഞങ്ങളോട് പറയുക",
        "Needs Fulfillment Store": "പൂർത്തീകരണ സ്റ്റോർ ആവശ്യമാണ്",
        "Shopping by Seeing Video": "ചാറ്റിലൂടെ ഷോപ്പിംഗ്",
        "Enter your message and press ENTER": "നിങ്ങളുടെ സന്ദേശം നൽകി ENTER അമർത്തുക",
        "Send": "അയയ്‌ക്കുക",
        "Start Chat": "ചാറ്റ് ആരംഭിക്കുക",
        "Username": "ഉപയോക്തൃനാമം",
        "Password": "Password",
        "Submit": "സമർപ്പിക്കുക",
        "Don't have an account sign up now": "ഇപ്പോൾ ഒരു അക്കൗണ്ട് സൈൻ അപ്പ് ചെയ്യരുത്",
        "Name": "പേര്",
        "Email Address": "ഈ - മെയില് വിലാസം",
        "Phone Number": "ഫോൺ നമ്പർ",
        "Confirm Password": "പാസ്വേഡ് സ്ഥിരീകരിക്കുക",
        "Already have an account login now": "ഇതിനകം ഒരു അക്കൗണ്ട് ലോഗിൻ ഉണ്ട്",
        "Contacts": " കോൺ‌ടാക്റ്റുകൾ",
        "Channels": " ചാനലുകൾ",
        "Users": " ഉപയോക്താക്കൾ",
        "Invites": " ക്ഷണിക്കുന്നു",
        "Profile": " പ്രൊഫൈൽ",
        "Log Out": " ലോഗ് .ട്ട് ചെയ്യുക",
        "Search Here": " ഇവിടെ തിരയുക",
        "Image": " ചിത്രം",
        "Video": " വീഡിയോ",
        "Advanced Search": " വിപുലമായ തിരയൽ",
        "Create Channel": " ചാനൽ സൃഷ്ടിക്കുക",
        "Send Invite": " ക്ഷണം അയയ്ക്കുക",
        "Channel Name": " ചാനലിന്റെ പേര്",
        "Channel Detaiks": " ചാനൽ വിശദാംശങ്ങൾ",
        "Read Only ": " വായിക്കാൻ മാത്രം",
        "Only admin can send messages": " അഡ്മിന് മാത്രമേ സന്ദേശങ്ങൾ അയയ്ക്കാൻ കഴിയൂ",
        "Creating Channel": " ചാനൽ സൃഷ്‌ടിക്കുന്നു",
        "No Data": " ഡാറ്റാ ഇല്ല",
        "Invite": " ക്ഷണിക്കുക",
        "Update": " അപ്ഡേറ്റ് ചെയ്യുക",
        "Close": " അടയ്‌ക്കുക",
        "Buy Now": " ഇപ്പോൾ വാങ്ങുക",
        "Chat": " ചാറ്റ് ",
        "Buy": "വാങ്ങാൻ",
        "Research": "ഗവേഷണം",
        "Compare": "താരതമ്യം ചെയ്യുക",
        "Conversations": "സംഭാഷണങ്ങൾ", "Settings": "ക്രമീകരണങ്ങൾ", "Logout": "ലോഗ് .ട്ട് ചെയ്യുക", "Account": "അക്കൗണ്ട്", "Message": "സന്ദേശം", "Cancel": "റദ്ദാക്കുക", "Channel Info": "ചാനൽ വിവരം", "User View": "ഉപയോക്തൃ കാഴ്ച", "Type a message": "ഒരു സന്ദേശം ടൈപ്പുചെയ്യുക", "Create": "സൃഷ്ടിക്കാൻ", "Description": "വിവരണം", "Join Channel": "ചാനലിൽ ചേരുക"
      }
    },

    GU: {
      translations: {
        "Introduction": "પરિચય",
        "Advantages": "ફાયદા",
        "Flexibility to use other packages":
          "અન્ય પેકેજોનો ઉપયોગ કરવાની સુગમતા",
        "An Alternative AppStore": "એક વૈકલ્પિક એપ સ્ટોર",
        "Sutraz": "સૂત્રો",
        "A Products Search Engine": "એક પ્રોડક્ટ્સ સર્ચ એન્જિન",
        "Search Engine for Products": "ઉત્પાદનો માટે શોધ એંજિન",
        "Find All Products Here!": "અહીં બધા ઉત્પાદનો શોધો!",
        "Find Your Product": "તમારું ઉત્પાદન શોધો",
        "There is an App for that": "તે માટે એક એપ્લિકેશન છે",
        "Products": "ઉત્પાદનો",
        "Review": "સમીક્ષા",
        "List": "યાદી",
        "How To": "કઈ રીતે",
        "FAQ": "FAQ",
        "Find your App": "તમારી એપ્લિકેશન શોધો",
        "Login": "લ .ગિન",
        "Log In": "લ .ગિન",
        "Register As User": "વપરાશકર્તા તરીકે નોંધણી કરો",
        "Register As Sutra": "પ્રકાશક તરીકે નોંધણી કરો",
        "Register": "સાઇન અપ કરો",
        "Tell Us what You Need": "તમને જેની જરૂર છે તે અમને કહો",
        "Needs Fulfillment Store": "પૂરવણી સ્ટોરની જરૂર છે",
        "Shopping by Seeing Video": "ચેટ પર ખરીદી",
        "Enter your message and press ENTER": "તમારો સંદેશ દાખલ કરો અને ENTER દબાવો",
        "Send": "મોકલો",
        "Start Chat": "ચેટ શરૂ કરો",
        "Username": "વપરાશકર્તા નામ",
        "Password": "પાસવર્ડ",
        "Submit": "સબમિટ કરો",
        "Don't have an account sign up now": "હવે એકાઉન્ટ સાઇન અપ કરશો નહીં",
        "Name": "નામ",
        "Email Address": "ઈ - મેઈલ સરનામું",
        "Phone Number": "ફોન નંબર",
        "Confirm Password": "પાસવર્ડની પુષ્ટિ કરો",
        "Already have an account login now": "પહેલેથી જ એક એકાઉન્ટ લ loginગિન છે",
        "Contacts": " સંપર્કો",
        "Channels": " ચેનલો",
        "Users": " વપરાશકર્તાઓ",
        "Invites": " આમંત્રણ આપે છે",
        "Profile": " પ્રોફાઇલ",
        "Log Out": " લૉગ આઉટ",
        "Search Here": " અહીં શોધો",
        "Image": " છબી",
        "Video": " વિડિઓ",
        "Advanced Search": " અદ્યતન શોધ",
        "Create Channel": " ચેનલ બનાવો",
        "Send Invite": " આમંત્રણ મોકલો",
        "Channel Name": " ચેનલ વિગતો",
        "Channel Detaiks": " ફક્ત વાંચી",
        "Read Only ": " ફક્ત એડમિન સંદેશા મોકલી શકે છે",
        "Only admin can send messages": " ચેનલ બનાવી રહ્યા છે",
        "Creating Channel": " કોઈ ડેટા નથી",
        "No Data": " આમંત્રિત",
        "Invite": " અપડેટ",
        "Update": " બંધ",
        "Close": " હમણાં જ ખરીદો",
        "Buy Now": " ગપસપ ",
        "Buy": "ખરીદો",
        "Chat": " ",
        "Research": "સંશોધન",
        "Shopping": "ખરીદી",
        "Compare": "તુલના",

        "Conversations": "વાતચીત", "Settings": "સેટિંગ્સ", "Logout": "લૉગ આઉટ", "Account": "ખાતું", "Message": "સંદેશ", "Cancel": "રદ કરો", "Channel Info": "ચેનલ માહિતી", "User View": "વપરાશકર્તા દૃશ્ય", "Type a message": "એક સંદેશ લખો", "Create": "બનાવો", "Description": "વર્ણન", "Join Channel": "ચેનલમાં જોડાઓ"
      }
    },

    UR: {
      translations: {
        "Introduction": "تعارف۔",
        "Advantages": "فوائد",
        "Flexibility to use other packages":
          "دوسرے پیکیجوں کو استعمال کرنے میں لچک۔",
        "An Alternative AppStore": "ایک متبادل ایپ اسٹور۔",
        "Sutraz": "ستراس",
        "A Products Search Engine": "ایک پروڈکٹ سرچ انجن۔",
        "Search Engine for Products": "مصنوعات کے لئے تلاش انجن",
        "Find All Products Here!": "یہاں تمام مصنوعات تلاش کریں!",
        "Find Your Product": "اپنی مصنوعات تلاش کریں۔",
        "There is an App for that": "اس کے لئے ایک ایپ موجود ہے۔",
        "Products": "مصنوعات",
        "Review": "جائزہ",
        "List": "فہرست",
        "How To": "کیسے",
        "FAQ": "عمومی سوالات",
        "Find your App": "اپنی ایپ تلاش کریں۔",
        "Login": "میں لاگ ان کریں",
        "Log In": "میں لاگ ان کریں",
        "Register As User": "بطور صارف رجسٹر ہوں۔",
        "Register As Sutra": "بطور ناشر رجسٹر ہوں۔",
        "Register": "سائن اپ کریں۔",
        "Tell Us what You Need": "آپ کی ضرورت ہمیں بتائیں۔",
        "Needs Fulfillment Store": "فلفلمنٹ اسٹور کی ضرورت ہے۔",
        "Shopping by Seeing Video": "چیٹ پر خریداری",
        "Enter your message and press ENTER": "اپنا پیغام درج کریں اور ENTER دبائیں۔",
        "Send": "بھیجیں",
        "Start Chat": "چیٹ شروع کریں",
        "Username": "صارف نام",
        "Password": "پاس ورڈ",
        "Submit": "جمع کرائیں",
        "Don't have an account sign up now": "اب آپ کے پاس اکاؤنٹ سائن اپ نہیں ہے",
        "Name": "نام",
        "Email Address": "ای میل اڈریس",
        "Phone Number": "فون نمبر",
        "Confirm Password": "پاس ورڈ کی تصدیق کریں",
        "Already have an account login now": "پہلے سے ہی اب ایک اکاؤنٹ لاگ ان ہے",
        "Contacts": "رابطے",
        "Channels": "چینل",
        "Users": "صارفین",
        "Invites": "دعوت دیتا ہے",
        "Profile": "پروفائل",
        "Log Out": "لاگ آوٹ",
        "Search Here": "یہاں تلاش کریں",
        "Image": "تصویر",
        "Video": "ویڈیو",
        "Advanced Search": "اعلی درجے کی تلاش",
        "Create Channel": "چینل بنائیں",
        "Send Invite": "دعوت نامہ ارسال کریں",
        "Channel Name": "چینل کا نام",
        "Channel Detaiks": "چینل کی تفصیلات",
        "Read Only ": "صرف پڑھو",
        "Only admin can send messages": "صرف منتظم ہی پیغامات بھیج سکتا ہے",
        "Creating Channel": "چینل بنانا",
        "No Data": "کوئی مواد نہیں",
        "Invite": "مدعو کریں",
        "Update": "اپ ڈیٹ",
        "Close": "بند کریں",
        "Buy Now": "ابھی خریدیں",
        "Chat": "چیٹ",
        "Buy": "خریدنے",
        "Research": "تحقیق",
        "Shopping": "خریداری",
        "Compare": "موازنہ",
        "Conversations": "گفتگو", "Settings": "ترتیبات", "Logout": "لاگ آوٹ", "Account": "کھاتہ", "Message": "پیغام", "Cancel": "منسوخ کریں", "Channel Info": "چینل کی معلومات", "User View": "صارف کا نظارہ", "Type a message": "ایک پیغام ٹائپ کریں", "Create": "بنانا", "Description": "تفصیل", "Join Channel": "چینل میں شامل ہوں"
      }
    },
  },
  fallbackLng: "EN",
  debug: true,
  // have a common namespace used around the full app
  ns: ["translations"],
  defaultNS: "translations",
  keySeparator: false, // we use content as keys
  interpolation: {
    escapeValue: false, // not needed for react!!
    formatSeparator: ","
  },

  react: {
    wait: true
  }
});

export default i18n;