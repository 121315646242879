import { Button, Result } from 'antd';
import React from 'react';

export const AppErrorComponent = (props: { error: string }) => {
    let { error } = props;
    return (<Result
        title="Error"
        subTitle={`${error}`}
        extra={<Button href="/" type="primary">Back Home</Button>}
    />);
}