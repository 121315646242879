import { Button, Card, Empty, List, Spin } from 'antd';
import React, { Component } from 'react';
import { Trans } from 'react-i18next';
import { Link, withRouter } from 'react-router-dom';
import { ProfileType } from '../../controllers/ProfileController';
import StoreController from '../../controllers/StoreController';
import UserController from '../../controllers/UserController';
import AppRoutes from '../../helpers/AppRoutes';
import { authConnector } from '../../stores/auth/connector';
import FollowButton from '../followButton/FollowButton';
import './style.css';

type props = {
    type: string
}

class SuggestionBox extends Component<any, any> {

    state = {
        stores: {},
        isLoading: true,
        type: undefined
    }

    componentDidMount() {
        let { type } = this.props;
        this.loadStores(type);
    }


    loadStores = async (type) => {
        const { showAll } = this.props;
        this.setState({ type })
        let response = await StoreController.getHomeProfiles(type, showAll);
        const { stores } = this.state;
        const newStores = { ...stores };
        if (response.data) {
            newStores[type] = response.data;
        }
        this.setState({
            isLoading: false,
            stores: newStores
        })
    }

    async componentWillUpdate(nextProps, nextState) {
        let { type } = nextProps;
        if (this.props.type != type && this.state.type != type) {
            this.loadStores(type);
        }
    }

    render() {
        const { isLoading, stores } = this.state;
        const { user, updateUser, query = '' } = this.props;
        console.log("Query ", query);
        const profile = { ...user };
        const { following = [] } = profile;
        let { type = ProfileType.STORE } = this.props;
        let list: Array<any> = stores[type];
        if (query) {
            list = list.filter((e) => `${e.username || ""}`.toLowerCase().includes(query.toLowerCase()))
        }
        return (
            <Card title={
                <Button type="link" >
                    <Link to={AppRoutes.SUGGESTED}><span className="download-link"><Trans>Search</Trans> {" "}<Trans>{ProfileType.getPluralType(type)}</Trans> </span>
                    </Link>
                </Button>}>
                {isLoading ? <Spin /> : stores ? <List
                    dataSource={list}
                    renderItem={item => (
                        <div className="suggestion">
                            <img className="profile-pic" src={item.profilePic} onError={(image) => {
                                image.currentTarget.src = UserController.getGravatar(item.username)
                            }} />
                            <div className="suggestion-content">
                                <a href={AppRoutes.USER_PROFILE(item.type, item.username)}>
                                    <span className="title" >{item.username}</span>
                                    <br />
                                    <span className="subtitle">{<Trans>{ProfileType.getType(item.type)}</Trans>}</span>
                                </a>
                            </div>
                            <FollowButton friendId={item.id} />
                        </div>
                    )}
                ></List> : <Empty />}
            </Card>
        );
    }
}

export default authConnector(withRouter(SuggestionBox));