import firebase, { firestore } from "firebase";
import { v4 as uuidV4 } from 'uuid';
import JsonHelper from "../helpers/JsonHelper";
import AppResponse from "../models/AppResponse";
import LanguageController from "./LanguageController";
import ProfileController from "./ProfileController";
import VideoController from "./VideoController";

export default class StoryController {

    static STORIES = "stories";

    static async getDetails(id: string): Promise<AppResponse<{}>> {
        try {
            var snapshot = await firebase.firestore().collection(StoryController.STORIES).doc(id).get();
            var data = snapshot.data();
            console.log("Story", data);
            if (data) {
                let { videoId } = data;
                let video = await VideoController.getDetails(videoId);
                if (video) {
                    let result = { ...video, ...data, };
                    return new AppResponse(result, null);
                }
                else {
                    return new AppResponse(data, null);
                }
            }
            return new AppResponse(null, "Does not exists");

        } catch (error) {
            console.log("Error", error)
            return new AppResponse(null, error.toString);

        }
    }

    static async addStory(post): Promise<AppResponse<boolean>> {
        try {
            let temp = { ...post };
            temp.id = uuidV4();
            temp.recordedAt = new Date().toUTCString();
            let video = { videoUrl: temp.videoUrl, videoThumbnail: temp.videoThumbnail };
            let videoResponse = await VideoController.addVideo(video);
            if (videoResponse.data) {
                temp.videoId = videoResponse.data;
                temp.videoUrl = undefined;
                temp.videoThumbnail = undefined;
                await firestore().collection(this.STORIES).doc(temp.id).set(JsonHelper.cleanJson(temp));
            }
            else {
                return new AppResponse(undefined, videoResponse.error);
            }
            return new AppResponse(true, undefined);
        } catch (error) {
            console.log("Error", error);
            return new AppResponse(undefined, error);
        }
    }


    static async updateStory(sutra): Promise<AppResponse<boolean>> {
        try {
            let temp = { ...sutra };
            var snapshot = firebase.firestore().collection(StoryController.STORIES).doc(temp.id).update(temp);
            return new AppResponse(true, null);
        } catch (error) {
            console.log("Error", error)
            return new AppResponse(null, error["message"] || "Some error occurred");
        }
    }


    static async deleteStory(id, undo?: boolean): Promise<AppResponse<boolean>> {
        try {
            if (undo == undefined) {
                undo = false;
            }
            await this.updateStory({ "isDeleted": !undo, id: id })
            return new AppResponse(true, null);
        } catch (error) {
            console.log("Error", error);
            return new AppResponse(null, error);
        }
    }


    static onStoriesLoaded(onLoaded: (val) => void) {
        try {
            firebase.firestore().collection(this.STORIES).onSnapshot(async (snapshot) => {
                var result = snapshot.docs.map((val) => val.data());
                var profileResult = await ProfileController.parseData(result);
                var videoResult = await VideoController.parseData(profileResult);
                onLoaded(videoResult)
            })

        } catch (error) {
            console.log("Error", error);

        }
    }


    static async getHomeStories(): Promise<AppResponse<Array<any>>> {
        try {
            let languageResponse = await LanguageController.getLanguageByCode(LanguageController.currentLanguage);
            if (languageResponse.error) {
                return new AppResponse(null, "Lang error");
            }
            let language = languageResponse.data;
            let result = [];
            var snapshot = await firebase.firestore().collection(this.STORIES).where("language", "==", language.id).get();
            var subResult = snapshot.docs.map((val) => val.data());
            result.push(...subResult);
            var profileResult = await ProfileController.parseData(result);
            profileResult = profileResult.filter((val) => val.profile);
            var videoResult = await VideoController.parseData(profileResult);
            videoResult = videoResult.filter((e) => !e.isDeleted);
            return new AppResponse(videoResult, null);
        } catch (error) {
            console.log("Error", error);
            return new AppResponse(null, error.toString());

        }
    }

    static async getPrevNextStory(story, count?: number,): Promise<AppResponse<Array<any>>> {
        try {
            var snapshot = await firebase.firestore().collection(this.STORIES);
            var result = [];
            await Promise.all([
                (async () => {
                    let sub = await snapshot.where("recordedAt", "<", story.recordedAt).limit(count || 1).get();
                    console.log("Sub", sub.docs.map((e) => e.data()));
                    result.push(...sub.docs);
                })(),
                (async () => {
                    let sub = await snapshot.where("recordedAt", ">", story.recordedAt).orderBy("recordedAt").limit((count || 1) + 1).get();
                    result.push(...sub.docs);
                })()
            ]);
            result = result.map((val) => val.data());
            result.push(story)
            result.sort((a, b) => a.recordedAt.localeCompare(b.recordedAt));
            var profileResult = await ProfileController.parseData(result);
            profileResult = profileResult.filter((val) => val.profile);
            var videoResult = await VideoController.parseData(profileResult);
            videoResult = videoResult.filter((e) => !e.isDeleted);
            console.log(videoResult);
            return new AppResponse(videoResult, null);
        } catch (error) {
            console.log("Error", error);
            return new AppResponse(null, error.toString());

        }
    }

    static async getStoriesByProfile(profileId): Promise<AppResponse<Array<any>>> {
        try {
            var snapshot = await firebase.firestore().collection(this.STORIES).get();
            var result = snapshot.docs.map((val) => val.data());
            var profileResult = await ProfileController.parseData(result);
            var videoResult = await VideoController.parseData(profileResult);
            videoResult = videoResult.filter((e) => !e.isDeleted);
            videoResult = videoResult.filter((e) => e.profileId == profileId);
            return new AppResponse(videoResult, null);
        } catch (error) {
            console.log("Error", error);
            return new AppResponse(null, error.toString());

        }
    }

    static async getStoriesByHashtag(hashtags: Array<string>): Promise<AppResponse<Array<any>>> {
        try {
            console.log("Hashtags", hashtags);
            let result = await Promise.all(hashtags.map(async (hashtag) => {
                var snapshot = await firebase.firestore().collection(this.STORIES).where("hashtags", "array-contains", hashtag).get();
                var result = snapshot.docs.map((val) => val.data());
                var profileResult = await ProfileController.parseData(result);
                var videoResult = await VideoController.parseData(profileResult);
                videoResult = videoResult.filter((e) => !e.isDeleted);
                return { [hashtag]: videoResult };
            }));
            return new AppResponse(result, null);
        } catch (error) {
            console.log("Error", error);
            return new AppResponse(null, error.toString());

        }
    }
    static async getStoriesBySource(sourceStrings: Array<string>): Promise<AppResponse<{}>> {
        try {
            var map = {};
            let result = await Promise.all(sourceStrings.map(async (sourceString) => {
                console.log("Source String", sourceString)
                var snapshot = await firebase.firestore().collection(this.STORIES).where("sourceStrings", "array-contains", sourceString).get();
                var result = snapshot.docs.map((val) => val.data());
                var profileResult = await ProfileController.parseData(result);
                var videoResult = await VideoController.parseData(profileResult);
                map[sourceString] = videoResult;
            }));
            return new AppResponse(map, null);
        } catch (error) {
            console.log("Error", error);
            return new AppResponse(null, error.toString());

        }
    }

    static async getStoriesByIntent(intents: Array<string>): Promise<AppResponse<{}>> {
        try {
            var map = {};

            let result = await Promise.all(intents.map(async (intent) => {
                var snapshot = await firebase.firestore().collection(this.STORIES).where("intents", "array-contains", intent).get();
                var result = snapshot.docs.map((val) => val.data());
                var profileResult = await ProfileController.parseData(result);
                var videoResult = await VideoController.parseData(profileResult);
                videoResult = videoResult.filter((e) => !e.isDeleted);
                map[intent] = videoResult;
            }));
            return new AppResponse(map, null);
        } catch (error) {
            console.log("Error", error);
            return new AppResponse(null, error.toString());

        }
    }
}

export class StoryType {

    static get SUTRA() {
        return "sutra";
    }
    static get STORE() {
        return "store";
    }
    static get USER() {
        return "user";
    }
}