import { Spin } from 'antd';
import React, { Component } from 'react';
import { isMobile } from 'react-device-detect';
import { Trans } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import ProductController from '../../controllers/ProductController';
import AppConstants from '../../helpers/AppConstants';
import AppRoutes from '../../helpers/AppRoutes';
import StoryGridPage from '../storyPage/StoryGridPage';

class ProductHome extends Component<any, any>{

    state = {
        isLoading: true
    }

    componentDidMount() {
        let { products } = this.props;
        if (products) {
            this.setState({ isLoading: false });
        } else
            this.loadProducts();
    }

    getTitle = () => {
        return AppConstants.Products;
    }

    getDescription = () => {
        return "These are the Products which are part of the Brand Catalogues";
    }

    loadProducts = async () => {
        this.setState({ isLoading: true });
        let productResponse = await ProductController.getProducts();
        this.setState({ isLoading: false });
        let { onProductsLoaded } = this.props;
        if (onProductsLoaded && productResponse.data) {
            onProductsLoaded(productResponse.data);
        }
    }


    render() {
        const { isLoading } = this.state;
        let { products = [] } = this.props;
        return (
            <div>
                {isLoading ? <Spin tip="Loading Products..." /> : <div style={{ padding: isMobile ? "1rem" : "2rem 4rem", }}>
                    <span style={{ fontSize: '3rem', fontWeight: "bold", color: "black" }}><Trans>{this.getTitle()}</Trans> </span>
                    <h2 style={{ fontSize: '1.14rem', fontWeight: 'normal', color: "rgba(0,0,0,0.4)" }}><Trans>{this.getDescription()}</Trans> </h2>
                    <StoryGridPage stories={products} onClick={(val) => {
                        this.props.history.push(AppRoutes.PRODUCT(val.id));
                    }} />
                </div>}
            </div>
        );
    }
}

export default withRouter(ProductHome);