import React, { Component } from 'react';
import './style.css';

type scaffoldProps = {
    appBar: React.ReactNode,
    body: React.ReactNode,
}


const ScaffoldPage = (props: scaffoldProps) => {
    const { appBar, body } = props;
    return (
        <section className="scaffold">
            <section className="scaffold-appbar">
                {appBar}
            </section>
            <section className="scaffold-body">
                {body}
            </section>
        </section>
    );
}

export default ScaffoldPage;