

class JsonHelper {

    static cleanJson(json: {}): {} {
        return JSON.parse(JSON.stringify(json))
    }

}

export default JsonHelper;