import User from "../../models/User";


/**
 * Actions
 */

export const AUTH_LOGIN = 'AUTH_LOGIN'
export const AUTH_UPDATE = 'AUTH_UPDATE'
export const AUTH_LOGOUT = 'AUTH_LOGOUT'

export interface AuthLoginAction {
    type: typeof AUTH_LOGIN,
    payload: any
}

export interface AuthUpdateAction {
    type: typeof AUTH_UPDATE,
    payload: any
}

export interface AuthLogoutAction {
    type: typeof AUTH_LOGOUT,
    meta: {
        timestamp: number
    }
}

export type AuthTypeActions = AuthLoginAction | AuthLogoutAction | AuthUpdateAction;


export interface AuthState {
    user: any
}

