import { Avatar, Button, Drawer, Spin } from 'antd';
import JavascriptTimeAgo from 'javascript-time-ago';
import en from 'javascript-time-ago/locale/en';
import queryString from 'query-string';
import React, { Component, Fragment } from 'react';
import { BrowserView, isMobile, MobileView } from 'react-device-detect';
import { Trans } from 'react-i18next';
import { withRouter } from 'react-router';
import ReactTimeAgo from 'react-time-ago';
import { Button as SemanticButton, Header, Label } from 'semantic-ui-react';
import CateogryController from '../../controllers/CategoryController';
import CommentController from '../../controllers/CommentController';
import HashtagController from '../../controllers/HashtagController';
import LangController from '../../controllers/LangController';
import ProfileController, { ProfileType } from '../../controllers/ProfileController';
import StoryController from '../../controllers/StoryController';
import UserController from '../../controllers/UserController';
import VideoController from '../../controllers/VideoController';
import AppRoutes from '../../helpers/AppRoutes';
import { authConnector } from '../../stores/auth/connector';
import './style.css';

JavascriptTimeAgo.addLocale(en)

class StoryPage extends Component<any, any>  {
  state = {
    isLoading: true,
    story: null,
    error: null,
    stories: [],
    comments: true,
    showVideo: true,
    showComments: false
  }

  componentDidMount() {
    let location = this.props.location;
    if (location.pathname.startsWith(AppRoutes.STORY(""))) {
      let params = queryString.parse(location.search);
      let storyId: string = location.pathname.replace(AppRoutes.STORY(""), "");
      if (storyId) {
        this.loadStoryDetails(storyId);
      } else {
        this.setState({ error: "Does not exists" });
      }
    }
  }

  loadPreviousAndNext = async (story) => {
    // this.setState({stories:[]});
    let storyResponse = await StoryController.getPrevNextStory(story, 1);
    if (storyResponse.data) {
      let data = storyResponse.data;
      this.setState({ stories: data })
    }
  }

  goNext = (story) => {
    let { stories } = this.state;
    let index = stories.findIndex((e) => e.id == story.id);
    if (index != -1 && index + 1 <= stories.length - 1) {
      let next = stories[index + 1];
      this.loadStoryDetails(next.id);
    }

  }

  goPrev = (story) => {
    let { stories } = this.state;
    let index = stories.findIndex((e) => e.id == story.id);
    if (index != -1 && index - 1 >= 0) {
      let prev = stories[index - 1];
      this.loadStoryDetails(prev.id);
    }
  }


  history = {
    goNext: this.goNext,
    goPrev: this.goPrev,
    close: () => {
      this.props.history.goBack();
    }

  };



  loadStoryDetails = async (storyId) => {
    this.props.history.replace(AppRoutes.STORY(storyId));

    const response = await StoryController.getDetails(storyId);
    if (response.data) {
      const story: any = response.data;
      const { profileId = '', language = '', category = '', hashtags = [] } = story || {};
      if (profileId) {
        story.user = await ProfileController.getDetails(profileId) || {};
      }
      story.language = language ? await LangController.getLanguage(language) || {} : {};
      story.category = category ? await CateogryController.getCategory(category) || {} : {};
      story.hashtags = hashtags.length > 0 ? await Promise.all(hashtags.map(hasttag => HashtagController.getHashtag(hasttag))) : [];
      await this.loadPreviousAndNext(story);
      this.setState({
        story,
        isLoading: false
      })
      this.setState({ comments: false, showVideo: false });
      setTimeout(() => {
        this.setState({ comments: true, showVideo: true });
      }, 400)
      return;
    }
    this.setState({
      error: "Story does not exists",
      isLoading: false
    });
  }

  render() {
    const { history } = this.props;
    return (
      <>
        <BrowserView viewClassName="browser-view">
          {this.buildHomeView()}
        </BrowserView>
        <MobileView viewClassName="mobile-view">
          {this.buildMobileView()}
        </MobileView>

      </>
    );
  }

  private videoRef: React.RefObject<VideoPlayer> = React.createRef();

  onMuteToggle = () => {
    if (this.videoRef && this.videoRef.current && this.videoRef.current.hasLoaded) {
      this.videoRef.current.toggleMute();
      setTimeout(() => {
        this.setState({},)
      }, 100)
    }
  }

  buildMobileView() {
    const { isLoading, story, error, stories = [], comments, showVideo, showComments } = this.state;
    const { user = {}, profileId, title, description, category = {}, language = {}, hashtags = [] } = story || {};
    let { name, username, profilePic, type } = user;
    const { history } = this.props;
    if (type == ProfileType.CUSTOMER) {
      type = "consumer";
    }
    var muted = true;
    if (this.videoRef.current) {
      muted = this.videoRef.current.muted
    }
    return (
      <div className="story-container">
        {isLoading ? <Spin /> :
          error ?
            <span>{error}</span> :
            <div className="story-page" >

              <VideoPlayer history={this.history} story={showVideo ? story : {}} ref={this.videoRef} />

              <div className="story-info-container">

                <div className="video-actions">
                  <SemanticButton className="action close" onClick={this.history.close} circular icon="close" />
                  <SemanticButton className="action close" onClick={this.onMuteToggle} circular icon={"volume " + (!muted ? "up" : "off")} />
                </div>
                <div style={{ flexGrow: 1, height: 0 }} />
                {this.buildStoryOptions(story)}
                {this.buildStoryContainer(story)}
                <div className="carousel-actions video-actions">
                  <SemanticButton className="action" onClick={() => this.history.goPrev(story)} circular icon="chevron left" />
                  <SemanticButton className="action" onClick={() => this.history.goNext(story)} circular icon="chevron right" />
                </div>

              </div>

            </div>
        }
        <Drawer placement="bottom" visible={showComments} height={'50vh'} onClose={() => this.setState({ showComments: false })}>
          {!comments ? <div /> : <CommentSection {...this.props} />}
        </Drawer>
      </div>
    );
  }

  buildStoryDetails = (story) => {
    const { user = {} } = story;
    let { name, username, profilePic, type } = user;
    const profileLink = `${AppRoutes.USER_PROFILE(type, username)}`;
    return (
      <div className="story-details">
        <div className="story-details-container">
          <BrowserView>
            <Avatar size={45} src={profilePic} />
          </BrowserView>
          <div className="user-name-container">
            <Header as="h2" className="name">{name}</Header>
            <div className="user-name">{username}</div>
            <i className="user-name">({type == ProfileType.STORE ? "app" : type})</i>
          </div>
          <div className="profile">
            <Button type="primary" onClick={() => { window.location.href = profileLink }}><Trans>View Profile</Trans> </Button>
          </div>
        </div>
        {/* <BrowserView>
          <div className="share-section">
            <SemanticButton circular icon="share alternate" color="teal" />
          </div>
        </BrowserView> */}
      </div>
    );
  }

  shareStory = (story) => {
  }

  buildStoryOptions = (story) => {
    const { user = {} } = story;
    let { name, username, profilePic, type } = user;
    return (
      <MobileView>
        <div className="story-option-list">
          <Avatar className="story-option" size={45} src={profilePic} />
          <SemanticButton className="story-option" circular icon="share alternate" color="teal" />
          <SemanticButton className="story-option" circular icon="like alternate" color="teal" />
          <SemanticButton
            onClick={() => {
              this.setState({ showComments: true })
            }}
            className="story-option" circular icon="comment alternate" color="teal" />
        </div>
      </MobileView>
    );
  }

  buildStoryContainer = (story) => {
    const { history } = this.props;
    const { user = {}, profileId, title, description, category = {}, language = {}, hashtags = [] } = story || {};
    return (
      <div className="story-container">
        <Header as="h2">{title}</Header>
        <div>{description}</div>
        <div className="tag-section">
          {category.name && <Label tag>{category.name}</Label>}
          {language.name && <Label tag>{language.name}</Label>}
        </div>
        <div className="hashtag-section">
          {hashtags.map(hashtag => {
            const { id, name } = hashtag;
            const hashtagLink = `${AppRoutes.HASHTAG(id)}`;
            return (
              <Label key={hashtag.id} onClick={() => { history.push(hashtagLink) }}>#{name}</Label>
            )
          })}
        </div>
      </div>
    );
  }

  buildHomeView() {
    const { isLoading, story, error, stories = [], comments, showVideo } = this.state;
    const { user = {}, profileId, title, description, category = {}, language = {}, hashtags = [] } = story || {};
    let { name, username, profilePic, type } = user;
    if (type == ProfileType.CUSTOMER) {
      type = "consumer";
    }
    const { history } = this.props;
    const profileLink = `${AppRoutes.USER_PROFILE(type, username)}`;
    return (
      <div className="story-container">
        {isLoading ?
          <img className="sutra-logo" src="/logo.jpg" /> :
          error ?
            <span>{error}</span> :
            <div className="story-page" >
              <VideoPlayer history={this.history} story={showVideo ? story : {}} />
              <div className="story-info-container" style={{ flex: 1 }}>
                <div className="story-details">
                  <div className="story-details-container">
                    <Avatar size={45} src={profilePic} />
                    <div className="user-name-container">
                      <Header as="h2" className="name">{name}</Header>
                      <div className="user-name">{username}</div>
                      <i className="user-name">({type == ProfileType.STORE ? "app" : type})</i>
                    </div>
                    <div className="profile">
                      <Button type="primary" onClick={() => { window.location.href = profileLink }}>
                        <Trans>View Profile</Trans>
                      </Button>
                    </div>
                  </div>
                  {/* <div className="share-section">
                    <SemanticButton circular icon="share alternate" color="teal" />
                  </div> */}
                </div>
                <div className="story-container">
                  <Header as="h2">{title}</Header>
                  <div>{description}</div>
                  <div className="tag-section">
                    {category.name && <Label tag>{category.name}</Label>}
                    {language.name && <Label tag>{language.name}</Label>}
                  </div>
                  <div className="hashtag-section">
                    {hashtags.map(hashtag => {
                      const { id, name } = hashtag;
                      const hashtagLink = `${AppRoutes.HASHTAG(id)}`;
                      return (
                        <Label key={hashtag.id} onClick={() => { history.push(hashtagLink) }}>#{name}</Label>
                      )
                    })}
                  </div>
                </div>
                {!comments ? <div /> : <CommentSection {...this.props} />}
              </div>
            </div>
        }
      </div>
    );
  }
}

class CommentSection extends Component<any, any> {
  constructor(props) {
    super(props);
    this.commentRef = React.createRef();
  }

  state = {
    comments: [],
    storyId: '',
  }

  private commentRef: React.RefObject<HTMLInputElement>;

  componentDidMount() {
    let location = this.props.location;
    if (location.pathname.startsWith(AppRoutes.STORY(""))) {
      let params = queryString.parse(location.search);
      let storyId: string = location.pathname.replace(AppRoutes.STORY(""), "");
      if (storyId) {
        this.loadComments(storyId);
        this.setState({
          storyId,
        })
      } else {
        this.setState({ error: "Does not exists" });
      }
    }
  }

  loadComments = async (storyId) => {
    const { user } = this.props;
    if (user) {
      this.commentsUnsubscribe = CommentController.onCommentsLoaded(storyId, async (comments) => {
        const userIds = [];
        comments.forEach(comment => {
          const isUserIdPresent = userIds.some(id => comment.profileId === id);
          if (!isUserIdPresent) {
            userIds.push(comment.profileId);
          }
        });
        const users = await Promise.all(userIds.map(async (userId) => await UserController.getUser(userId) || {}))
        comments.forEach(comment => {
          comment.user = users.find(user => user.id === comment.profileId);
        })
        this.setState({
          comments,
        });
      })
    }
  }

  commentsUnsubscribe;

  componentWillUnmount() {
    if (this.commentsUnsubscribe) {
      this.commentsUnsubscribe();
    }
  }

  sendComment = async (e) => {
    e.preventDefault();
    const { user } = this.props;
    const { storyId } = this.state;
    const content = this.commentRef.current.value
    const comment = {
      profileId: user.id,
      content,
      storyId,
      recordedAt: new Date(),
      isDeleted: false,
      userId: user.id,
    };
    const response = await CommentController.addComment(comment);
    this.commentRef.current.value = "";
    return false;
  }

  buildCommentsList = () => {
    const { user } = this.props;
    if (!user) {
      return <Button type="primary">Login/Register to view</Button>
    }
  }


  render() {
    const { user } = this.props;
    const { id } = user;
    const { comments } = this.state;

    return (
      <Fragment>
        <Header as="h2">Comments</Header>
        {id ?
          <div className="comments-data-container">
            {comments.sort((a, b) => {
              return a.recordedAt.seconds - b.recordedAt.seconds
            }).map(comment => {
              const { id, content, user = {}, recordedAt } = comment;
              const { profilePic, name } = user;
              const { seconds } = recordedAt || {};
              const date = new Date(seconds * 1000);
              return (
                <div key={id} className="comment-container">
                  <div className="profile-pic">
                    <Avatar size={35} src={profilePic} />
                  </div>
                  <div className="comment-section">
                    <Header as="h5" className="profile-name">
                      {name}
                    </Header>
                    <div>{content}</div>
                    <span className="time-ago"><ReactTimeAgo date={date} locale="en" /></span>
                  </div>
                </div>
              )
            })}
          </div> :
          <div className="comments-data-container">
            <Button type="danger" size="large" href={AppRoutes.LOGIN}><Trans>Login to view comments</Trans></Button>
          </div>
        }
        <div className="comment-box">
          {id &&
            <form onSubmit={this.sendComment} className="comment-inputarea">
              <input required={true} ref={this.commentRef} type="text" className="comment-input" placeholder="Write your comment...." />
              <button type="submit" className="comment-send-btn">Send</button>
            </form>
          }
        </div>
      </Fragment>
    )
  }
}

class VideoPlayer extends Component<any, any> {

  state = {
    muted: true
  }

  private isLoaded: boolean = false;

  get hasLoaded() {
    return this.isLoaded;
  }

  constructor(props) {
    super(props);
    this.videoRef = React.createRef();
  }
  componentDidMount() {

  }

  private videoRef: React.RefObject<HTMLVideoElement>;

  play = () => {
    this.videoRef.current.play();
  }

  pause = () => {
    this.videoRef.current.pause();
  }

  seeVideo = () => {
    const { story = {} } = this.props;
    VideoController.seeVideo(story["videoId"]);
  }

  onLoadedVideo = () => {
    this.play();
    this.isLoaded = true;
  }

  toggleMute = () => {
    const { muted } = this.state;
    this.setState({ muted: !muted })
  }

  get muted() {
    const { muted } = this.state;

    return muted;
  }

  render() {
    const { history, story } = this.props;
    const { muted } = this.state;
    var videoUrl = story["videoUrl"];
    return (
      <div className="story-video">
        <div className="video-container">
          {videoUrl ? <video controls={!isMobile} muted={muted} ref={this.videoRef} onLoadedData={this.onLoadedVideo} onLoadStart={this.seeVideo}>
            <source type="video/webm" src={videoUrl} />
            <source type="video/mp4" src={videoUrl} />
            <source type="video/ogg" src={videoUrl} />
          </video> : <div />}
        </div>
        <BrowserView>
          <div className="carousel-actions video-actions">
            <SemanticButton className="action" onClick={() => history.goPrev(story)} circular icon="chevron left" />
            <SemanticButton className="action" onClick={() => history.goNext(story)} circular icon="chevron right" />
          </div>
          <div className="video-actions">
            <SemanticButton className="action" onClick={history.close} circular icon="close" />
            <SemanticButton className="action close" onClick={this.toggleMute} circular icon={"volume " + (!muted ? "up" : "off")} />
          </div>
        </BrowserView>
      </div>
    )
  }
}

export default authConnector(withRouter(StoryPage));