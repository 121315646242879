import { Input, Layout } from 'antd';
import React, { Component } from 'react';
import { ProfileType } from '../../controllers/ProfileController';
import HomeNavigationComponent from '../homeNavigation/HomeNavigationComponent';
import './style.css';
import SuggestionsBox from './SuggestionsBox';

class SugesstedPage extends Component {

    state = {
        selected: ProfileType.getPluralType(ProfileType.SUTRA),
        query: null
    }

    onSelected = (tab) => {
        this.setState({ selected: tab });
    }

    getTabs = () => {
        return [ProfileType.SUTRA, ProfileType.CUSTOMER].map((e) => ProfileType.getPluralType(e))
    }

    getProfileType = () => {
        const { selected, } = this.state;
        let index = this.getTabs().indexOf(selected);
        return [ProfileType.SUTRA, ProfileType.CUSTOMER][index];
    }

    render() {
        const { selected, query } = this.state;
        return (
            <Layout className="suggested-page">
                <HomeNavigationComponent selected={selected} onSelected={this.onSelected} tabs={this.getTabs()} />
                <div style={{ padding: '1rem 2rem' }} className="suggested-page-body">
                    <Input.Search placeholder="Search Here" size="large" onChange={(e) => {
                        this.setState({ query: e.target.value })
                    }} />
                    <div style={{ height: "1rem" }} />
                    <SuggestionsBox type={this.getProfileType()} showAll={true} query={query} />
                </div>
            </Layout>
        );
    }
}

export default SugesstedPage;