import { Drawer, Icon } from 'antd';
import React, { Component } from 'react';
import { BrowserView, MobileView } from 'react-device-detect';
import { withRouter } from 'react-router-dom';
import { authConnector } from '../../stores/auth/connector';
import LangSelection from '../langSelection/LangSelection';
import ProfileAvatarComponent from '../profileAvatarComponent/ProfileAvatarComponent';
import SidePanel from '../sidePanel/SidePanel';
import UploadButton from '../uploadButton/UploadButton';
import './style.css';


class AppHeader extends Component<any, any> {

    state = {
        drawerVisible: false
    }

    render() {
        const { drawerVisible } = this.state;
        return (
            <div>
                <BrowserView>
                    {this.buildHomeView()}
                </BrowserView>
                <MobileView>
                    {this.buildMobileView()}
                </MobileView>
            </div>
        );
    }

    buildMobileView() {
        return <MobileHeader {...this.props} />
    }

    buildHomeView() {
        const { drawerVisible } = this.state;
        const { title, } = this.props;
        return <div>
            <div className="page-header" >
                <div className="page-header-leading">
                    <Icon type="menu" className="drawer-icon" onClick={() => this.setState({ drawerVisible: true })} />
                </div>
                <div className="page-header-title">
                    <a href="/"><img src={'/logo.jpg'} className="logo" alt="logo" /></a>
                </div>
                <div className="page-header-actions">
                    <div style={{ marginLeft: "auto" }} />
                    <UploadButton />
                    <ProfileAvatarComponent />
                    <LangSelection />
                </div>
            </div>
            <Drawer visible={drawerVisible}
                placement={"left"}
                width={window.innerWidth < 480 ? "80%" : "25%"}
                height={"100%"}
                onClose={() => { this.setState({ drawerVisible: false }) }}>
                <SidePanel />
            </Drawer>
        </div>;
    }
}

export const MobileHeader = (props) => {
    const { title, } = props;
    return <div className="mobile-page-header">
        <a href={'/'}><img className="mobile-header-logo" src={'/icon.jpg'} style={{ height: "2em", }} alt="logo" /></a>
        <div className="mobile-header-space">
            <span className="mobile-header-title">
                {title}
            </span>
        </div>
        <ProfileAvatarComponent className="mobile-header-icon" />
    </div>;
}

export default authConnector(withRouter(AppHeader));