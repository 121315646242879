import React, { Component } from 'react';
import WrappedAddProfileForm from '../profileForm/ProfileForm';
import { userInfo } from 'os';
import { ProfileType } from '../../controllers/ProfileController';
import SutrazController from '../../controllers/SutrazController';
import { message } from 'antd';
import { authConnector } from '../../stores/auth/connector';
import { withRouter } from 'react-router-dom';
import AppRoutes from '../../helpers/AppRoutes';
import CustomerController from '../../controllers/CustomerController';
import { IdcardFilled } from '@ant-design/icons';
import StoreController from '../../controllers/StoreController';

class ProfileAddPage extends Component<any, any> {

    state = {
        type: undefined
    }

    componentDidMount() {
        let pathname = this.props.location.pathname;
        if (pathname.startsWith(AppRoutes.ADD_PROFILE(""))) {
            let type = pathname.replace(AppRoutes.ADD_PROFILE(""), "");
            if (ProfileType.allTypes.includes(type)) {
                this.setState({ type });
            }
        }
    }

    addCustomerProfile=async(user)=>{
        this.setState({ isLoading: true });
        let response = await CustomerController.addCustmomer(user);
        console.log(response)
        if (response.data) {
            let newCustomerResponse = await CustomerController.getDetails(response.data);
            if (newCustomerResponse.data) {
                let newCustomer:any={...newCustomerResponse.data};
                let profile={...newCustomer,id:newCustomer.profileId,customerId:newCustomer.id};
                this.props.login(profile);
                window.location.href='/';
            }
            else {
                message.error("Some error occurred");
            }
        }
        else {
            message.error(response.error);
        }
        
    }

    addStoreProfile=async(user)=>{
        console.log("New User ",user);
        this.setState({ isLoading: true });
        let response = await StoreController.addStore(user);
        console.log(response)
        if (response.data) {
            let newStoreResponse = await StoreController.getDetails(response.data);
            if (newStoreResponse.data) {
                let newStore:any={...newStoreResponse.data};
                let profile={...newStore,id:newStore.profileId,storeId:newStore.id};
                this.props.login(profile);
                window.location.href='/';
            }
            else {
                message.error("Some error occurred");
            }
        }
        else {
            message.error(response.error);
        }
        
    }

    addSutraProfile = async (newUser) => {
        this.setState({ isLoading: true });
        let response = await SutrazController.addSutra(newUser)
        if (response.data) {
            let newSutraResponse = await SutrazController.getDetails(response.data);
            if (newSutraResponse.data) {
                let newSutra:any={...newSutraResponse.data};
                let profile={...newSutra,id:newSutra.profileId,sutraId:newSutra.id};
                this.props.login(profile);
                window.location.href='/';
            }
            else {
                message.error("Some error occurred");
            }
        }
        else {
            message.error(response.error);
        }
    }

    render() {
        const { type } = this.state;
        return type ? <div className="login-section">
            <h3 className="login-title">Add {type==ProfileType.STORE?"app":type}</h3>
            <h4 className="login-description">
                Fill your all details<br /> and start exploring.
    </h4>
            <div style={{ height: '1rem ' }} />
            {
                <WrappedAddProfileForm onSubmit={(newUser) => {
                    let {user}=this.props;
                    let profile={id:user.id,userId:user.userId};
                    if (type==ProfileType.SUTRA) {
                        profile={...profile,...newUser,type:ProfileType.SUTRA};
                        this.addSutraProfile(profile);
                    }
                    else if(type==ProfileType.CUSTOMER){
                        profile={...profile,...newUser,type:ProfileType.CUSTOMER};
                        this.addCustomerProfile(profile)
                    }else if(type==ProfileType.STORE){
                        profile={...profile,...newUser,type:ProfileType.STORE};
                        this.addStoreProfile(profile)
                        
                    }
                }} user={{ type: type }} />
            }
        </div>
            : <div />;
    }
}

export default authConnector(withRouter(ProfileAddPage));