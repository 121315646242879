import { Button } from 'antd';
import React from 'react';
import { Trans } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import LoginController from '../../controllers/LoginController';
import ProfileController, { ProfileType } from '../../controllers/ProfileController';
import AppRoutes from '../../helpers/AppRoutes';
import { authConnector } from '../../stores/auth/connector';
import './style.css';

const UploadButton = (props) => {

    let onUploadClick = () => {
        const { user = {} } = props;
        if (LoginController.validateUser(user)) {
            if (ProfileController.isProfileComplete(user)) {
                props.history.push(AppRoutes.UPLOAD_PAGE);
            }
            else {
                props.history.push(AppRoutes.PROFILE_COMPLETE);
            }
        }
        else {
            props.history.push(AppRoutes.LOGIN);

        }
    }
    const { user = {} } = props;

    return (
        <div className="upload-btn">
            <Button block style={{ background: "black" }} type="primary" size="large" className="header-action" onClick={onUploadClick}  ><span className="title"><Trans>{user.type == ProfileType.SUTRA ? "Upload" : "Post your Requirement"}</Trans> </span></Button>
            {/* <MobileView>
                <Fab color="primary" aria-label="add" onClick={onUploadClick}>
                    <Add />
                </Fab>
            </MobileView> */}
        </div>
    );


}

export default withRouter(authConnector(UploadButton));