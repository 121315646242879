import firebase from "firebase";
import LanguageController from "./LanguageController";

export default class HashtagController {
  static HASHTAGS = "hashtags";

  static async getHashtag(id: string): Promise<{}> {
    try {
      var snapshot = await firebase.firestore().collection(HashtagController.HASHTAGS).doc(id).get();
      var data = snapshot.data();
      if (data)
        return data;
      return "Does not exists";
    } catch (error) {
      console.log("Error", error)
      return null;
    }
  }

  static async getHomeHashtags(): Promise<Array<any>> {
    try {
      let languageResponse = await LanguageController.getLanguageByCode(LanguageController.currentLanguage);
      if (languageResponse.error) {
        return [];
      }
      let language = languageResponse.data;
      var snapshot = await firebase.firestore().collection(HashtagController.HASHTAGS).where("language", "==", language.id).get();
      var result = snapshot.docs.map((val) => val.data());
      return result;
    } catch (error) {
      console.log("Error", error);
      return null;
    }
  }
}
