import AppResponse from "../models/AppResponse";
import ProfileController, { ProfileType } from "./ProfileController";
import { v4 as uuidV4 } from 'uuid';
import firebase from 'firebase';
import LanguageController from "./LanguageController";

export default class CustomerController {

    static CUSTOMERS = "customers";

    static async addCustmomer(user): Promise<AppResponse<string>> {
        try {
            let { username, profilePic, userId, language,name,location } = user;
            let profile = { name,username, profilePic, userId, type: ProfileType.CUSTOMER, language,location };
            let profileResponse = await ProfileController.addProfile(profile);
            if (profileResponse.error) {
                return new AppResponse(null, profileResponse.error);
            }
            let profileId = profileResponse.data;
            let newCustomer = { id: uuidV4(), profileId, };
            try {
                var snapshot = firebase.firestore().collection(this.CUSTOMERS).doc(newCustomer.id).set(newCustomer);
            } catch (error) {
                await ProfileController.deleteProfileForever(profileId);

            }
            return new AppResponse(newCustomer.id, null);
        } catch (error) {
            console.log("Error", error)
            return new AppResponse(null, error["message"] || "Some error occurred");
        }
    }

    static async updateCustomer(customer): Promise<AppResponse<boolean>> {
        
        try {
            let { profileId,username, profilePic, userId,  language,id,location } = customer;
            let profile = { username, profilePic, userId, type: ProfileType.CUSTOMER, language,id:profileId,location };
            let profileResponse = await ProfileController.update(profile);
            if (profileResponse.error) {
                return new AppResponse(null, profileResponse.error);
            }
            let temp = {id, profileId, };
            var snapshot = firebase.firestore().collection(CustomerController.CUSTOMERS).doc(temp.id).update(temp);
            return new AppResponse(true, null);
        } catch (error) {
            console.log("Error", error)
            return new AppResponse(null, error["message"] || "Some error occurred");
        }
    }


    static async deleteCustomer(id, undo?: boolean): Promise<AppResponse<boolean>> {
        try {
            if (undo == undefined) {
                undo = false;
            }
            await this.updateCustomer({ "isDeleted": !undo, id: id })
            return new AppResponse(true, null);
        } catch (error) {
            console.log("Error", error);
            return new AppResponse(null, error);
        }
    }


    static onCustomersDetailLoaded(onLoaded: (val) => void) {
        try {
            firebase.firestore().collection(this.CUSTOMERS).onSnapshot(async (snapshot) => {
                var result = snapshot.docs.map((val) => val.data());
                var profileResult = await ProfileController.parseData(result);
                onLoaded(profileResult)
            })

        } catch (error) {
            console.log("Error", error);

        }
    }


    static async getCustomers(): Promise<AppResponse<Array<any>>> {
        try {
            var snapshot = await firebase.firestore().collection(this.CUSTOMERS).get();
            var result = snapshot.docs.map((val) => val.data());
            var newResult = await LanguageController.parseData(result);
            let profileResult = await ProfileController.parseData(newResult);
            return new AppResponse(profileResult, null);

        } catch (error) {
            console.log("Error", error);
            return new AppResponse(null, error.toString());

        }
    }

    static async getDetails(id: string): Promise<AppResponse<{}>> {
        try {
            var snapshot = await firebase.firestore().collection(this.CUSTOMERS).doc(id).get();
            var data = snapshot.data();
            if (data){
            let result=await ProfileController.getDetails(data.profileId);
                if(result){
                    return new AppResponse({...result,...data,},null)
                }
            }
            return new AppResponse(null, "Does not exists");

        } catch (error) {
            console.log("Error", error)
            return new AppResponse(null, error.toString);

        }
    }


}