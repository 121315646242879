import React from 'react';
import { Trans } from 'react-i18next';
import './style.css';

type TabBarProps = {
    tabs: Array<String>,
    onTabChanged: (val: string) => void,
    current: string
}

const TabBar = (props: TabBarProps) => {
    const { tabs, onTabChanged, current } = props;

    const buildTab = (tab, isSelected, onClick) => {
        return (
            <span className={"tab " + (isSelected ? "active" : "")} onClick={() => {
                onClick(tab);
            }}>
                <Trans>{tab}</Trans>
            </span>
        );
    }

    return (
        <div className="tab-bar">
            {
                tabs.map((e) => buildTab(e, current == e, onTabChanged))
            }
        </div>
    );
}

export default TabBar;