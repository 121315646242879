import ProfileController from "../controllers/ProfileController";

export default class FollowHelper{

      static async follow(u1,u2,updateUser){
        const followResponse = await ProfileController.updateFollowing(u1, u2);
        const updatedUser = {...followResponse}
        updateUser(updatedUser);
        const followerResponse = await ProfileController.updateFollower(u2, u1);
        return followerResponse;
      } 

}