import assert from "assert";
import { firestore } from "firebase";
import { v4 } from 'uuid';
import JsonHelper from "../helpers/JsonHelper";
import AppResponse from "../models/AppResponse";
import LanguageController from "./LanguageController";
import ProfileController from "./ProfileController";
import SpecController from "./SpecController";
import VideoController from "./VideoController";

type Product = {
    id,
    title,
    description,
    thumbnail,
    price,
    specs,
    profile,
}

class ProductController {

    static PRODUCTS = "products";

    static async addProduct(product): Promise<AppResponse<boolean>> {
        try {
            let temp = { ...product };
            assert(product.title && product.description && product.profileId)
            temp.id = v4();
            await firestore().collection(this.PRODUCTS).doc(temp.id).set(temp);
            return new AppResponse(true, undefined);
        } catch (error) {
            console.log("Error", error);
            return new AppResponse(undefined, error);
        }
    }


    static async deleteProduct(id, undo?: boolean): Promise<AppResponse<boolean>> {
        try {
            if (undo == undefined) {
                undo = false;
            }
            await firestore().collection(this.PRODUCTS).doc(id).delete();
            return new AppResponse(true, null);
        } catch (error) {
            console.log("Error", error);
            return new AppResponse(null, error);
        }
    }

    static onProductsLoaded(onLoaded: (val) => void) {
        try {
            firestore().collection(this.PRODUCTS).onSnapshot(async (snapshot) => {
                var result = snapshot.docs.map((val) => val.data());
                result = await VideoController.parseData(result);
                let newResult = await LanguageController.parseData(result);
                onLoaded(newResult);
            })

        } catch (error) {
            console.log("Error", error);

        }
    }

    static async getDetails(id): Promise<{}> {
        try {
            var snapshot = await firestore().collection(this.PRODUCTS).doc(id).get();
            let { videoId, profileId } = snapshot.data();
            var data = snapshot.data();
            let { specs = [] } = data || {};
            await Promise.all([
                (async () => {
                    if (videoId) {
                        let response = await VideoController.getDetails(videoId);
                        data.video = response;
                    }
                })(),
                (
                    async () => {
                        if (profileId) {
                            let response = await ProfileController.getDetails(profileId);
                            data.profile = response;
                        }
                    }
                )(),
                (
                    async () => {
                        let specsResult = [];
                        specsResult = await Promise.all(specs.map(async (e) => {
                            var response = await SpecController.getDetails(e);
                            return response;
                        }))
                        specsResult = specsResult.filter((e) => e);
                        data.specs = specsResult;
                    }
                )(),

            ]);

            return { ...data, thumbnail: data.thumbnail || (data.video || {}).videoThumbnail };
        } catch (error) {
            return undefined;
        }
    }

    static async updateProduct(product): Promise<AppResponse<boolean>> {
        try {

            await firestore().collection(this.PRODUCTS).doc(product.id).update(JsonHelper.cleanJson(product));
            return new AppResponse(true, null);
        } catch (error) {
            console.log("Error", error);
            return new AppResponse(null, error);
        }
    }

    static async getProducts(): Promise<AppResponse<Array<any>>> {
        try {
            let languageResponse = await LanguageController.getLanguageByCode(LanguageController.currentLanguage);
            if (languageResponse.error) {
                return new AppResponse([],);
            }
            let language = languageResponse.data;
            var snapshot = await firestore().collection(this.PRODUCTS).where("language", "==", language.id).get();
            var result = snapshot.docs.map((val) => val.data());
            result = await VideoController.parseData(result);
            let newResult = await LanguageController.parseData(result);
            newResult = await ProfileController.parseData(newResult);
            newResult = newResult.map((e) => {
                return { ...e, thumbnail: e.thumbnail || (e.video || {}).videoThumbnail }
            });
            return new AppResponse(newResult, null);
        } catch (error) {
            console.log("Error", error);
            return new AppResponse(null, error.toString());

        }
    }

    static async getProductsByProfile(id): Promise<AppResponse<Array<any>>> {
        try {
            var snapshot = await firestore().collection(this.PRODUCTS).where("profileId", "==", id).get();
            var result = snapshot.docs.map((val) => val.data());
            result = await VideoController.parseData(result);
            result = await LanguageController.parseData(result);
            result = await ProfileController.parseData(result);
            return new AppResponse(result, null);
        } catch (error) {
            console.log("Error", error);
            return new AppResponse(null, error.toString());

        }
    }

    static async getProductsBySpec(specs: Array<string>): Promise<AppResponse<{}>> {
        try {
            var map = {};
            await Promise.all(specs.map(async (id) => {
                var snapshot = await firestore().collection(this.PRODUCTS).where("specs", "array-contains", id).get();
                var result = snapshot.docs.map((val) => val.data());
                result = await VideoController.parseData(result);
                result = await LanguageController.parseData(result);
                result = await ProfileController.parseData(result);
                map[id] = result;
            }));
            return new AppResponse(map, null);
        } catch (error) {
            console.log("Error", error);
            return new AppResponse(null, error.toString());

        }
    }

}

export default ProductController;