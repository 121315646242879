export default class Message {
    id: string;
    chatId: string;
    sender: any;
    recordedAt: Date;
    content: any;
    type: string;
    isSelf: boolean;
    isForward: boolean;
    replyTo: Message;

    constructor(id?, chatId?, sender?, recordedAt?, content?, type?, isSelf?, replyTo?, isForward?) {
        this.id = id;
        this.chatId = chatId;
        this.sender = sender;
        this.recordedAt = recordedAt;
        this.content = content;
        this.type = type;
        this.isSelf = isSelf;
        this.replyTo = replyTo;
        this.isForward = isForward;
    }

    static getText(message) {
        if (!message) {
            return "Start Conversation";
        }
        if (message.type == MessageType.text) {
            return message.content["content"];
        }
        if (message.type == MessageType.image) {
            return "Image";
        }
        if (message.type == MessageType.video) {
            return "Video";
        }
    }
}

export class TextMessage {
    content: string;
    constructor(content) {
        this.content = content;
    }
}

export class ImageMessage {
    url: string;
    constructor(url) {
        this.url = url;
    }
}

export class VideoMessage {
    url: string;
    thumbnail: string;
    constructor(url, thumbnail?) {
        this.url = url;
        this.thumbnail = thumbnail;
    }
}

export class MessageType {

    static get text() { return "text" };

    static get image() { return "image" };

    static get video() { return "video" };

}
