import { firestore } from "firebase";
import uuid from 'uuid';
import AppResponse from "../models/AppResponse";
import ProfileController from "./ProfileController";


class CommentController {

    static COMMENTS = "comments";

    static async addComment(comment): Promise<AppResponse<boolean>> {
        try {
            let temp = { ...comment };
            temp.content = comment.content.trim();
            temp.id = uuid.v4();
            if (!temp.storyId) {
                return new AppResponse(undefined, "Story id must exists");
            }
            if (!temp.profileId) {
                return new AppResponse(undefined, "Profile id must exists");
            }
            await firestore().collection(this.COMMENTS).doc(temp.id).set(temp);
            return new AppResponse(true, undefined);
        } catch (error) {
            console.log("Error", error);
            return new AppResponse(undefined, error);
        }
    }

    static async updateComment(comment): Promise<AppResponse<boolean>> {
        try {

            await firestore().collection(this.COMMENTS).doc(comment.id).update(comment);
            return new AppResponse(true, null);
        } catch (error) {
            console.log("Error", error);
            return new AppResponse(null, error);
        }
    }
    static async deleteComment(id, undo?: boolean): Promise<AppResponse<boolean>> {
        try {
            if (undo == undefined) {
                undo = false;
            }
            await this.updateComment({ "isDeleted": !undo, id: id })
            return new AppResponse(true, null);
        } catch (error) {
            console.log("Error", error);
            return new AppResponse(null, error);
        }
    }
    static onCommentsLoaded(storyId: string, onLoaded: (val) => void): any {
        try {
            return firestore().collection(this.COMMENTS).where("storyId", "==", storyId).onSnapshot(async (snapshot) => {
                var result = snapshot.docs.map((val) => val.data()).filter((a) => !a.isDeleted);
                var newResult = await ProfileController.parseData(result);
                onLoaded(newResult);
            })

        } catch (error) {
            console.log("Error", error);

        }
    }

}

export default CommentController;