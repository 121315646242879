import { Empty } from 'antd';
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import AppRoutes from '../../helpers/AppRoutes';
import SourceStringComponent from '../story/StoryComponent';
import './style.css';

type props = {
  stories: [],
  onLoadMore: (currentCount: number) => void,
  onClick: (story) => void
}



class StoryGridPage extends Component<any & props, any> {

  onLoadMore = () => {
    const { stories = [], onLoadMore } = this.props;
    if (onLoadMore)
      onLoadMore(stories.length);
  }

  render() {
    const { stories = [], onClick } = this.props;
    return (
      !stories ?
        <Empty /> :
        <div style={{ display: "flex", flexWrap: "wrap", justifyContent: "space-between", width: "100%" }} className="story-grid">
          {stories.map((val) =>
            <SourceStringComponent key={val.id} onProfileClick={(val) => {
              this.props.history.push(AppRoutes.USER_PROFILE(val.type, val.username))
            }}
              onClick={onClick ? onClick : (val) => {
                this.props.history.push(AppRoutes.STORY(val.id))
              }}
              sourceString={val}
            />
          )}
        </div>
    );
  }
}

export default withRouter(StoryGridPage);