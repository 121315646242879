import { MenuItem } from '@material-ui/core';
import { Avatar, Button, Dropdown, Icon, Menu, Modal } from 'antd';
import React, { Component } from 'react';
import { BrowserView, MobileView } from 'react-device-detect';
import { Trans } from 'react-i18next';
import { withRouter } from 'react-router';
import LoginController from '../../controllers/LoginController';
import { ProfileType } from '../../controllers/ProfileController';
import UserController from '../../controllers/UserController';
import AppRoutes from '../../helpers/AppRoutes';
import { connectedRouteProps } from '../../props';
import { authConnector } from '../../stores/auth/connector';
import LangSelection from '../langSelection/LangSelection';
import ProfileDialog from '../profileDialog/ProfileDialog';
import './style.css';

class ProfileAvatarComponent extends Component<connectedRouteProps, any>{

    state = {
        accountVisible: false
    }

    logout = async () => {
        Modal.confirm({
            title: <Trans>Do you want to logout?</Trans>, onOk: async () => {
                await LoginController.logOut();
                this.props.logOut();
                this.props.history.push(AppRoutes.LOGIN);
            }
        })
    }

    render() {
        const menu = (
            <Menu>
                <MobileView>
                    <MenuItem key={"-1"}>
                        <LangSelection />
                    </MenuItem>
                </MobileView>
                <Menu.Item key="0" onClick={() => {
                    this.props.history.push(AppRoutes.PROFILE)
                }}>
                    <div className="profile-menu" ><span><Trans>My Profile</Trans></span></div>
                </Menu.Item>
                <Menu.Item key="1" onClick={() => {
                    this.setState({ accountVisible: true })
                }}>
                    <div className="profile-menu" > <span>Switch Profile</span></div>
                </Menu.Item>
                <Menu.Item key="2" onClick={() => {
                    this.props.history.push(AppRoutes.PROFILE_SETTINGS)
                }}>
                    <div className="profile-menu" > <span><Trans>Settings</Trans> </span></div>
                </Menu.Item>
                <Menu.Divider />
                <Menu.Item key="3" onClick={this.logout}>
                    <div className="profile-menu" ><Icon type="poweroff" /> <span><Trans>Logout</Trans> </span></div>
                </Menu.Item>
            </Menu>
        );

        const { accountVisible } = this.state;
        const { user } = this.props;
        const { profile = {} } = user;
        const profilePic = profile.profilePic || user.profilePic || UserController.getGravatar(user.name);
        return (
            <div className="profile-header" >
                {LoginController.validateUser(user) ?
                    <Dropdown overlay={menu}  >
                        <Avatar style={{ cursor: "pointer", marginLeft: "auto" }} src={profilePic} size={40} />
                    </Dropdown> : <>
                        <MobileView>
                            <Avatar style={{ cursor: "pointer", marginLeft: "auto" }} icon={<Icon type="user" onClick={() => this.props.history.push(AppRoutes.REGISTER_PROFILE(ProfileType.STORE))} />} size={40} />
                        </MobileView>
                        <BrowserView viewClassName="login-action-list">
                            {/* <Button className="login-action-btn" type="default" style={{ borderColor: "#1890ff", color: '#1890ff' }} onClick={() => this.props.history.push(AppRoutes.LOGIN)} size="large"><span style={{ textAlign: "center" }}>
                                Login</span></Button>
                            <div style={{ width: '1rem' }} /> */}
                            <Button className="login-action-btn" type="primary" onClick={() => this.props.history.push(AppRoutes.REGISTER)} size="large"><span style={{ textAlign: "center" }}>
                                <Trans>Signup</Trans></span></Button>
                        </BrowserView>
                    </>}
                <ProfileDialog visible={accountVisible} onClose={() => {
                    this.setState({ accountVisible: false })
                }} />
            </div>
        );
    }
}

export default authConnector(withRouter(ProfileAvatarComponent));