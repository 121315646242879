import React, { Component } from 'react';
import { Icon, Input, Button, Divider } from 'antd';
import './style.css'
import { message } from 'antd';
import i18n from '../../../i18n';
import AppInput from '../../appInput/AppInput';
import AppConstants from '../../../helpers/AppConstants';



type ChatInputProps = {
    onSubmit: (val: string) => void,
    onChange?: (val: string) => void,
}

class ChatInput extends Component<ChatInputProps, any> {

    state = {
        inputValue: ''
    };
    œ
    updateInputValue = (evt) => {
        this.props.onChange(evt.target.value);
        this.setState({
            inputValue: evt.target.value
        });

    }

    onSubmit = () => {

        const { inputValue } = this.state;
        if (!inputValue) {
            message.error('Can not send empty message');
            return;
        }
        this.props.onSubmit(inputValue);
        this.props.onChange('');
        this.setState({
            inputValue: ''
        })

    }

    onSmileyClick = (e) => {
        const { inputValue } = this.state;
        this.setState({ inputValue: inputValue + "😃" })
    }

    render() {
        const { inputValue } = this.state;
        return (
            <div className="chat-container" >
                <Icon onClick={(e) => this.onSmileyClick(e)} type="smile" className="chat-container-smile" />
                <AppInput className="chat-container-input" placeholder={AppConstants.TYPE_A_MESSAGE} onSubmit={(val) => { this.onSubmit(); }} value={inputValue} onChange={this.updateInputValue} />
                <div className="chat-container-action" onClick={this.onSubmit}>
                    <img src="https://img.icons8.com/material-rounded/24/ffffff/filled-sent.png" />
                </div>
            </div>
        );
    }
}

export default ChatInput;