import { Card, Spin } from 'antd';
import React, { Component } from 'react';
import { Trans } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import { Label } from 'semantic-ui-react';
import IntentsController from '../../controllers/IntentsController';
import { ProfileType } from '../../controllers/ProfileController';
import SourceStringController from '../../controllers/SourceStringController';
import AppConstants from '../../helpers/AppConstants';
import AppRoutes from '../../helpers/AppRoutes';
import './style.css';

class DiscoverCard extends Component<any, any> {

    state = {
        intents: [],
        sourceStrings: [],
        isSourceStringLoading: true,
        isIntentsLoading: true,
    }

    componentDidMount() {
        this.loadData();
    }

    loadData = async () => {
        await Promise.all([
            (async () => {
                this.setState({ isIntentsLoading: true });
                var response = await IntentsController.getIntents();
                this.setState({ isIntentsLoading: false, intents: response });
            })(),
            (async () => {
                this.setState({ isSourceStringLoading: true });
                var response = await SourceStringController.getSourceStrings();
                if (response && response.length > 0) {
                    const { onSelected, } = this.props;
                    if (onSelected)
                        onSelected(response[0].id);
                }
                this.setState({ isSourceStringLoading: false, sourceStrings: response });

            })(),
        ]);
    }

    render() {
        const { profileType = ProfileType.CUSTOMER } = this.props;
        return (
            <Card title={<a href={AppRoutes.DISCOVER} style={{ color: "black", fontWeight: "bold" }}><Trans>Discover</Trans> <Trans>{ProfileType.getPluralType(profileType)}</Trans></a>} >
                <div className="discover-card">
                    <section className="discover-body">
                        {profileType == ProfileType.CUSTOMER ? this.buildIntents() : <section />}
                        {profileType == ProfileType.SUTRA ? this.buildSourceStrings() : <section />}
                    </section>
                </div>
            </Card>
        );
    }

    buildSourceStrings() {
        const { onSelected, selected } = this.props;
        const { isSourceStringLoading = false, sourceStrings = [] } = this.state;
        return isSourceStringLoading ? <Spin /> : sourceStrings.map((hashtag, index) => {
            const { id, name } = hashtag;
            const hashtagLink = `${AppRoutes.DISCOVER_ITEM(id, AppConstants.SOURCE_STRING)}`;
            return (
                <Label key={id} style={{ margin: '5px', cursor: 'pointer', backgroundColor: selected == hashtag.id ? "#FFFF9E" : undefined }} onClick={onSelected ? () => { onSelected(hashtag.id) } : undefined}># {hashtag.name}</Label>
            )
        })
    }

    buildIntents() {
        const { history } = this.props;
        const { isIntentsLoading = false, intents = [] } = this.state;
        return isIntentsLoading ? <Spin /> : intents.map((hashtag, index) => {
            const { id, name } = hashtag;
            const hashtagLink = `${AppRoutes.DISCOVER_ITEM(id, AppConstants.INTENT)}`;
            return (
                <Label key={id} style={{ margin: '5px', cursor: 'pointer' }} onClick={() => { history.push(hashtagLink) }}># {hashtag.name}</Label>
            )
        })
    }
}

export default withRouter(DiscoverCard);