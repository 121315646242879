
import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { authConnector } from '../../stores/auth/connector';
import HomePage from '../home/HomePage';
import './style.css';


class BrowserApp extends Component<any, any> {

    render() {
        return (
            <div className="home">
                <HomePage />
            </div>
        );
    }
}


export default authConnector(withRouter(BrowserApp));