import { Button, Dropdown, Icon, Menu, Modal, PageHeader, Spin } from 'antd';
import React, { Component } from 'react';
import ChatController from '../../../controllers/ChatController';
import MessageController from '../../../controllers/MessageController';
import ProfileHelper from '../../../helpers/ProfileHelper';
import Chat from '../../../models/chat/Chat';
import Message, { ImageMessage, MessageType, TextMessage, VideoMessage } from '../../../models/chat/Message';
import { ContentType } from '../../../models/upload';
import { authConnector } from '../../../stores/auth/connector';
import { AppErrorComponent } from '../../appError/AppErrorComponent';
import ChatSearchModal from '../../chatPage/ChatSearchModal';
import UploadPanel from '../../uploadPanel/UploadPanel';
import ChatInput from '../Input/ChatInput';
import MessageComponent from '../message/MessageCompoent';
import ReplyChatComponent from '../message/ReplyChatComponent';
import './style.css';



class ChatDashboard extends Component<any, any> {

    state = {
        isLoading: true,
        chat: undefined,
        isLoadingMessages: false,
        messages: [],
        uploadVisble: false,
        uploadType: null,
        error: undefined,
        searching: false,
        replyTo: undefined
    }

    messageRef;
    messageContainerRef = React.createRef();

    componentDidMount() {
        let { chatId, chat, } = this.props;

        if (chatId) {
            this.loadChatDetails(chatId);
            this.loadMessages(chatId);
        }
        else if (chat) {
            this.setState({ isLoading: false, chat })
            this.loadMessages(chat.id);
        }
    }

    loadChatDetails = async (chatId) => {
        this.setState({
            isLoading: true
        })
        let response = await ChatController.getChat(chatId);
        this.setState({
            isLoading: false
        })
        if (response.data) {
            this.setState({ chat: response.data });
        }
        else {
            this.setState({ error: response.error })
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot,) {
        let { chat = {}, } = this.props;
        if (chat.id != (prevProps.chat || {}).id) {
            this.setState({ isLoading: false, chat })
            this.loadMessages(chat.id);
        }
    }

    loadMessages = async (chatId) => {
        this.setState({
            isLoadingMessages: true
        })
        var response = await MessageController.getMessages(chatId);
        this.setState({ isLoadingMessages: false, messages: response })
        this.messageRef = MessageController.onMessage(chatId, this.onMessage)
    }

    onMessage = async (message) => {
        const { messages } = this.state;
        let index = messages.findIndex((val) => message.id == val.id);
        let newMessages = [...messages];
        if (index != -1) {
            newMessages[index] = message;
        }
        else {
            newMessages = [...newMessages, message];
        }
        this.setState({ messages: newMessages });
    }

    componentWillUnmount() {
        if (this.messageRef) {
            this.messageRef();
        }
    }

    // Messages List Ref
    messageContainer;

    onTextSubmit = (text) => {
        let textMessage = new TextMessage(text);
        let message = new Message();
        message.content = textMessage;
        message.type = MessageType.text;
        this.sendMessage(message);
    }


    sendMessage = async (message) => {
        const { chat, replyTo } = this.state;
        if (chat) {
            message.chatId = chat.id;
            message.replyTo = replyTo;
            this.setState({ replyTo: null })
            var response = await MessageController.sendPersonalMessage(message);
            if (response.error) {
                window.alert(`Response ${response.error}`);
            }
        }
        else {
            window.alert(`Chat not found`);
        }
    }

    onTextChange = (message) => {
    }

    onFileUpload = (contentType) => {
        this.setState({
            uploadVisble: true,
            uploadType: contentType
        })
    }

    onFileUploaded = (url, type) => {
        this.setState({
            uploadVisble: false,
        })
        let message = new Message();
        if (type == ContentType.Image) {
            message.content = new ImageMessage(url);
            message.type = MessageType.image;
            this.sendMessage(message);
        }
        if (type == ContentType.Video) {
            message.content = new VideoMessage(url);
            message.type = MessageType.video;
            this.sendMessage(message);
        }
    }

    onFileUploadCancel = () => {
        this.setState({
            uploadVisble: false,
        })
    }

    onSearch = () => {
        this.setState({ searching: true })
    }

    onSearchCancel = () => {
        this.setState({ searching: false });
    }

    onReply = (message) => {
        this.setState({ replyTo: message })
    }

    onReplyCancel = () => {
        this.setState({ replyTo: null })
    }

    render() {

        const { isLoading, chat = {}, isLoadingMessages, messages, uploadType, uploadVisble, error, searching, replyTo } = this.state;
        if (isLoading) {
            return (
                <Spin tip="Loading " />
            );
        }
        if (error) {
            return (
                <AppErrorComponent error={error} />
            );
        }

        if (messages) {
            messages.sort((a, b) => Date.parse(a.recordedAt) - Date.parse(b.recordedAt));
        }

        const uploadMenu = (<Menu>
            <Menu.Item>
                <Button type="dashed" icon="file-image" onClick={() => this.onFileUpload(ContentType.Image)}>
                    Image
                </Button>
            </Menu.Item>
            <Menu.Item>
                <Button type="dashed" icon="video-camera" onClick={() => this.onFileUpload(ContentType.Video)}>
                    Video
                </Button>
            </Menu.Item>
        </Menu>);
        let { chatOptions } = this.props;
        return (
            <div className="chat-dashboard">
                {!isLoading ? <div className="chat-dashboard-header">
                    <PageHeader
                        className="chat-dashboard-header"
                        avatar={{ src: Chat.getAvatar(chat) }}
                        title={<span className={"chat-dashboard-header-title"} onClick={chat.friend ? () => ProfileHelper.goToProfile(chat.friend) : undefined}>{Chat.getTitle(chat)}</span>}
                        extra={chatOptions ? chatOptions : [
                            <Button key="1" shape="circle" onClick={this.onSearch}>
                                <Icon type="search" />
                            </Button>,
                            <div style={{ display: "inline-block", margin: "0pt 4pt" }} />,
                            <Dropdown key="more" overlay={uploadMenu}>
                                <Button shape="circle">
                                    <Icon
                                        type="file-add"
                                    />
                                </Button>
                            </Dropdown>,
                        ]}
                    ></PageHeader>
                </div> : <div />}
                <div className="chat-dashboard-container" >
                    {isLoadingMessages ? <Spin tip="Loading Messages" /> : <div ref={(el) => this.messageContainer = el}>
                        {messages.map((message) => <MessageComponent
                            // onForward={this.onMessageForward}
                            // onSeen={(chat) => this.onMessageSeen(chat)}
                            // selected={isForwarding && this.isChatSelected(chat)}
                            // canSelect={isForwarding}
                            // onSelected={(check: boolean, chat) => {
                            //     if (check)
                            //         this.selectChat(chat)
                            //     else {
                            //         this.disSelectChat(chat);
                            //     }
                            // }}
                            onReply={this.onReply}
                            message={message}
                        />)}
                    </div>}
                </div>
                <div className="chat-dashboard-input">
                    {
                        replyTo ? <ReplyChatComponent message={replyTo} onCancel={this.onReplyCancel} /> : <div />
                    }
                    {
                        <ChatInput onSubmit={this.onTextSubmit} onChange={this.onTextChange} />
                    }
                </div>
                <Modal visible={uploadVisble} onCancel={this.onFileUploadCancel} footer={[]} destroyOnClose={true}>
                    <UploadPanel uploadType={uploadType} onFileUpload={this.onFileUploaded} />
                </Modal>
                <Modal visible={searching} footer={[]} onCancel={this.onSearchCancel}>
                    <ChatSearchModal chatId={chat.id} />
                </Modal>
            </div >
        );
    }
}

export default authConnector(ChatDashboard);