import React from 'react';
import './style.css';

export default function (props) {
  const { sourceString, onClick, onProfileClick, customClass } = props;
  const { videoThumbnail, video = {}, profile = {}, thumbnail } = sourceString;
  const storyClass = `source-string ${customClass}`;

  return (
    <div className={storyClass}>
      <img alt="thumbnail" className="image" src={video.videoThumbnail || videoThumbnail || thumbnail} />
      <div onClick={() => { onClick(sourceString) }} className="black-layer" />
      <div className="source-string-actions-container">
        <span className="play-view"><img className="play-icon" src="/images/eye.png" /> {video.viewCount || 0}</span>
        <div onClick={() => { onClick(sourceString) }} className="play-expand" />
        {sourceString.profile ? <img alt="profile" src={sourceString.profile.profilePic} onClick={() => { onProfileClick(sourceString.profile) }} className="user-icon" /> : <span />}
        <div className="play-button" onClick={() => { onProfileClick(sourceString.profile) }}>
          <span className="play-view" style={{ color: "black", fontWeight: "normal" }} >{profile.username}</span>
        </div>
      </div>
    </div>
  );
}
