import { firestore } from 'firebase';
import uuid from "uuid";
import JsonHelper from '../helpers/JsonHelper';
import AppResponse from '../models/AppResponse';
import Chat, { ChatType } from '../models/chat/Chat';
import LoginController from './LoginController';
import MessageController from './MessageController';
import ProfileController from './ProfileController';

class ChatController {

    static get chats() { return "chats" };

    static get chatCollection(): firestore.CollectionReference {
        return firestore().collection(this.chats);
    }

    static async startChatWithUsername(username: string): Promise<AppResponse<Chat>> {
        try {
            let snapshpt = await ProfileController.profileCollection.where("username", "==", username).get();
            if (snapshpt.docs.length > 0) {
                let userData = snapshpt.docs[0].data();
                let userId = userData["id"];
                let chatIdResponse = await this.startPersonalChat(userId);
                if (chatIdResponse.data) {
                    return await this.getChat(chatIdResponse.data);
                }
                return new AppResponse(null, chatIdResponse.error);
            }
            else {
                return new AppResponse(null, "User not found");
            }
        } catch (error) {
            return new AppResponse(null, `${error}`);
        }
    }

    static async getChats(): Promise<AppResponse<Array<Chat>>> {
        try {
            let id = await LoginController.getCurrentProfile();
            if (!id) {
                return new AppResponse(null, "Not exists");
            }
            var snapshot = await this.chatCollection.where("users", "array-contains", id).get();
            let docs = snapshot.docs.map((e) => e.data());
            let result = await Promise.all(docs.map(async (e) => await this.parseJson(e)));
            result = result.filter((e) => e);
            return new AppResponse(result, null);

        } catch (error) {
            console.log("Error", error);
            return new AppResponse(null, `${error}`);
        }
    }

    static async parseJson(json): Promise<Chat> {
        let chat = { ...json };
        let id = await LoginController.getCurrentProfile();
        if (!(chat.users || []).includes(id)) {
            return null;
        }
        let messageSnapshot = await MessageController.messageQuery(chat.id).orderBy("counter", "desc").limit(1).get();
        if (messageSnapshot.docs.length > 0) {
            let message = { ...messageSnapshot.docs[0].data() };
            chat.lastMsg = message;
        }
        let users = chat.users || [];
        if (chat.type == ChatType.private) {
            let newUsers = users.filter((e) => e != id);
            if (newUsers.length == 1) {
                let friendId = newUsers[0];
                let friend = await ProfileController.getDetails(friendId);
                if (!friend) {
                    return null;
                }
                if (!friend.username) {
                    friend.username = "Anonymous"
                }
                chat.friend = friend;
            }
        }
        return chat;
    }

    static async startPersonalChat(friendId: string): Promise<AppResponse<string>> {
        try {
            let userId = await LoginController.getCurrentProfile();
            if (userId) {
                if (userId == friendId) {
                    return new AppResponse(null, "Cannot start chat with youself");
                }
                let users = [userId, friendId];
                return await this.startChat(users);
            }
            else {
                return new AppResponse(null, "Not logged in");
            }
        } catch (error) {
            return new AppResponse(null, `${error}`);
        }
    }

    static async startChat(users: Array<string>): Promise<AppResponse<string>> {
        try {
            if (!users || users.length < 2) {
                return new AppResponse(null, "Not possible");
            }
            let chat = new Chat();
            chat.type = ChatType.private;
            // if (chat.users.length == 0) {
            //     return new AppResponse(null, "Empty list");
            // }
            // if (chat.users && chat.users.length > 1) {
            //     chat.type = ChatType.group;
            // }
            let id = uuid.v4();
            let recordedAt = new Date();
            chat.id = id;
            chat.recordedAt = recordedAt;
            var json = this.toJson(chat);
            users.sort()
            json["users"] = users;
            // Check for existing
            var snapshot = await this.chatCollection.where("users", "==", json["users"]).where("type", "==", ChatType.private).get();
            if (snapshot.docs.length > 0) {
                return new AppResponse(this.fromJson(snapshot.docs[0].data()).id,);
            }
            await this.chatCollection.doc(chat.id).set(json);
            return new AppResponse(id,);

        } catch (error) {
            return new AppResponse(null, `${error}`);
        }
    }

    static async getChat(id: string): Promise<AppResponse<Chat>> {
        try {
            console.log("ID", id);
            var snapshot = await this.chatCollection.doc(id).get();
            if (snapshot.exists) {
                var data = snapshot.data();
                var chat = await this.parseJson(data);
                if (!chat) {
                    return new AppResponse(null, "Chat not exists");
                }
                return new AppResponse(chat, null);
            }
            else {
                return new AppResponse(null, "Chat does not exists");
            }
        } catch (error) {
            console.log("Error", error);
            return new AppResponse(null, `${error}`);
        }
    }


    static toJson(chat: Chat) {
        var json = { ...chat };
        json["users"] = (chat.users || []).map((e) => e.id);
        json["lastMsg"] = undefined;
        return JsonHelper.cleanJson(json);
    }

    static fromJson(json) {
        var chat = new Chat();
        chat = { ...json };
        return chat;
    }


}

export default ChatController;