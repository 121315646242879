import Firebase from '../Firebase';
import firebase from 'firebase';
import uuid from 'uuid';
import { debugPort } from 'process';


class UploadController {


    static uploadFile(file: File,contentType:string): firebase.storage.UploadTask {
        const storage = firebase.storage();
        return this.uploadFileById(file,uuid.v4(),contentType);
    }

    static uploadFileById(file: File, id: string,contentType:string): firebase.storage.UploadTask {
        const storage = firebase.storage();
        let name = file.type;
        return storage.ref(contentType+"/"+(id || uuid.v4())).put(file);
    }

    static async crawlFiles():Promise<any>{
        // const storage = firebase.storage();
        // let listResult=await storage.ref('').listAll();
        // let commands=[];
        // await Promise.all(listResult.items.map(async(item)=>{
        //     var metadata=await item.getMetadata();
        //     if(metadata){
        //         if(metadata["contentType"]=="video/mp4"){
        //             let oldRef=storage.ref(metadata["name"]).toString();
        //             let ref=storage.ref('/videos').child(metadata["name"]).toString();
        //             commands.push(`gsutil mv ${oldRef} ${ref}`);
        //         }
        //     }
        // }))
        // console.log("Commands ",commands);
    }
}

export default UploadController;