
import { load } from '@fingerprintjs/fingerprintjs';

export default class DeviceController {
    static async getUniqueId(): Promise<string> {
        try {
            var fp = await load();
            var result = await fp.get();
            return result.visitorId;
        } catch (error) {
            console.log("Error", error)
            return null;
        }
    }
}