import { Button, Tag } from 'antd';
import React from 'react';
import AppRoutes from '../../helpers/AppRoutes';
import Chat from '../../models/chat/Chat';
import FollowButton from '../followButton/FollowButton';

type ChatDetailProps = {
    chat: Chat
}

export const ChatDetailComponent = (props: ChatDetailProps) => {
    const { chat } = props;
    if (chat.friend) {
        return <ChatUserDetailComponent user={chat.friend} />
    }
    return <ChatGroupDetailComponent chat={chat} />
}

export const ChatUserDetailComponent = (props: { user: any }) => {
    const { user } = props;
    return (
        <div className="chat-details">
            <section className="chat-details-header chat-header">Info</section>
            <div className="chat-details-body">
                <img src={user.profilePic} className="chat-details-avatar" />
                <h5 className="chat-details-title">{user.username}  <Tag color={"green"}>{user.type}</Tag></h5>
                <h6 className="chat-details-subtitle">{user.name}</h6>
                <FollowButton friendId={user.id} />
                <br />
                <Button onClick={() => { window.location.href = AppRoutes.USER_PROFILE(user.type, user.username) }} type="link">View Profile</Button>
            </div>
        </div>
    );
}

export const ChatGroupDetailComponent = (props: { chat: Chat }) => {
    const { chat } = props;
    return (
        <div>
            <img src={chat.groupAvatar} className="chat-details-avatar" />
            <h5 className="chat-details-title">{chat.title}</h5>
            <h6 className="chat-details-subtitle">{(chat.users || []).length} members</h6>
        </div>
    );
}


