import LoginController from '../../controllers/LoginController';
import { AuthState, AuthTypeActions, AUTH_LOGIN, AUTH_LOGOUT, AUTH_UPDATE } from './types';


const initialState: AuthState = {
    user: null
}

export function authReducer(
    state = initialState,
    action: AuthTypeActions | any
): AuthState | any {
    switch (action.type) {
        case AUTH_LOGIN:
            console.log("Login ", action.payload)
            LoginController.saveCurrentProfile(action.payload.id);
            return {
                user: { ...action.payload }
            }
        case AUTH_UPDATE:
            console.log("Update ", action.payload)
            const { user } = state;
            let newUser = { ...user };
            LoginController.saveCurrentProfile(action.payload.id);
            Object.keys(action.payload).forEach((key) => {
                if (action.payload[key]) {
                    newUser[key] = action.payload[key]
                }
            });
            return {
                user: { ...newUser }
            }
        case AUTH_LOGOUT:
            console.log("Logout", state);
            return {
                following: []
            }
        default:
            return state
    }
}