import { connect, } from 'react-redux'
import { AuthState, AUTH_LOGIN, AUTH_LOGOUT, AUTH_UPDATE } from './types'


const mapState = (state: AuthState) => ({
    user: { ...state[0].user }
})

const mapDispatch = {
    login: (user:any) => {
        return ({
            type: AUTH_LOGIN,
            payload: user
        })
    },
    updateUser: (user: any) => {
        return ({
            type: AUTH_UPDATE,
            payload: user
        })
    },
    logOut: () => {
        return ({
            type: AUTH_LOGOUT,
            metadata: Date.now()
        })
    },
}

export const authConnector = connect(mapState, mapDispatch)