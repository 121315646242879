
import { Col, Row } from 'antd';
import React, { Component } from 'react';
import { BrowserView, MobileView } from 'react-device-detect';
import { withRouter } from 'react-router';
import { ProfileType } from '../../controllers/ProfileController';
import AppConstants from '../../helpers/AppConstants';
import { authConnector } from '../../stores/auth/connector';
import ChatPopup from '../chatPopup/ChatPopup';
import DiscoverCard from '../discover/DiscoverCard';
import SpecsCard from '../discover/SpecsCard';
import HomeNavigationComponent from '../homeNavigation/HomeNavigationComponent';
import ProductHome from './ProductHome';
import StoryHome from './StoryHome';
import './style.css';


class HomePage extends Component<any, any> {

    state = {
        selected: ProfileType.getPluralType(ProfileType.SUTRA),
        stories: undefined,
        products: undefined,
        selectedTag: undefined,
        selectedSpec: undefined,
    }


    getTabs = () => {
        return [
            ProfileType.getPluralType(ProfileType.SUTRA), AppConstants.Products
        ]
    }

    onSelected = (tab) => {
        this.setState({ selected: tab, });
        let index = ProfileType.allTypes.map((e) => ProfileType.getPluralType(e)).indexOf(tab);
        if (index >= 0) {
        }
        else if (tab == AppConstants.Products) {

        }
    }

    onStoriesLoaded = (stories) => {
        this.setState({ stories })
    }

    onProductsLoaded = (products) => {
        this.setState({ products })
    }

    render() {
        const { selected, stories, products, selectedSpec, selectedTag } = this.state;
        let index = ProfileType.allTypes.map((e) => ProfileType.getPluralType(e)).indexOf(selected);
        var newProducts = products, newStories = stories;
        if (selectedSpec && newProducts) {
            newProducts = newProducts.filter((e) => {
                let { specs = [] } = e;
                return specs.includes(selectedSpec)
            });
        }
        if (selectedTag && stories) {
            newStories = stories.filter((e) => {
                let { sourceStrings = [] } = e;
                return sourceStrings.includes(selectedTag)
            });
        }

        let body = (
            <>
                {index >= 0 ? <StoryHome stories={newStories} onStoriesLoaded={this.onStoriesLoaded} profileType={ProfileType.allTypes[index]} /> : <div />}
                {selected == AppConstants.Products ? <ProductHome products={newProducts} onProductsLoaded={this.onProductsLoaded} /> : <div />}
            </>
        );
        return (
            <div className="home-page">
                <HomeNavigationComponent selected={selected} onSelected={this.onSelected} tabs={this.getTabs()} />
                <MobileView viewClassName="home-page-body">
                    {body}
                </MobileView>
                <BrowserView viewClassName="home-page-body">
                    <Row>
                        <Col span={17}>
                            {body}
                        </Col>
                        <BrowserView>
                            <Col span={7} style={{ padding: '2rem 2rem', }} >
                                {index >= 0 ? <DiscoverCard available={[]} profileType={ProfileType.allTypes[index]} selected={selectedTag} onSelected={(selected) => {
                                    this.setState({ selectedTag: selected })
                                }} /> : <div />}
                                {selected == AppConstants.Products ? <SpecsCard selected={selectedSpec} onSelected={(selected) => {
                                    this.setState({ selectedSpec: selected })
                                }} /> : <div />}
                            </Col></BrowserView>
                    </Row>
                    <ChatPopup />
                </BrowserView>
            </div>
        );
    }
}


export default authConnector(withRouter(HomePage));