class AppResponse<T>{

    data: T;
    error: string;

    constructor(data: T, error?: string) {
        this.data = data;
        this.error = error;
    }



}

export default AppResponse;