import { Card, Spin } from 'antd';
import React, { Component } from 'react';
import { Trans } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import { Label } from 'semantic-ui-react';
import SpecController from '../../controllers/SpecController';
import AppConstants from '../../helpers/AppConstants';
import AppRoutes from '../../helpers/AppRoutes';
import './style.css';

class SpecsCard extends Component<any, any> {

    state = {
        isLoading: false,
        specs: [],
    }

    componentDidMount() {
        this.loadData();
    }

    loadData = async () => {
        this.setState({ isLoading: true });
        var response = await SpecController.getSpecs();
        const { onSelected } = this.props;
        if (response.data) {
            let data = response.data;
            if (data && data.length > 0) {
                onSelected(data[0].id);
            }
        }
        this.setState({ isLoading: false, specs: response.data });
    }

    render() {
        return (
            <Card title={<a href={AppRoutes.DISCOVER} style={{ color: "black", fontWeight: "bold" }}><Trans>Discover Products</Trans></a>} >
                <div className="discover-card">
                    <section className="discover-body">
                        {this.buildSpecs()}
                    </section>
                </div>
            </Card>
        );
    }


    buildSpecs() {
        const { history } = this.props;
        const { isLoading = false, specs = [] } = this.state;
        const { selected, onSelected } = this.props;
        return isLoading ? <Spin /> : specs.map((hashtag, index) => {
            const { id, name } = hashtag;
            const hashtagLink = `${AppRoutes.DISCOVER_ITEM(id, AppConstants.SPEC)}`;
            return (
                <Label key={id} style={{ margin: '5px', cursor: 'pointer', backgroundColor: selected == hashtag.id ? "#FFFF9E" : undefined }} onClick={onSelected ? () => { onSelected(hashtag.id) } : undefined}># {hashtag.name}</Label>
            )
        })
    }
}

export default withRouter(SpecsCard);