import firebase from "firebase";
import { v4 as uuidV4 } from 'uuid';
import AppResponse from "../models/AppResponse";
import LanguageController from "./LanguageController";
import ProfileController, { ProfileType } from "./ProfileController";
import UserController from "./UserController";


class StoreController {

    static STORES = "stores";


    static async addStore(user): Promise<AppResponse<string>> {
        try {
            let { username, profilePic, userId, language, name } = user;
            let profile = { name, username, profilePic, userId, type: ProfileType.STORE, language };
            let profileResponse = await ProfileController.addProfile(profile);
            if (profileResponse.error) {
                return new AppResponse(null, profileResponse.error);
            }
            let profileId = profileResponse.data;
            let store = { id: uuidV4(), profileId, };
            try {
                var snapshot = firebase.firestore().collection(this.STORES).doc(store.id).set(store);
            } catch (error) {
                await ProfileController.deleteProfileForever(profileId);

            }
            return new AppResponse(store.id, null);
        } catch (error) {
            console.log("Error", error)
            return new AppResponse(null, error["message"] || "Some error occurred");
        }
    }

    static async getDetails(id: string): Promise<AppResponse<{}>> {
        try {
            var snapshot = await firebase.firestore().collection(this.STORES).doc(id).get();
            var data = snapshot.data();
            if (data) {
                let result = await ProfileController.getDetails(data.profileId);
                if (result) {
                    return new AppResponse({ ...result, ...data, }, null)
                }
            }
            return new AppResponse(null, "Does not exists");

        } catch (error) {
            console.log("Error", error)
            return new AppResponse(null, error.toString);

        }
    }

    static async getHomeProfiles(type?: string, showAll?: boolean): Promise<AppResponse<Array<any>>> {
        let languageResponse = await LanguageController.getLanguageByCode(LanguageController.currentLanguage);
        if (languageResponse.error) {
            return new AppResponse([]);
        }
        let language = languageResponse.data;

        try {
            if (!type) {
                type = ProfileType.STORE;
            }
            let data = await Promise.all([
                (async () => {
                    let profileResponse = await ProfileController.getProfilesByType(type, showAll ? -1 : 10, language.id);
                    if (profileResponse.data) {
                        return profileResponse.data;
                    }
                    return []
                })(),


            ])
            let result = [...data[0],]

            let completedProfiles = await UserController.parseData(result);
            return new AppResponse(completedProfiles, null);
        } catch (error) {
            console.log("Error", error)
            return new AppResponse(null, error);
        }
    }


}

export default StoreController;