import { Empty, Layout, Spin } from 'antd';
import queryString from 'query-string';
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Header } from 'semantic-ui-react';
import IntentsController from '../../controllers/IntentsController';
import ProductController from '../../controllers/ProductController';
import SourceStringController from '../../controllers/SourceStringController';
import SpecController from '../../controllers/SpecController';
import StoryController from '../../controllers/StoryController';
import AppConstants from '../../helpers/AppConstants';
import AppRoutes from '../../helpers/AppRoutes';
import AppHeader from '../appHeader/AppHeader';
import StoryGridPage from '../storyPage/StoryGridPage';
import './style.css';

class DiscoverItemPage extends Component<any, any> {

    state = {
        isLoading: true,
        items: [],
        data: {},
        type: undefined
    }


    componentDidMount() {
        let pathname = this.props.history.location.pathname;
        if (pathname.startsWith(AppRoutes.DISCOVER_ITEM(""))) {
            let id = pathname.replace(AppRoutes.DISCOVER_ITEM(""), "");
            console.log("ID ", id)
            let search = queryString.parse(this.props.history.location.search);
            let type = search["type"];
            this.loadItems(id, type);
            this.setState({ type })
        }
    }

    loadItems = async (id, type) => {
        console.log("ID ", id, "Type ", type)
        switch (type) {
            case AppConstants.SOURCE_STRING:
                await Promise.all([
                    (async () => {
                        var response = await SourceStringController.getSourceString(id);
                        this.setState({ data: response })
                    })(),
                    (async () => {
                        var items = await StoryController.getStoriesBySource([id]);
                        console.log("Stories ", id, items.data);
                        if (items.data)
                            this.setState({ items: items.data[id] })
                    })(),
                ]);

                break;
            case AppConstants.INTENT:
                await Promise.all([
                    (async () => {
                        var response = await IntentsController.getIntent(id);
                        this.setState({ data: response })
                    })(),
                    (async () => {
                        var items = await StoryController.getStoriesByIntent([id]);
                        console.log("Stories ", id, items.data);
                        if (items.data)
                            this.setState({ items: items.data[id] })
                    })(),
                ]);

                break;
            case AppConstants.SPEC:
                await Promise.all([
                    (async () => {
                        var response = await SpecController.getDetails(id);
                        this.setState({ data: response })
                    })(),
                    (async () => {
                        var items = await ProductController.getProductsBySpec([id]);
                        if (items.data)
                            this.setState({ items: items.data[id] })
                    })(),
                ]);
                break;
            default:
                break;
        }

        this.setState({ isLoading: false });
    }

    render() {
        const { isLoading = true, items = [], data = {}, type } = this.state;
        console.log("Items ", items);
        return (
            <Layout style={{ background: "white" }}>
                <AppHeader title={"Discover"} />
                <div >
                    {isLoading ? <Spin /> : <div className="discover-page-body">
                        <Header as="h2" style={{ margin: '2rem 0 0 0rem', textAlign: "center" }}>#{data["name"]}</Header>
                        <span style={{ color: "rgba(0,0,0,0.6)", textAlign: "center" }} >{items.length} items found</span>
                        <div style={{ height: "0.5rem" }} />
                        {items.length > 0 ? <div style={{ width: '80%' }}>
                            <StoryGridPage stories={items} onClick={type == AppConstants.SPEC ? (val) => { } : null} />
                        </div> : <Empty />}
                    </div>}
                </div>
            </Layout>
        );
    }


}

export default withRouter(DiscoverItemPage);