import { Avatar, Button, Card, Drawer, Spin, Tag } from 'antd';
import JavascriptTimeAgo from 'javascript-time-ago';
import en from 'javascript-time-ago/locale/en';
import queryString from 'query-string';
import React, { Component } from 'react';
import { BrowserView, isMobile, MobileView } from 'react-device-detect';
import { Trans } from 'react-i18next';
import { withRouter } from 'react-router';
import { Button as SemanticButton, Header, Label } from 'semantic-ui-react';
import ProductController from '../../controllers/ProductController';
import { ProfileType } from '../../controllers/ProfileController';
import VideoController from '../../controllers/VideoController';
import AppRoutes from '../../helpers/AppRoutes';
import { authConnector } from '../../stores/auth/connector';
import './style.css';

JavascriptTimeAgo.addLocale(en)

class ProductNewDetailPage extends Component<any, any>  {
    state = {
        isLoading: true,
        product: null,
        error: null,
        comments: true,
        showVideo: true,
        showComments: false
    }

    componentDidMount() {
        let location = this.props.location;
        if (location.pathname.startsWith(AppRoutes.PRODUCT(""))) {
            let params = queryString.parse(location.search);
            let storyId: string = location.pathname.replace(AppRoutes.PRODUCT(""), "");
            if (storyId) {
                this.loadProductDetails(storyId);
            } else {
                this.setState({ error: "Does not exists" });
            }
        }
    }

    loadProductDetails = async (id) => {
        this.setState({ isLoading: true });
        let product = await ProductController.getDetails(id);
        console.log("Product ", product)
        console.log("Product ", product);
        this.setState({ isLoading: false, product })
    }

    render() {
        return (
            <>
                <BrowserView viewClassName="browser-view">
                    {this.buildHomeView()}
                </BrowserView>
                <MobileView viewClassName="mobile-view">
                    {this.buildMobileView()}
                </MobileView>

            </>
        );
    }

    private videoRef: React.RefObject<VideoPlayer> = React.createRef();


    history = {
        close: () => {
            console.log("Close");
            this.props.history.goBack();
        }

    };


    onMuteToggle = () => {
        if (this.videoRef && this.videoRef.current && this.videoRef.current.hasLoaded) {
            this.videoRef.current.toggleMute();
            setTimeout(() => {
                this.setState({},)
            }, 100)
        }
    }

    buildMobileView() {
        const { isLoading, product, error, comments, showVideo, showComments } = this.state;
        const { profile = {}, video = {} } = product || {};
        let { name, username, profilePic, type } = profile;
        if (type == ProfileType.CUSTOMER) {
            type = "consumer";
        }
        var muted = true;
        if (this.videoRef.current) {
            muted = this.videoRef.current.muted
        }
        return (
            <div className="story-container">
                {isLoading ? <Spin /> :
                    error ?
                        <span>{error}</span> :
                        <div className="story-page" >
                            <VideoPlayer history={this.history} video={showVideo ? video : {}} ref={this.videoRef} />
                            <div className="story-info-container">
                                <div className="video-actions">
                                    <SemanticButton className="action close" onClick={null} circular icon="close" />
                                    <SemanticButton className="action close" onClick={this.onMuteToggle} circular icon={"volume " + (!muted ? "up" : "off")} />
                                </div>
                                <div style={{ flexGrow: 1, height: 0 }} />
                                {this.buildStoryOptions(product)}
                                {this.buildStoryContainer(product)}
                            </div>
                        </div>
                }

                <Drawer placement="bottom" visible={showComments} height={'50vh'} onClose={() => this.setState({ showComments: false })}>
                </Drawer>
            </div>
        );
    }

    buildStoryDetails = (product) => {
        const { profile = {}, video = {} } = product || {};

        let { name, username, profilePic, type } = profile;
        const profileLink = `${AppRoutes.USER_PROFILE(type, username)}`;
        return (
            <div className="story-details">
                <div className="story-details-container">
                    <BrowserView>
                        <Avatar size={45} src={profilePic} />
                    </BrowserView>
                    <div className="user-name-container">
                        <Header as="h2" className="name">{name}</Header>
                        <div className="user-name">{username}</div>
                        <i className="user-name">({type == ProfileType.STORE ? "app" : type})</i>
                    </div>
                    <div className="profile">
                        <Button type="default" onClick={() => { window.location.href = profileLink }}><Trans>View Profile</Trans> </Button>
                    </div>
                </div>
                {/* <BrowserView>
          <div className="share-section">
            <SemanticButton circular icon="share alternate" color="teal" />
          </div>
        </BrowserView> */}
            </div>
        );
    }


    buildStoryOptions = (story) => {
        const { user = {} } = story;
        let { name, username, profilePic, type } = user;
        return (
            <MobileView>
                <div className="story-option-list">
                    <Avatar className="story-option" size={45} src={profilePic} />
                    <SemanticButton className="story-option" circular icon="share alternate" color="teal" />
                    <SemanticButton className="story-option" circular icon="like alternate" color="teal" />
                    <SemanticButton
                        onClick={() => {
                            this.setState({ showComments: true })
                        }}
                        className="story-option" circular icon="comment alternate" color="teal" />
                </div>
            </MobileView>
        );
    }

    buildStoryContainer = (product) => {
        const { history } = this.props;
        const { user = {}, profileId, title, description, category = {}, language = {}, specs = [] } = product || {};
        return (
            <div className="story-container">
                <Header as="h2">{title}</Header>
                <div>{description}</div>
                <div className="tag-section">
                    {category.name && <Label tag>{category.name}</Label>}
                    {language.name && <Label tag>{language.name}</Label>}
                </div>
                <div className="hashtag-section">
                    {specs.map(spec => {
                        const { id, name } = spec;
                        const hashtagLink = `${AppRoutes.HASHTAG(id)}`;
                        return (
                            <Label key={spec.id} onClick={() => { history.push(hashtagLink) }}>#{name}</Label>
                        )
                    })}
                </div>
                <div style={{ height: "0.7rem" }} />
                <Button block size="large" href={product.website} target="_blank" type="primary">Buy Now</Button>
            </div>
        );
    }

    buildHomeView() {
        const { isLoading, product, error, comments, showVideo } = this.state;
        const { profile = {}, profileId, title, description, category = {}, language = {}, specs = [], video = {} } = product || {};
        let { name, username, profilePic, type } = profile;
        if (type == ProfileType.CUSTOMER) {
            type = "consumer";
        }
        const profileLink = `${AppRoutes.USER_PROFILE(type, username)}`;
        return (
            <div className="story-container">
                {isLoading ?
                    <img className="sutra-logo" src="/logo.jpg" /> :
                    error ?
                        <span>{error}</span> :
                        <div className="story-page" >
                            <VideoPlayer history={this.history} video={showVideo ? video : {}} />
                            <div className="story-info-container" style={{ flex: 1 }}>
                                <div className="story-details">
                                    <div className="story-details-container">
                                        <Avatar size={45} src={profilePic} />
                                        <div className="user-name-container">
                                            <Header as="h2" className="name">{name}</Header>
                                            <div className="user-name">{username}</div>
                                            <i className="user-name">({type == ProfileType.STORE ? "app" : type})</i>
                                        </div>
                                        <div className="profile">
                                            <Button type="default" onClick={() => { window.location.href = profileLink }}>
                                                <Trans>View Profile</Trans>
                                            </Button>
                                        </div>
                                    </div>

                                </div>

                                <div className="story-container">
                                    <Header as="h2">{title}</Header>
                                    <div>{description}</div>

                                    <div className="hashtag-section">
                                        {specs.map(spec => {
                                            const { id, name } = spec;
                                            const hashtagLink = `${AppRoutes.HASHTAG(id)}`;
                                            return (
                                                <Tag key={spec.id}>#{name}</Tag>
                                            )
                                        })}
                                    </div>
                                    <div style={{ height: "1rem" }} />
                                    <Card className="shop-card">
                                        <h3 className="shop-card-title">Looking to Buy?</h3>
                                        <h4 className="shop-card-subtitle">Click the shop now button to go to product website.</h4>
                                        <Button block size="large" href={product.website} target="_blank" type="primary">Buy Now</Button>
                                    </Card>
                                </div>
                            </div>
                        </div>
                }
            </div>
        );
    }
}


class VideoPlayer extends Component<any, any> {

    state = {
        muted: true
    }

    private isLoaded: boolean = false;

    get hasLoaded() {
        return this.isLoaded;
    }

    constructor(props) {
        super(props);
        this.videoRef = React.createRef();
    }
    componentDidMount() {

    }

    private videoRef: React.RefObject<HTMLVideoElement>;

    play = () => {
        this.videoRef.current.play();
    }

    pause = () => {
        this.videoRef.current.pause();
    }

    seeVideo = () => {
        const { story = {} } = this.props;
        VideoController.seeVideo(story["videoId"]);
    }

    onLoadedVideo = () => {
        this.play();
        this.isLoaded = true;
    }

    toggleMute = () => {
        const { muted } = this.state;
        this.setState({ muted: !muted })
    }

    get muted() {
        const { muted } = this.state;

        return muted;
    }

    render() {
        const { history, video } = this.props;
        const { muted } = this.state;
        var { videoUrl = "" } = video || {};
        return (
            <div className="story-video">
                <div className="video-container">
                    {videoUrl ? <video controls={!isMobile} muted={muted} ref={this.videoRef} onLoadedData={this.onLoadedVideo} onLoadStart={this.seeVideo}>
                        <source type="video/webm" src={videoUrl} />
                        <source type="video/mp4" src={videoUrl} />
                        <source type="video/ogg" src={videoUrl} />
                    </video> : <div />}
                </div>
                <BrowserView>
                    <div className="video-actions">
                        <SemanticButton className="action" onClick={history.close} circular icon="close" />
                        <SemanticButton className="action close" onClick={this.toggleMute} circular icon={"volume " + (!muted ? "up" : "off")} />
                    </div>
                </BrowserView>
            </div>
        )
    }
}

export default authConnector(withRouter(ProductNewDetailPage));