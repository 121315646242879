class NewUser {
    id: string;
    name: string;
    profilePic: string;
    createdAt: Date;
    googleId: string;
    facebookId: string;
    mobileNumber: string;
    email: string;
    password: string;
    username: string;
    profileComplete: boolean;
    profile: object;

    constructor(id, name, profilePic, createdAt, googleId, facebookId, mobileNumber, email, password, username, profileComplete) {
        this.id = id;
        this.name = name;
        this.profilePic = profilePic;
        this.createdAt = createdAt;
        this.googleId = googleId;
        this.facebookId = facebookId;
        this.mobileNumber = mobileNumber;
        this.email = email;
        this.password = password;
        this.username = username
        this.profileComplete = profileComplete || false;
    }

    static fromJson(json) {
        return new NewUser(
            json.id, json.name, json.profilePic, json.createdAt,
            json.googleId,
            json.facebookId,
            json.mobileNumber,
            json.email,
            json.password,
            json.username,
            json.profileComplete
        );
    }

}

class NewUserRole {
    static get USER() {
        return "user"
    };
    static get SUTRA() {
        return "sutra"
    };
}

export default NewUser;