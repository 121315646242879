import { MessageTwoTone } from '@ant-design/icons';
import { Col, message, Result, Row, Spin } from 'antd';
import React, { Component } from 'react';
import { BrowserView, MobileView } from 'react-device-detect';
import { Route, Switch, withRouter } from 'react-router-dom';
import ChatController from '../../controllers/ChatController';
import MessageController from '../../controllers/MessageController';
import AppRoutes from '../../helpers/AppRoutes';
import Chat from '../../models/chat/Chat';
import { authConnector } from '../../stores/auth/connector';
import { AppErrorComponent } from '../appError/AppErrorComponent';
import { AppLoader } from '../appLoader/AppLoader';
import ChatDashboard from '../chat/dashboard/ChatDashboard';
import LoginOptionsPage from '../login/LoginOptionsPage';
import { ChatDetailComponent } from './ChatDetailComponent';
import ChatPanel from './ChatPanel';
import './style.css';

class ChatPage extends Component<any, any> {

    state = {
        currentChat: undefined,
        isLoading: false,
        error: undefined,
        showLogin: false,
        isLoadingChats: true,
        chats: [],
    }

    messageListener;

    componentDidMount() {
        const { user } = this.props;
        if (Object.keys(user || {}).length == 0) {
            this.setState({ error: "User not logged in", showLogin: true })
            return;
        }
        this.loadChats();
        this.messageListener = MessageController.onMessageEveryone((message) => {
            if (message) {
                this.updateChat(message.chatId)
            }
        });
        let pathname = this.props.history.location.pathname;
        if (pathname && pathname.startsWith(AppRoutes.CHAT(""))) {
            let username = pathname.replace(AppRoutes.CHAT(""), "");
            if (username)
                this.loadDetails(username);
        }
        else {

        }
    }

    updateChat = async (chatId) => {
        let newChat = (await ChatController.getChat(chatId)).data;
        if (newChat) {
            let { chats = [] } = this.state;
            let oldChats = [...chats];
            oldChats = oldChats.filter((chat) => chat.id != newChat.id);
            let newChats = [...oldChats, newChat];
            this.setState({
                chats: newChats
            })
        }
    }

    componentWillUnmount() {
        if (this.messageListener) {
            this.messageListener();
        }
    }

    listenMessages = () => {
    }

    loadChats = async () => {
        let chatResponse = await ChatController.getChats();
        this.setState({ isLoadingChats: false })
        if (chatResponse.data) {
            let chats = [...chatResponse.data];
            this.setState({ chats })
        }
    }

    onSelectedChat = (chat: Chat) => {
        this.setState({ currentChat: chat, error: undefined, isLoading: false });
        let width = window.innerWidth;
        // Mobile
        let push = this.props.history.replace;
        if (width < 600) {
            push = this.props.history.push;
        }
        if (chat.friend) {
            push(AppRoutes.CHAT(chat.friend.username))
        }
        else {
            push(AppRoutes.CHAT(chat.friend.username))
        }
    }

    loadDetails = async (username) => {
        this.setState({ isLoading: true })

        let response = await ChatController.startChatWithUsername(username);
        this.setState({
            isLoading: false
        })
        if (response.data) {
            this.setState({ currentChat: response.data })
        }
        else {
            message.error({ content: response.error })
            this.setState({ error: response.error })
        }
    }


    render() {
        return (
            <>
                <MobileView viewClassName="mobile-view">{this.buildMobileView()}</MobileView>
                <BrowserView style={{ height: "100%" }}>{this.buildHomeView()}</BrowserView>
            </>
        );
    }

    buildHomeView = () => {
        const { showLogin, } = this.state;
        if (showLogin) {
            return <div className="login-page"><LoginOptionsPage options={['facebook', 'google']} redirectLink={window.location.href
            } /></div>
        }
        const { currentChat, isLoading, error, isLoadingChats, chats } = this.state;
        return (
            <Row className="chat-page" align="stretch">
                <Col span={5} className="h-100">
                    <ChatPanel selectedChat={currentChat} chats={chats} isLoading={isLoadingChats} onSelected={this.onSelectedChat} />
                </Col>
                <Col span={19} style={{ height: "100%" }} >
                    {isLoading ? <Spin tip={"Loading"} /> : currentChat ? <section style={{ height: "100%" }} className="chat-body">
                        <>
                            <ChatDashboard chat={currentChat} />
                        </>
                        < >
                            <ChatDetailComponent chat={currentChat} />
                        </>
                    </section> : this.buildEmpty()}
                </Col>
            </Row>
        );
    }

    buildEmpty = () => {
        return (
            <Result icon={<MessageTwoTone translate />} title={"Welcome to Sutras"} subTitle={"Please tap a chat to start messaging"} />
        );
    }

    buildMobileView = () => {
        const { currentChat, isLoading, error, showLogin, chats, isLoadingChats } = this.state;
        console.log("Build Chats");
        if (showLogin) {
            return <div className="login-page"><LoginOptionsPage options={['facebook', 'google']} redirectLink={window.location.href
            } /></div>
        }
        return (
            error ? <AppErrorComponent error={error} /> : isLoading ? <AppLoader /> : <div className="chat-page">
                <Switch>
                    <Route exact path={AppRoutes.CHAT(":username")} render={() => isLoading ? <Spin /> : <ChatDashboard chat={currentChat} />} />
                    <Route path="/" render={() => <ChatPanel chats={chats} isLoading={isLoadingChats} selectedChat={currentChat} onSelected={this.onSelectedChat} />} />
                </Switch>
            </div>
        );
    }
}

export default withRouter(authConnector(ChatPage));