import firebase, { firestore } from 'firebase';
import uuid from 'uuid';
import AppCollections from '../helpers/AppCollections';
import AppConstants from '../helpers/AppConstants';
import JsonHelper from '../helpers/JsonHelper';
import Channel from '../models/Channel';
import User from '../models/User';

const PASSWORD = "Password";


class UserController {

    static get users() { return "users" };

    static get userCollection(): firestore.CollectionReference {
        return firestore().collection(this.users);
    }

    static getUserRef(userId: string, onChanged: (user: User) => void) {
        return this.getUserReference().child(userId).on("value", (snapshot) => {
            if (!snapshot) {
                return;
            }
            let json = snapshot.val()
            if (json) {
                let user = User.fromJson(json);
                onChanged(user);
            }
        })
    }

    static async searchUser(query: string): Promise<Array<User>> {
        try {
            let snapshot = await firebase.database().ref(AppConstants.USERS).orderByChild("name").startAt(query).endAt(query + "\uf8ff").once("value");
            let result = [];
            snapshot.forEach((child) => {
                let json = child.val();
                result.push(User.fromJson(json));
            });
            return result.map((val) => val);
        } catch (error) {
            return [];
        }
    }

    static async updateUser(id: string, newUser: User): Promise<void> {
        let result = JSON.parse(JSON.stringify(newUser));
        await firebase.database().ref("Users").child(id).update(result);
    }

    // Get user details by user id
    static async getUserDetails(id: string): Promise<User> {
        let data = await this.getUserReference().child(id).once("value");

        if (data.exists && data.val()) {
            let json = data.val();
            let user = User.fromJson(json);
            return user;
        }
        return undefined;
    }



    // Get user details by username
    static async getUserDetailsByUsername(username: string): Promise<User> {
        let data = await this.getUserReference().orderByChild("username").equalTo(username).once("value");
        console.log(data.val());

        if (data.exists() && data.numChildren() > 0) {
            let json = {};
            data.forEach((child) => {
                json = child.val();
            })
            let user = User.fromJson(json);
            return user;
        }
        return undefined;
    }

    static async getUserDetailsByEmail(email: string): Promise<User> {
        const snapshot = await firebase.firestore().collection(AppCollections.USERS).where("email", "==", email).get()
        const result = snapshot.docs.map((val) => val.data());
        if (result.length) {
            let user = User.fromJson(result[0]);
            return user;
        }

        return undefined;
    }

    static async getUserDetailsByField(field: string, value: string): Promise<User> {
        const snapshot = await firebase.firestore().collection(AppCollections.USERS).where(field, "==", value).get()
        const result = snapshot.docs.map((val) => val.data());
        if (result.length) {
            let user = User.fromJson(result[0]);
            return user;
        }

        return undefined;
    }

    static async getUserProfile(id: string, profileType: string): Promise<any> {
        const snapshot = await firebase.firestore().collection("profiles").where("userId", "==", id).get()
        const result = snapshot.docs.map((val) => val.data());
        if (result.length) {
            const profile = result.find(profile => profile.type === profileType);
            if (profile) {
                return profile;
            }
        }

        return undefined;
    }

    static USER: string = "user";
    static USER_ID: string = "userId";

    static getUserReference(): firebase.database.Reference {
        return firebase.database().ref("Users");
    }


    static async createUser(user: User, password: string): Promise<User> {
        try {
            let temp = { ...user };
            temp.userId = uuid.v4();
            temp["password"] = password;
            temp.photoURL = this.getUserImage(user)
            temp["creation_date"] = new Date().toISOString();
            let snapshot = await this.getUserReference().child(temp.userId).set(JsonHelper.cleanJson(temp));
            return User.fromJson(temp);
        }
        catch (e) {
            return e;
        }
    }

    static getGravatar(name: string): string {
        return `https://ui-avatars.com/api/?name=${name}&rounded=true&size=256`;
    }

    static getUserImage(user: User): string {
        const firebasePath = 'https://firebasestorage.googleapis.com/v0/b/sutras-a72f7.appspot.com/o/';
        return firebasePath + user.userId + "?alt=media"
    }


    static getChannelInteract(onLoaded: (channels: Array<Channel>) => void) {
        // ChannelController.loadChannels(true, onLoaded);
    }

    // New

    // Get user details by user id
    static async getUser(uid: string): Promise<any> {
        try {
            var snapshot = await firebase.firestore().collection(AppCollections.USERS).doc(uid).get();
            if (snapshot.exists) {
                return snapshot.data()
            }
        } catch (error) {
            console.log("Error", error);
            return null;
        }
    }

    static async parseData(data): Promise<any> {
        let newData = [...data]
        let userData = {};
        await Promise.all(Object.keys(newData).map(async (key) => {
            let val = newData[key];
            if (val && val.userId) {
                if (userData[val.userId]) {
                    val.user = userData[val];
                }
                else {
                    let result = await this.getUser(val.userId);
                    if (result) {
                        val.user = result;
                        userData[val.userId] = result;
                    }
                }
            }
        }));
        return newData;
    }

}

enum UserRole {
    user,
    sutra,
    admin,
    anonymous
}

export { UserRole };

export default UserController;