import { Button, Divider, Dropdown, Icon, List, Menu, Modal } from 'antd';
import React, { Component } from 'react';
import { MobileView } from 'react-device-detect';
import { Trans } from 'react-i18next';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import { ProfileType } from '../../controllers/ProfileController';
import UserController from '../../controllers/UserController';
import AppConstants from '../../helpers/AppConstants';
import AppRoutes from '../../helpers/AppRoutes';
import ProfileHelper from '../../helpers/ProfileHelper';
import { authConnector } from '../../stores/auth/connector';
import LangSelection from '../langSelection/LangSelection';
import ProfileDialog from '../profileDialog/ProfileDialog';
import UploadButton from '../uploadButton/UploadButton';
import './SidePanelStyle.css';

const { confirm } = Modal;

class Action {
    title: string;
    icon: string;
    link: string;

    constructor(title, icon, link?) {
        this.title = title;
        this.icon = icon;
        this.link = link
    }
}

class SidePanel extends Component<any, any>  {
    state = {
        selectedAction: null,
        invitationsVisible: false,
        accountVisible: false,
        isLoading: true,
        actions: [],
        currentUser: null
    }

    userUnsubscribe;


    async componentDidMount() {
    }


    onActionClick = (action: Action) => {
        this.setState({
            selectedAction: action
        })
    }

    componentWillUnmount() {
        if (this.userUnsubscribe)
            this.userUnsubscribe()
    }


    // User Details Header
    buildUserHeader = () => {
        const { isLoading, accountVisible } = this.state;
        const { user } = this.props;
        const menu = <Menu>
            {ProfileType.allTypes.map((e) => <Menu.Item>
                <a rel="noopener noreferrer" href={AppRoutes.REGISTER_PROFILE(e)}>
                    <Trans>Register as {ProfileType.getType(e)}</Trans>
                </a>
            </Menu.Item>)}
        </Menu>;

        return (
            <div className={"drawer-header"} style={{ position: 'relative', }}>
                {user && Object.keys(user).length > 0 ? <div>
                    <img className="drawer-avatar" onError={(image) => {
                        image.currentTarget.src = user.profilePic
                    }} src={user.profilePic || UserController.getGravatar(user.name)} />
                    <div style={{ height: "12pt" }}></div>
                    <List.Item.Meta title={user.name} description={user.username} />
                    <Button type="link" onClick={() => window.location.pathname = AppConstants.getUserProfile(user.username)}><Trans>View Profile</Trans></Button>
                    <div style={{ height: "12pt" }}></div>
                    <MobileView>
                        <Button onClick={() => {
                            const { accountVisible } = this.state;
                            this.setState({ accountVisible: !accountVisible })
                        }} >Switch Profile</Button><div style={{ width: "0.1rem", display: "inline-block" }} />

                        <LangSelection size="default" />
                    </MobileView>
                    <ProfileDialog visible={accountVisible} onClose={() => {
                        this.setState({ accountVisible: false })
                    }} />
                </div> : <div />}
                <div style={{ height: "1rem" }} />
                <UploadButton />
                {
                    (!user || Object.keys(user).length == 0) ? <section style={{ margin: '1rem 0pt', width: "80%" }}>
                        <Button className="login-action" type="default" style={{ borderColor: "#1890ff", color: '#1890ff' }} block onClick={() => this.props.history.push(AppRoutes.LOGIN)} size="large"><Trans>Login</Trans></Button>
                        <div style={{ height: '1rem' }} />
                        <Dropdown overlay={menu} ><Button size="large" type="primary" block >Register as <Icon type="down" /></Button></Dropdown>
                        <MobileView>
                            <div style={{ height: '1rem' }} />
                            <LangSelection block={true} />
                        </MobileView>
                    </section > : <div />
                }
            </div>
        );
    }

    // Logo
    buildLogo = () => {
        return (
            <div className="logo-container">
                <Link to="/">
                    <img className="logo" src="/logo.jpg" /></Link>
            </div>
        );
    }

    render() {

        const { user } = this.props;

        return (
            <div className="side-panel">
                <List>
                    {this.buildLogo()}
                    {this.buildUserHeader()}
                    <Divider />
                    <div className="side-panel-actions">
                        <div>
                            <Button icon={"home"} type="link" onClick={() => {
                                this.props.history.push(AppRoutes.HOME);
                            }} className={`side-panel-action`} >
                                <Trans>Home</Trans>
                            </Button>
                        </div>
                        <div>
                            <Button icon={"global"} type="link" onClick={() => {
                                this.props.history.push(AppRoutes.SUGGESTED);
                            }} className={`side-panel-action`} >
                                <Trans>Search</Trans> </Button>
                        </div>
                        <div>
                            <Button icon={"tags"} type="link" onClick={() => {
                                this.props.history.push(AppRoutes.DISCOVER)
                            }} className={`side-panel-action`} >
                                <Trans>Discover</Trans>
                                {/* <Trans>{ProfileType.getPluralType(ProfileType.STORE)}</Trans> */}
                            </Button>
                        </div>
                        <div>
                            <Button icon={"message"} type="link" onClick={() => {
                                this.props.history.push(AppRoutes.CHAT(""));
                            }} className={`side-panel-action`} >
                                <Trans>Chats</Trans>
                            </Button>
                        </div>
                        {user && Object.keys(user).length > 0 ? <Button icon={"logout"} type="link" onClick={() => ProfileHelper.showLogout(this.props.logOut)} className={`side-panel-action`} >
                            {" "}<Trans>{AppConstants.Logout}</Trans>
                        </Button> : <div />}
                    </div>
                    <div style={{ height: "12pt" }}></div>
                </List>

            </div >
        );
    }
}

export default authConnector(withRouter(SidePanel));