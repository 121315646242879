import { Avatar, List } from 'antd';
import React from 'react';
import Chat from '../../models/chat/Chat';
import Message from '../../models/chat/Message';

type ChatProps = {
    chat: Chat,
    onClick: (chat: Chat) => void,
    isSelected?: boolean
}

export const ChatComponent = (props: ChatProps) => {
    const { chat, onClick, isSelected } = props;
    return <List.Item className={"chat-item " + (isSelected ? "chat-item-selected" : "")} onClick={onClick ? () => onClick(chat) : undefined}>
        <List.Item.Meta avatar={<Avatar src={Chat.getAvatar(chat)} />} title={Chat.getTitle(chat)} description={Message.getText(chat.lastMsg)} />
    </List.Item>
}