import { Button, Form, Icon, Input, message, Select } from 'antd';
import queryString from 'query-string';
import React, { Component } from 'react';
import { Trans } from 'react-i18next';
import { withRouter } from 'react-router';
import LoginController from '../../controllers/LoginController';
import UserController from '../../controllers/UserController';
import AppConstants from '../../helpers/AppConstants';
import AppRoutes from '../../helpers/AppRoutes';
import ProfileHelper from '../../helpers/ProfileHelper';
import AppResponse from '../../models/AppResponse';
import NewUser from '../../models/NewUser';
import { connectedRouteProps } from '../../props';
import { authConnector } from '../../stores/auth/connector';


const InputGroup = Input.Group;
const { Option } = Select;
const options = [{
    label: 'Sutra',
    value: 'sutra'
}, {
    label: 'Consumer',
    value: 'customer',
}, {
    label: 'Supplier',
    value: 'store'
}]

class LoginEmailPage extends Component<connectedRouteProps, any> {

    state = {
        email: null,
        password: null,
        isLoading: false,
        isNewUser: false,
        name: undefined,
    }



    loginWithEmail = async () => {
        const { email, password, name, isNewUser } = this.state;
        if (!email) {
            message.error("Email required");
            return;
        }
        if (!password) {
            message.error("Password required");
            return;
        }
        if (isNewUser && !name) {
            message.error("Please enter name");
            return;
        }
        this.setState({ isLoading: true });
        if (!isNewUser) {
            let profile = await UserController.getUserDetailsByField("email", email);
            if (!profile) {
                this.setState({ isNewUser: true, isLoading: false });
                message.error("Your profile does not exists. Please enter your name");
                return;
            }
        }
        let response: AppResponse<NewUser>;
        if (isNewUser) {
            response = await LoginController.signupWithEmail({ email, password, name });
        }
        else {
            response = await LoginController.loginWithEmail(email, password);
        }
        this.setState({ isLoading: false });
        if (!response) {
            message.error("Not logged in");

        }
        else if (response.error) {
            message.error(response.error);
        }
        else {
            message.success("Success");
            ProfileHelper.loginProfile(response.data, this.props.history, this.props.login, ProfileHelper.getType(this.props.history.location.search));
        }

    }

    isSignup = () => {
        let search = this.props.history.location.search;
        let query = queryString.parse(search);
        if (query["option"] && query["option"] == AppConstants.REGISTER.toLowerCase()) {
            return true;
        }
    }

    isSutra = () => {
        let search = this.props.history.location.search;
        let query = queryString.parse(search);
        if (query["type"] && query["type"] == "sutra") {
            return true;
        }
    }


    render() {
        const { isLoading, isNewUser } = this.state;
        let labelText = this.isSignup() ? "Signup" : "Login";
        return (
            <div className="login-section">
                <h3 className="login-title"><Trans>{labelText + (this.isSignup() && this.isSutra() ? " as Sutra" : "")}</Trans></h3>
                <h4 className="login-description">
                    <Trans>{labelText} with email</Trans>
                </h4>
                <div style={{ height: '1rem ' }} />
                <Form>
                    <Input
                        type="email"
                        className="login-input"
                        size="large"
                        prefix={<Icon type="mail" />}
                        onChange={(event) => {
                            this.setState({
                                email: event.target.value
                            })
                        }}
                        required
                        placeholder="Email"
                        disabled={isNewUser}
                        style={{ marginBottom: "1.2rem" }}
                    />
                    <Input
                        className="login-input"
                        size="large"
                        maxLength={20}
                        prefix={!isNewUser ? undefined : <Icon type="user" />}
                        required
                        onChange={(event) => {
                            this.setState({
                                name: event.target.value
                            })
                        }}
                        style={{ marginBottom: "1.2rem" }}
                        placeholder="Name"
                        hidden={!isNewUser}
                    />
                    <Input.Password
                        className="login-input"
                        size="large"
                        type="password"
                        prefix={<Icon type="lock" />}
                        required
                        onChange={(event) => {
                            this.setState({
                                password: event.target.value
                            })
                        }}
                        placeholder="Password"
                    />
                    <div style={{ height: '1rem ' }} />
                    <Button style={{ marginLeft: "auto" }} onClick={() => {
                        this.props.history.replace(AppRoutes.FORGOT_EMAIL);
                    }} type="link"><Trans
                    >Forgot Password</Trans></Button>
                    <div style={{ height: '0.5rem' }} />

                    <Button formAction="submit" formMethod="submit" onSubmit={(val) => {
                        console.log("Submit")
                    }} loading={isLoading} disabled={isLoading} onClick={this.loginWithEmail} className="login-action-button"><Trans>{labelText} with email</Trans></Button>
                </Form>
            </div>
        );
    }
}

export default authConnector(withRouter(LoginEmailPage));