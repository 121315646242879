import React, { Component } from 'react';
import {  message,  Spin } from 'antd';
import { RouteComponentProps, withRouter, StaticContext } from 'react-router';
import AppRoutes from '../../helpers/AppRoutes';
import { connectedRouteProps } from '../../props';
import { authConnector } from '../../stores/auth/connector';
import ProfileController, { ProfileType } from '../../controllers/ProfileController';
import AppHeader from '../appHeader/AppHeader';
import SutrazController from '../../controllers/SutrazController';
import ProfessionController from '../../controllers/ProfessionController';
import './style.css';
import WrappedAddProfileForm from '../profileForm/ProfileForm';
import ProfileHelper from '../../helpers/ProfileHelper';


class ProfileEditPage extends Component<connectedRouteProps, any> {

    state = {
        isLoading: false,
        username: null,
        name: null,
        location: null,
        category: "clothes",
        profilePic:undefined,
        profession:undefined,
        isLoadingData:true,
        professions:[]  
    }
    componentDidMount() {
        let { user } = this.props;
        if(!user){
            return;
        }
        this.setState({profilePic:user.profilePic})
        this.loadData();
    }

    loadData = async () => {
        const response = await ProfessionController.getProfessions();
        if(response.data){
            var professions=response.data||[];
            this.setState({professions})
        }
      this.setState({isLoadingData:false});
    }
  

    updateUser = async (newUser) => {
       let {user={}}=this.props;
       this.setState({isLoading:true});
       let response=await ProfileHelper.updateProfile(user,newUser);
       if(response){
           this.props.updateUser(response);
           this.props.history.push(AppRoutes.HOME);
       }
    }

    render() {
        const { isLoading, profilePic,professions,isLoadingData } = this.state;
        const { user } = this.props;
        let isSutra=user.type==ProfileType.SUTRA;
        return (
            <div className="profile-page">
                <AppHeader/>
                <div className="profile-section">
                <h3 className="profile-title">Profile Settings</h3>
                <h4 className="profile-description">
                    You can edit all your details.
                </h4>
                <div style={{ height: '2rem ' }} />
                {isLoadingData?<Spin tip="Loading Data" />:<WrappedAddProfileForm user={user} onSubmit={(user)=>{
                    this.updateUser(user);
                }} isLoading={false}  />}
                </div>
            </div>
        );
    }
}

export default authConnector(withRouter(ProfileEditPage));