import Message from "./Message";

export default class Chat {
    id: string;
    users: Array<any>;
    lastMsg: Message;
    type: string;
    recordedAt: Date;
    friend: any;
    groupAvatar: string;
    groupName: string;

    constructor(id?, users?, lastMsg?, type?, recordedAt?, friend?, groupAvatar?, groupName?) {
        this.id = id;
        this.users = users;
        this.lastMsg = lastMsg;
        this.type = type;
        this.recordedAt = recordedAt;
        this.friend = friend;
        this.groupAvatar = groupAvatar;
        this.groupName = groupName;
    }

    get title() {
        if (this.friend) {
            return this.friend.name;
        }
        return this.groupName;
    }

    static getTitle(chat) {
        if (chat.friend) {
            return chat.friend.username;
        }
        return chat.groupName;
    }

    static getAvatar(chat) {
        if (chat.friend) {
            return chat.friend.profilePic;
        }
        return chat.groupAvatar;
    }
}

export class ChatType {
    static get private() {
        return "private";
    }

    static get group() {
        return "group";
    }

}