import { Button } from 'antd';
import React, { Component } from 'react';
import { Trans } from 'react-i18next';
import FollowHelper from '../../helpers/FollowHelper';
import ProfileHelper from '../../helpers/ProfileHelper';
import { authConnector } from '../../stores/auth/connector';
import './style.css';

type props = {
    friendId: any
}

class FollowButton extends Component<props | any, any> {
    render() {
        const { user, updateUser, friendId } = this.props;
        const profile = { ...user };
        const { following = [] } = profile;
        const isFollowing = following.some(profileId => profileId === friendId);
        return (
            friendId == user.id ? <span /> : <Button onClick={() => {
                if (!user.id) {
                    ProfileHelper.showLogin();
                    return;
                }
                FollowHelper.follow(user.id, friendId, updateUser);
            }} type={isFollowing ? "default" : "primary"} size="small" ><Trans>{isFollowing ? "Unfollow" : "Follow"}</Trans></Button>);
    }
}

export default authConnector(FollowButton);