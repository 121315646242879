import firebase from 'firebase';
import LanguageController from './LanguageController';

class IntentsController {

  static INTENTS = "intents";
  static INTENT_VIEWS = "IntentViews";

  // Search intents by text

  static async getIntent(id: string): Promise<any> {
    try {
      var snapshot = await firebase.firestore().collection(IntentsController.INTENTS).doc(id).get();
      var data = snapshot.data();
      if (data)
        return data;
      return "Does not exists";
    } catch (error) {
      console.log("Error", error)
      return null;
    }
  }


  static async getIntents(limit?: number): Promise<Array<any>> {
    try {
      let languageResponse = await LanguageController.getLanguageByCode(LanguageController.currentLanguage);
      if (languageResponse.error) {
        return [];
      }
      let language = languageResponse.data;
      var collectionRef = firebase.firestore().collection(IntentsController.INTENTS).where("language", "==", language.id);
      console.log("Get Intents ");
      var snapshot;
      if (limit) {
        snapshot = await collectionRef.limit(limit).get();
      }
      else {
        snapshot = await collectionRef.get();
      }
      var result = snapshot.docs.map((val) => val.data());
      return result;
    } catch (error) {
      console.log("Error", error);
      return null;
    }
  }

  // Updating intent view count
  static async updateViewCount(intentId: string): Promise<void> {

    try {
      let languageCode = LanguageController.currentLanguage;
      let ref = firebase.app().database().ref(this.INTENT_VIEWS).child(languageCode).child(intentId);
      let snapshot = await ref.once("value");
      let viewsCount = 0;
      if (snapshot.val()) {
        let json = snapshot.val();
        viewsCount = json["viewsCount"] || 0;
      }
      viewsCount += 1;
      await ref.set({ "viewsCount": viewsCount })
    } catch (error) {

    }
  }


}

export default IntentsController;