import firebase, { firestore } from "firebase";
import { v4 as uuidV4 } from 'uuid';
import UIHelper from "../helpers/UIHelper";
import AppResponse from "../models/AppResponse";
import DeviceController from "./DeviceController";
import SutrazController from "./SutrazController";
import UserController from "./UserController";
var short = require('short-uuid');


export default class ProfileController {

    static PROFILES = "profiles";

    static get profiles() { return "profiles" };

    static get profileCollection(): firestore.CollectionReference {
        return firestore().collection(this.profiles);
    }

    static async addProfile(profile): Promise<AppResponse<string>> {
        try {
            // Username
            if (profile.username) {
                let usernameResponse = await this.checkUsernameExists(profile.username);
                if (usernameResponse.error) {
                    return new AppResponse(null, usernameResponse.error);
                }
                if (usernameResponse.data) {
                    return new AppResponse(null, "Username exists");
                }
            }
            // Profile
            let profileResponse = await this.checkProfileExists(profile.userId, profile.type);
            if (profileResponse.error) {
                return new AppResponse(null, profileResponse.error);
            }
            if (profileResponse.data) {
                return new AppResponse(null, "Profile exists");
            }
            return await this.create(profile);
        } catch (error) {
            return new AppResponse(null, error);
        }
    }

    static async create(profile: any): Promise<AppResponse<string>> {
        try {
            let id = uuidV4();
            let temp = { ...profile };
            temp.id = id;
            temp.recordedAt = new Date();
            await firebase.firestore().collection(this.PROFILES).doc(id).set(JSON.parse(JSON.stringify(temp)));
            return new AppResponse(id, null);
        } catch (error) {
            return new AppResponse(null, error);
        }
    }

    static async createAnonymous(): Promise<AppResponse<any>> {
        try {
            let deviceId = await DeviceController.getUniqueId();
            if (!deviceId) {
                deviceId = uuidV4();
            }
            var oldResponse = await this.getDetails(deviceId);
            if (oldResponse) {
                return new AppResponse(oldResponse);
            }
            let profile: any = { name: "Anonymous", type: ProfileType.Anonymous, deviceId };
            let temp = { ...profile };
            temp.id = deviceId;
            temp.recordedAt = new Date();
            await firebase.firestore().collection(this.PROFILES).doc(deviceId).set(JSON.parse(JSON.stringify(temp)));
            return new AppResponse({ ...temp },);
        } catch (error) {
            return new AppResponse(null, error);
        }
    }


    static async update(profile: any): Promise<AppResponse<string>> {
        console.log("Profile Update", profile)
        try {
            let temp = { ...profile };

            let details = await ProfileController.getDetails(profile.id);
            if (!details) {
                return new AppResponse(null, "Profile not exists");
            }
            let username = details.username;
            if (profile.username && details.username != profile.username) {
                let existsResponse = await this.checkUsernameExists(profile.username);
                if (existsResponse.data) {
                    return new AppResponse(null, "Username exists");
                }
            }
            if (!temp.profilePic) {
                temp.profilePic = UIHelper.getProfilePicture(profile.username);
            }
            if (!temp.id) {
                return new AppResponse(null, "Id not exists");
            }
            let updateProfile = {};
            Object.keys(profile).forEach((key) => {
                updateProfile[key] = profile[key] || details[key]
            })
            await firebase.firestore().collection(this.PROFILES).doc(temp.id).update(JSON.parse(JSON.stringify(updateProfile)));
            return new AppResponse(temp.id, null);
        } catch (error) {
            return new AppResponse(null, error);

        }
    }


    static async checkUsernameExists(username: string): Promise<AppResponse<boolean>> {
        try {
            var snaphsot = await firebase.firestore().collection(this.PROFILES).where("username", "==", username).get();
            return new AppResponse(snaphsot.docs.length != 0, null);
        } catch (error) {
            return new AppResponse(null, error);
        }
    }

    static getUsername(name: string): string {
        return (name.split(" ").join("_").toLowerCase()) + "_" + short.generate();
    }


    static async getProfileByUsername(username: string): Promise<any> {
        try {
            var snaphsot = await firebase.firestore().collection(this.PROFILES).where("username", "==", username).get();
            var result = snaphsot.docs.map((val) => val.data());
            let newList = await UserController.parseData(result);
            let profile: any = {};
            if (newList.length >= 0) {
                profile = newList[0];
            }
            else
                profile = result[0];
            return profile;
        } catch (error) {
            return error;
        }
    }

    static async checkProfileExists(userId: string, type: string): Promise<AppResponse<boolean>> {
        try {
            var snaphsot = await firebase.firestore().collection(this.PROFILES).where("userId", "==", userId).where("type", "==", type).get();
            return new AppResponse(snaphsot.docs.length != 0, null);
        } catch (error) {
            return new AppResponse(null, error);
        }
    }

    static async getProfileByUserId(userId: string, type: string): Promise<any> {
        try {
            var snaphsot = await firebase.firestore().collection(this.PROFILES).where("userId", "==", userId).where("type", "==", type).get();
            var result = snaphsot.docs.map((val) => val.data());
            if (result.length == 1) {
                return result[0];
            }
        } catch (error) {
            return error;
        }
    }

    static async getAllProfile(userId: string): Promise<any> {
        try {
            var snaphsot = await firebase.firestore().collection(this.PROFILES).where("userId", "==", userId).get();
            var result = snaphsot.docs.map((val) => val.data());
            return result;
        } catch (error) {
            return [];
        }
    }


    static async getDetails(id, isCached?: boolean): Promise<any> {
        try {
            var snapshot = await firebase.firestore().collection(this.PROFILES).doc(id).get();
            var data = snapshot.data();
            return data;
        } catch (error) {
            return undefined;
        }
    }

    static async getWholeDetails(id): Promise<any> {
        try {
            let details = await this.getDetails(id);
            if (details) {
                if (details.type == ProfileType.SUTRA) {
                    let response = await SutrazController.getParticularDetails(id);
                    if (response.data) {
                        return { ...response.data, ...details };
                    }
                    else {
                        return undefined;
                    }
                }
                return { ...details };
            }
        } catch (error) {
            return undefined;
        }
    }

    static async updateFollowing(profileId, followingId): Promise<{}> {
        try {
            var snapshot = await firebase.firestore().collection(this.PROFILES).doc(profileId).get();
            var data = snapshot.data();
            const updatedData = Object.assign({}, data);
            data.following = data.following || [];
            const isFollowingPresent = data.following.findIndex(id => id === followingId);
            if (isFollowingPresent !== -1) {
                data.following.splice(isFollowingPresent, 1);
            } else {
                data.following.push(followingId);
            }

            await firebase.firestore().collection(this.PROFILES).doc(profileId).update(data);
            var updatedSnapshot = await firebase.firestore().collection(this.PROFILES).doc(profileId).get();
            var updatedProfile = updatedSnapshot.data();
            return updatedProfile;
        } catch (error) {
            return undefined;
        }
    }

    static async updateFollower(profileId, followerId): Promise<{}> {
        try {
            var snapshot = await firebase.firestore().collection(this.PROFILES).doc(profileId).get();
            var data = snapshot.data();
            const updatedData = Object.assign({}, data);
            data.followers = data.followers || [];
            const isFollowerPresent = data.followers.findIndex(id => id === followerId);
            if (isFollowerPresent !== -1) {
                data.followers.splice(isFollowerPresent, 1);
            } else {
                data.followers.push(followerId);
            }

            await firebase.firestore().collection(this.PROFILES).doc(profileId).update(data);
            var updatedSnapshot = await firebase.firestore().collection(this.PROFILES).doc(profileId).get();
            var updatedProfile = updatedSnapshot.data();
            return updatedProfile;
        } catch (error) {
            return undefined;
        }
    }

    static async getProfileDetails(id): Promise<{}> {
        try {
            var snapshot = await firebase.firestore().collection(this.PROFILES).doc(id).get();
            var data = snapshot.data();
            let newList = await UserController.parseData([data]);
            if (newList.length >= 0) {
                return newList[0];
            }
            return null;
        } catch (error) {
            return undefined;
        }
    }


    static async getProfiles(): Promise<AppResponse<Array<any>>> {
        try {
            var snapshot = await firestore().collection(this.PROFILES).get();
            var result = snapshot.docs.map((val) => val.data());
            return new AppResponse(result, null);

        } catch (error) {
            console.log("Error", error);
            return new AppResponse(null, error.toString());

        }
    }


    static async getProfilesByType(type: string, count?: number, language?: string): Promise<AppResponse<Array<any>>> {
        try {
            var refrence = firestore().collection(this.PROFILES).where("type", "==", type);
            if (language) {
                refrence = refrence.where("language", "==", language);
            }
            refrence = refrence.orderBy("username", "asc");
            if (count > 0) {
                refrence = refrence.limit(count || 10);
            }
            var snapshot = await refrence.get();
            var result = snapshot.docs.map((val) => val.data());
            return new AppResponse(result, null);

        } catch (error) {
            console.log("Error", error);
            return new AppResponse(null, error.toString());

        }
    }

    static async deleteProfileForever(id): Promise<AppResponse<boolean>> {
        try {
            await firestore().collection(this.PROFILES).doc(id).delete();
            return new AppResponse(true, null);

        } catch (error) {
            console.log("Error", error);
            return new AppResponse(null, error.toString());

        }
    }

    static async getList(ids: Array<string>): Promise<any> {
        try {
            let array = [];
            array = await Promise.all(ids.map(async (id) => {
                var details = await this.getDetails(id);
                return details;
            }));
            array = array.filter((val) => val);
            return array;

        } catch (error) {
            console.log("Error", error);
            return [];
        }
    }

    static async parseData(data): Promise<any> {
        let newData = [...data]
        let profileData = {};
        await Promise.all(Object.keys(newData).map(async (key) => {
            let val = newData[key];
            if (val && val.profileId) {
                if (profileData[val.profileId]) {
                    val.profile = profileData[val];
                }
                else {
                    let result = await this.getDetails(val.profileId);
                    if (result) {
                        val.profile = result;
                        profileData[val.profileId] = result
                    }
                }
            }
        }));
        newData = newData.filter((e) => e.profile);
        return newData;
    }

    static async getFollowingOrFollowers(id: string, showFollowing: boolean): Promise<AppResponse<Array<any>>> {
        try {
            let details = await this.getDetails(id);
            let userIds = showFollowing ? details.following : details.followers;
            let result: Array<any> = await Promise.all(userIds.map(async (id) => {
                return await ProfileController.getDetails(id);
            }));
            result = result.filter((e) => e);
            return new AppResponse(result, null);
        } catch (error) {
            return new AppResponse(null, `${error}`);
        }
    }


    static isProfileComplete(profile): boolean {
        if (!profile) {
            return false;
        }
        let basic = false;
        if (profile.name && profile.username && profile.profilePic) {
            basic = true;
        }
        if (profile.type == ProfileType.CUSTOMER) {
            return basic;
        }
        if (profile.type == ProfileType.SUTRA) {
            return basic && (profile.profession != undefined);
        }
        return false;
    }

    //   static async crawlFiles():Promise<any>{
    //         var snapshot = await firebase.firestore().collection(this.PROFILES).get();
    //         var result = snapshot.docs.map((val) => val.data());
    //         let baseUrl="https://firebasestorage.googleapis.com/v0/b/sutras-a72f7.appspot.com/o";
    //         result.forEach(async(item)=>{

    //             if(item.profilePic&&item.profilePic.startsWith(baseUrl)&&!item.profilePic.startsWith(baseUrl+"/images")){
    //                 item.profilePic=`${item.profilePic}`.replace(baseUrl+"/",baseUrl+"/images%2F");
    //                 console.log("Item",item)
    //                 // await firebase.firestore().collection(this.STORIES).doc(item.id).set(item);

    //             }
    //         })
    //     }
}

export class ProfileType {
    static get SUTRA() {
        return "sutra";
    }
    static get STORE() {
        return "store";
    }
    static get CUSTOMER() {
        return "customer";
    }
    static get Anonymous() {
        return "anonymous";
    }

    static get allTypes() {
        return [this.SUTRA, this.CUSTOMER];
    };

    static getDescription = (type) => {
        if (type == ProfileType.STORE) {
            return "This is what Apps say about themselves";
        }
        if (type == ProfileType.SUTRA) {
            return "These are the stories that Brands are telling";
        }
        if (type == ProfileType.CUSTOMER) {
            return "These are the requirements that Consumers are putting";
        }
    }

    static getType(type: string) {
        if (type == this.SUTRA) {
            return "Brand";
        }
        if (type == this.STORE) {
            return "App";
        }
        if (type == this.CUSTOMER) {
            return "Consumer";
        }
        return "User";
    }

    static getPluralType(type: string) {
        return this.getType(type) + "s";
    }
}
