import { List, Spin } from 'antd';
import React from 'react';
import Chat from '../../models/chat/Chat';
import { MobileHeader } from '../appHeader/AppHeader';
import { ChatComponent } from './ChatComponent';

type ChatPanelProps = {
    chats: Array<Chat>,
    isLoading: boolean,
    selectedChat: Chat,
    onSelected: (chat: Chat) => void
}

const ChatPanel = (props: ChatPanelProps) => {
    const { isLoading, chats, onSelected, selectedChat = {} } = props;
    let result = [...chats];
    console.log("Result", result);
    result.sort((a, b) => {
        let aRecorded = (a.lastMsg || {}).recordedAt || (new Date(1971));
        let bRecorded = (b.lastMsg || {}).recordedAt || (new Date(1971));
        return bRecorded.toLocaleString().localeCompare(aRecorded.toLocaleString());
    })
    return (
        <div className="chat-panel">
            <MobileHeader title={"Chats"} />
            <div className="chat-panel-body">
                {isLoading ? <Spin tip="Loading Chats" /> : <List dataSource={result} renderItem={(item => <ChatComponent key={item.id} onClick={onSelected} chat={item} isSelected={item.id == selectedChat["id"]} />)} />}
            </div>
        </div>
    );
}

export default ChatPanel;