import { Fab } from '@material-ui/core';
import { Chat, Close, LinkOutlined } from '@material-ui/icons';
import { Button, Spin } from 'antd';
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import ChatController from '../../controllers/ChatController';
import LoginController from '../../controllers/LoginController';
import ProfileController from '../../controllers/ProfileController';
import AppRoutes from '../../helpers/AppRoutes';
import { authConnector } from '../../stores/auth/connector';
import { AppErrorComponent } from '../appError/AppErrorComponent';
import ChatDashboard from '../chat/dashboard/ChatDashboard';
import './style.css';

class ChatPopup extends Component<any, any> {

    get sutradhar() {
        return "sutradhar";
    }


    state = {
        isExpanded: false,
        isLoading: false,
        error: null,
        showLogin: false,
        chat: undefined,
        assistant: undefined
    }

    componentDidMount() {
        this.startChat();
    }

    startChat = async () => {
        this.setState({ isLoading: true });
        var profileId = await LoginController.getCurrentProfile();
        if (!profileId) {
            var anonymousResponse = await ProfileController.createAnonymous();
            if (anonymousResponse.error) {
                this.setState({ isLoading: false, error: anonymousResponse.error });
                return;
            }
            await LoginController.saveCurrentProfile(anonymousResponse.data.id);
        }
        var appResponse = await ChatController.startChatWithUsername(this.sutradhar);
        this.setState({ isLoading: false });
        if (appResponse.data) {
            this.setState({ chat: appResponse.data })
        } else {
            this.setState({ error: appResponse.error })
        }
    }

    onChatToggle = () => {
        const { isExpanded } = this.state;
        this.setState({ isExpanded: !isExpanded })
    }

    render() {
        const { isExpanded } = this.state;
        return <section className="chat-popup">{isExpanded ? this.buildExpanded() : this.buildCollapsed()}</section>;
    }

    buildCollapsed() {
        return <Fab color="primary" aria-label="add" onClick={this.onChatToggle}>
            <Chat />
        </Fab>
    }

    buildExpanded() {
        const { showLogin, error, isLoading, chat } = this.state;
        const { user } = this.props;
        console.log("User ", user);
        return (
            <section className="chat-popup-expanded">
                {isLoading ? <Spin /> : chat ? <ChatDashboard chatOptions={[Object.keys(user).length > 0 ? <Button onClick={() => {
                    if (chat.friend)
                        this.props.history.push(AppRoutes.CHAT(chat.friend.username));
                }} type={"link"}>Open <LinkOutlined /></Button> : <div />, <Button onClick={this.onChatToggle}><Close /></Button>]} chat={chat} /> : error ? <AppErrorComponent error={error} /> : <section />}
            </section>
        );
    }
}

export default withRouter(authConnector(ChatPopup));