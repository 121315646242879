import UserController from "../controllers/UserController";


class User {
    userId: string;
    name: string;
    email: string;
    role: string;
    username: string;
    phone: string;
    photoURL: string;
    description: string;
    priority;

    constructor(userId: string, username: string, name: string, email: string, role: string, mobile: string, photoURL: string, description?: string, priority?, ) {
        this.userId = userId;
        this.name = name;
        this.username = username;
        this.email = email;
        this.role = role;
        this.phone = mobile;
        this.photoURL = photoURL;
        this.description = description;
        this.priority = priority;
    }

    static fromJson(json: {}): User {
        return new User(json["userId"] || json["id"], json['username'], json["name"], json["email"], json["role"], json["phone"], json['photoURL'] || UserController.getGravatar(json["name"]), json["description"] || '', json["priority"] || 0);
    }
}

let UserType = {
    user: "user",
    anonymous: "anonymous",
    sutra: "sutra"
}

export class UserInvite {
    receiverName: string;
    receiverMobile: string;
    senderName: string;
    senderId: string;
    message: string;
}

export { UserType };

export default User;